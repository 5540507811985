import { makeStyles } from '@fluentui/react-components';
import { LocationRegular, ReceiptSearchRegular } from '@fluentui/react-icons';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { uuidUtil } from '@zastrpay/common';
import { Alert, Body, BrandedQrCode, Button, Dialog, FormattedCurrency, Link, TimerProgressBar, Title } from '@zastrpay/components';
import { Page, SpinnerOverlay } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';
import { Transaction } from '@zastrpay/transactions';

import { feature } from './config';
import { useHubListener } from './hub';
import { isIdRequired, TransactionIntent } from './models';

const ShopListDialog = lazy(() => import('@zastrpay/shops').then((module) => ({ default: module.ShopListDialog })));

const EXPIRATION_DURATION = 15 * 60;

export type TransactionIntentCodeProps = {
    intent: TransactionIntent;
    merchant?: string;
    onCancel?: () => void;
    onExpire?: () => void;
    onRefresh?: () => void;
    onFinalize?: (trx: Transaction) => void;
};

export const TransactionIntentCode: React.FC<TransactionIntentCodeProps> = ({
    intent,
    onCancel,
    onExpire,
    onRefresh,
    onFinalize,
    merchant,
}) => {
    const classes = useStyles();

    const { t } = useTranslation('transaction-intents');
    const [shopsOpen, setShopsOpen] = useState(false);
    const [cancelOpen, setCancelOpen] = useState(false);
    const [codeOpen, setCodeOpen] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    const codeParts = useMemo(() => uuidUtil.uuidToBase36(intent.id).split('-'), [intent.id]);

    useHubListener('TransactionEvents', (data) => {
        if (data.transactionIntentId == null) {
            onFinalize?.(data); // backward compatibility, remove after deploy trx.evh to prod
        }
        if (data.transactionIntentId === intent.id) {
            onFinalize?.(data);
        }
    });

    const findShops = () => {
        trackClick('qr_code_view', 'find_shops');
        setShopsOpen(true);
    };

    const confirmCancellation = () => {
        trackClick('qr_code_view', 'cancel_qr_code');
        setCancelOpen(true);
    };

    useEffect(() => {
        trackPage('qr_code_view');
    }, []);

    return (
        <Page>
            {isIdRequired(intent.type, intent.amount) && <Alert type="info">{t('preview.idRequired')}</Alert>}

            <Title>
                <Trans t={t} i18nKey="preview.title" context={intent.type} />
                <br />
                <FormattedCurrency currency={intent.currency} value={intent.amount} format="sign" />
            </Title>

            {intent.type === 'PassthroughDeposit' && merchant && (
                <Body>
                    {t('common.recipient')}: {<span data-clarity-mask="true">{merchant}</span>}
                </Body>
            )}

            <BrandedQrCode content={intent.id} />

            {feature.clearTextCode && <Link onClick={() => setCodeOpen(true)}>{t('preview.problem')}</Link>}

            <TimerProgressBar
                startOn={intent.createdOn}
                duration={EXPIRATION_DURATION}
                title={t('preview.expiresIn', { remainingTime: Math.ceil(remainingTime / 60) })}
                onProgress={setRemainingTime}
                onFinish={onExpire}
            />

            <Button appearance="outline" size="large" onClick={findShops} icon={<LocationRegular />}>
                {t('preview.findShops')}
            </Button>

            <Link className={classes.cancel} onClick={confirmCancellation}>
                {t('preview.cancel')}
            </Link>

            <Dialog
                open={cancelOpen}
                title={t('cancel.title')}
                dismissible={false}
                align="bottom"
                actions={[
                    {
                        text: t('cancel.yes'),
                        onClick: () => {
                            trackClick('qr_code_view', 'cancel_popup_yes');
                            setCancelOpen(false);
                            onCancel?.();
                        },
                    },
                    {
                        text: t('cancel.no'),
                        style: 'preferred',
                        onClick: () => {
                            trackClick('qr_code_view', 'cancel_popup_no');
                            setCancelOpen(false);
                        },
                    },
                ]}
            >
                <Body block>{t('cancel.message')}</Body>
            </Dialog>

            <Dialog
                open={codeOpen}
                icon={<ReceiptSearchRegular />}
                title={t('preview.showCode.title')}
                align="bottom"
                onOpenChange={setCodeOpen}
            >
                <Body block>{t('preview.showCode.message')}</Body>
                <div className={classes.codeContainer}>
                    <div className={classes.codeRow}>
                        <Title className={classes.codePart}>{codeParts[0]}</Title>
                        <Title className={classes.codePart}>{codeParts[1]}</Title>
                        <Title className={classes.codePart}>{codeParts[2]}</Title>
                    </div>
                    <div className={classes.codeRow}>
                        <Title className={classes.codePart}>{codeParts[3]}</Title>
                        <Title className={classes.codePart}>{codeParts[4]}</Title>
                    </div>
                </div>
            </Dialog>

            <Suspense fallback={<SpinnerOverlay loading />}>
                {shopsOpen && <ShopListDialog open={shopsOpen} onOpenChange={setShopsOpen} />}
            </Suspense>
        </Page>
    );
};

const useStyles = makeStyles({
    cancel: {
        fontWeight: tokens.fontWeightSemibold,
    },
    progress: {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        marginTop: tokens.spacingVerticalS,
        gap: tokens.spacingVerticalXXS,
    },
    codeContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        justifyContent: 'center',
        background: tokens.colorNeutralBackground4,
        padding: tokens.spacingVerticalM,
        marginTop: tokens.spacingVerticalS,
    },
    codeRow: {
        display: 'flex',
        columnGap: '0.7rem',
        justifyContent: 'center',
    },
    codePart: {
        fontFamily: 'Courier New',
        flexGrow: 0,
        flexShrink: 0,
    },
});
