import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useAuth } from '@zastrpay/auth';

import { useApp } from '../AppProvider';
import { logError } from '../common/application-insights';

export type SessionNavigationState = {
    backShown: boolean;
};

export type SessionNavigationContext = SessionNavigationState & {
    showBack: (backShown?: boolean) => void;
    cancel: (codeCancelled?: boolean) => void;
    failure: () => void;
    success: () => void;
};

const Context = createContext<SessionNavigationContext | null>(null);

export const SessionNavigationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const session = useAuth();
    const { clear: clearContext, cancelRedirectSession, redirectSession, codeGenerated } = useApp();
    const [state, setState] = useState({ backShown: false, cancelShown: true });

    const showBack = useCallback((backShown?: boolean) => {
        setState((state) => {
            if (state.backShown === backShown) {
                return state;
            }

            return { ...state, backShown: backShown ?? false };
        });
    }, []);

    const clear = useCallback(() => {
        clearContext();
        session.clear();
    }, [session, clearContext]);

    const cancel = useCallback(
        async (codeCancelled?: boolean) => {
            if (session.state === 'expired' && session.failureUrl) {
                window.location.href = session.failureUrl;
                return;
            }

            if (!redirectSession) {
                return;
            }

            if (['anonymous', 'authenticated'].includes(session.state)) {
                // we need to make sure not to block the customer going back whatever it takes
                try {
                    await cancelRedirectSession();
                } catch (error) {
                    if (error instanceof Error) {
                        logError(error);
                    }
                }
            }

            clear();

            if (codeCancelled || !codeGenerated) {
                window.location.href = redirectSession.redirectData.abortUrl;
            } else {
                window.location.href = redirectSession.redirectData.qrCodeSuccessUrl ?? redirectSession.redirectData.abortUrl;
            }
        },
        [redirectSession, cancelRedirectSession, clear, codeGenerated, session],
    );

    const failure = useCallback(() => {
        if (redirectSession?.redirectData.failureUrl) {
            clear();
            window.location.href = redirectSession.redirectData.failureUrl;
        }
    }, [redirectSession, clear]);

    const success = useCallback(() => {
        if (redirectSession?.redirectData.successUrl) {
            clear();
            window.location.href = redirectSession.redirectData.successUrl;
        }
    }, [redirectSession, clear]);

    return <Context.Provider value={{ ...state, showBack, cancel, failure, success }}>{children}</Context.Provider>;
};

export const useSessionNavigation = (): SessionNavigationContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useContext must be used within an SessionNavigationProvider');
    }

    return context;
};

export const useBackNavigation = (shown = true) => {
    const { showBack, backShown } = useSessionNavigation();
    const [initiallyShown] = useState(backShown);

    useEffect(() => {
        showBack(shown);

        return () => showBack(initiallyShown);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
