import { api, sanitize } from '@zastrpay/common';

import { TRANSACTION_CMDH } from './config';
import { ApproveTransaction, CreateTransaction, Transaction } from './models';

export const create = (id: string, data: CreateTransaction): Promise<Transaction> => {
    return api.put<Transaction>(`${TRANSACTION_CMDH}/transactions/wallet-transfers/${id}`, sanitize(data), {
        headers: { 'x-request-id': id },
    });
};

export const approve = (id: string, data: ApproveTransaction): Promise<Transaction> => {
    return api.post<Transaction>(`${TRANSACTION_CMDH}/transactions/${id}/approve`, sanitize(data));
};

export const reset = (id: string): Promise<Transaction> => {
    return api.post<Transaction>(`${TRANSACTION_CMDH}/transactions/${id}/reset-approval`);
};
