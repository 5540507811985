import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

export type HeaderSlot = 'left' | 'right';
export type Background = 'default' | 'subtle';

export type LayoutState = {
    header: Record<HeaderSlot, ReactNode[]>;
    background: Background;
};

export type LayoutContext = LayoutState & {
    setHeaderSlot: (slot: HeaderSlot, element: ReactNode) => () => void;
    clearHeaderSlot: (slot: HeaderSlot, element?: ReactNode) => void;
    setBackground: (background: Background) => void;
};

const Context = createContext<LayoutContext | null>(null);

export const LayoutProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [state, setState] = useState<LayoutState>({
        header: {
            left: [],
            right: [],
        },
        background: 'default',
    });

    const setHeaderSlot = useCallback((slot: HeaderSlot, element: ReactNode) => {
        setState(({ header, background }) => ({
            background,
            header: { ...header, [slot]: [...header[slot], element] },
        }));

        return () =>
            setState(({ header, background }) => ({
                background,
                header: { ...header, [slot]: header[slot].filter((el) => el !== element) },
            }));
    }, []);

    const clearHeaderSlot = useCallback((slot: HeaderSlot) => {
        setState((state) => ({ ...state, header: { ...state.header, [slot]: [] } }));
    }, []);

    const setBackground = useCallback((background: Background) => {
        setState((state) => ({ ...state, background }));
    }, []);

    return <Context.Provider value={{ ...state, setHeaderSlot, clearHeaderSlot, setBackground }}>{children}</Context.Provider>;
};

export const useLayout = (): LayoutContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useContext must be used within an SessionNavigationProvider');
    }

    return context;
};

export const useHeaderSlot = (slot: HeaderSlot, element: ReactNode) => {
    const { setHeaderSlot, clearHeaderSlot } = useLayout();

    useEffect(() => {
        setHeaderSlot(slot, element);

        return () => clearHeaderSlot(slot);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setHeaderSlot, clearHeaderSlot]);
};
