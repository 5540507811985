import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from 'virtual:i18next-module-loader';

// import { DEFAULT_LANGUAGE, LANGUAGES, userLanguageDetector } from '@zastrpay/common';
import { userLanguageDetector } from '@zastrpay/common';

import { logError } from './application-insights';

try {
    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .use(userLanguageDetector)
        .init({
            resources,
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            appendNamespaceToCIMode: true,
            saveMissing: true,
            missingKeyHandler: (ng, ns, key, _fallback, _updateMissing, options) => {
                const message = `Warning: Translation Key not found (${ng}) ${ns}:${key}${options.context ? `_${options.context}` : ''}`;
                console.warn(message);
                !import.meta.env.DEV && logError(new Error(message));
            },
            detection: {
                supportedLanguages: ['en', 'de'], //LANGUAGES.map((lang) => lang.code),
                defaultLanguage: 'de', //DEFAULT_LANGUAGE,
                logError: logError,
            },
            react: {
                defaultTransParent: 'span',
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b'],
            },
        });
} catch (e) {
    e instanceof Error && logError(e);
}

document.documentElement.lang = i18n.language;

export default i18n;
