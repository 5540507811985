import { parse as uuidToBytes } from 'uuid';

const BASE58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
const BASE36 = '0123456789abcdefghijklmnopqrstuvwxyz';

/** converts a byte array into a string with a given alphabet */
const encodeBase = (bytes: Uint8Array, alphabet: string) => {
    const digits = [0]; // digits initialized to zero
    const alphabetSize = alphabet.length;

    for (const byte of bytes) {
        let carry = byte;
        for (let j = 0; j < digits.length; j++) {
            carry += digits[j] << 8; // Multiply by 256 (since it's byte-sized)
            digits[j] = carry % alphabetSize; // Mod by base
            carry = Math.floor(carry / alphabetSize); // Carry over to next digit
        }

        while (carry > 0) {
            digits.push(carry % alphabetSize);
            carry = Math.floor(carry / alphabetSize);
        }
    }

    // Convert digits back to a string using alphabet
    let result = '';
    for (const digit of digits.reverse()) {
        result += alphabet[digit];
    }

    // Handle leading zeros by counting leading 0s in Uint8Array
    for (const byte of bytes) {
        if (byte === 0) {
            result = alphabet[0] + result;
        } else {
            break;
        }
    }

    return result;
};

export const uuidToBase36 = (uuid: string) => {
    const bytes = uuidToBytes(uuid);
    return encodeBase(new Uint8Array(bytes), BASE36).replace(/^([\w]{5})([\w]{5})([\w]{5})([\w]{5})([\w]{5})$/, '$1-$2-$3-$4-$5');
};

export const uuidToBase58 = (uuid: string) => {
    const bytes = uuidToBytes(uuid);
    return encodeBase(new Uint8Array(bytes), BASE58).replace(/^([\w]{6})([\w]{5})([\w]{5})([\w]{6})$/, '$1-$2-$3-$4');
};
