import { api, sanitize } from '@zastrpay/common';

import { CUSTOMER_CMDH } from './config';
import { Customer, PreCheckRegistration, PreCheckRegistrationCommand, RegisterCustomerCommand } from './models';

export const registerCustomer = (customerId: string, data: RegisterCustomerCommand): Promise<Customer> => {
    return api.put<Customer>(`${CUSTOMER_CMDH}/customers/${customerId}`, sanitize(data));
};

export const preCheckRegistration = (data: PreCheckRegistrationCommand): Promise<PreCheckRegistration> => {
    return api.post<PreCheckRegistration>(`${CUSTOMER_CMDH}/customers/pre-check-registration`, sanitize(data));
};
