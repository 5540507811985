import { makeStyles } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { Link } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export type FooterProps = {
    enable?: boolean;
    openCookieBanner?: () => void;
};

export const Footer: React.FC<FooterProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('layout');

    return (
        (props.enable ?? true) && (
            <div className={classes.wrapper}>
                <div className={classes.footer}>
                    <Link className={classes.link} onClick={props.openCookieBanner}>
                        {t('footer.cookiePolicy')}
                    </Link>
                </div>
            </div>
        )
    );
};

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        paddingTop: tokens.spacingVerticalM,
        paddingBottom: `calc(${tokens.spacingVerticalM} + ${tokens.customFooterPadding})`,
        backgroundColor: tokens.colorNeutralBackground1,
    },
    footer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        fontSize: tokens.fontSizeBase300,
    },
});
