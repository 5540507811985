import { makeHubListener } from '@zastrpay/hooks';

import { CUSTOMER_EXTEVP } from './config';

export type CustomerEventChannel = 'CustomerEvents';

export type CustomerEventType = 'CustomerRegistered';

export type CustomerEvent = {
    type: CustomerEventType;
    id: string;
};

export const useHubListener = makeHubListener<CustomerEventChannel, CustomerEvent>(CUSTOMER_EXTEVP);
