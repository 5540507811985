// @ts-expect-error - no types for this package
import googleAnalyticsPlugin from '@analytics/google-analytics';
import { Analytics } from 'analytics';
import { clarity } from 'clarity-js';

import { api, errorResponse, ResponseError } from '@zastrpay/common';

import { analyticsConfig } from './config';
import { CookieConsent } from './model';

const analyticInstance = Analytics({
    plugins: [
        googleAnalyticsPlugin({
            measurementIds: [analyticsConfig.gaId],
            gtagConfig: {
                anonymize_ip: true,
            },
            debug: false,
            enabled: false,
        }),
    ],
});

export type Logger = (error: Error) => void;
export type TrackSession = { id: string; type: string; scope: string };
export type TrackPayload = Record<string | number, string | number | undefined | null | boolean>;

let configured = false;

let logger: Logger | undefined;
let lastPage: string | undefined;
let lastSession: TrackSession | undefined;

export const enable = async () => {
    await analyticInstance.plugins.enable('google-analytics');

    clarity.start({
        projectId: analyticsConfig.clarityId,
        upload: 'https://x.clarity.ms/collect',
        track: true,
        content: true,
    });
};

export const disable = async () => {
    await analyticInstance.plugins.disable('google-analytics', () => {
        // nothing to do
    });

    clarity.stop();
};

export const configure = (config: { logger?: Logger }) => {
    if (configured) {
        return;
    }

    logger = config.logger;

    api.apply((instance) => {
        instance.interceptors.response.use(
            (response) => {
                if (errorResponse(response.status) && response.status !== 404) {
                    trackError(response.data.code);
                }

                return response;
            },
            (error) => {
                if (error instanceof ResponseError) {
                    trackError(error.code);
                }

                return Promise.reject(error);
            },
        );
    });

    configured = true;
};

export const identify = async (userId: string, session: TrackSession | undefined, cookieConsent: CookieConsent) => {
    lastSession = session;

    clarity.identify(userId, session?.id);

    await analyticInstance.identify(userId, {
        consent_accepted_at: cookieConsent.date,
        consent_types: cookieConsent.types,
    });
};

const preparePayload = (name?: string, payload?: TrackPayload) => {
    return {
        ...payload,
        page_title: name ?? lastPage,
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_referrer: document.referrer ?? window.location.href,
        session_id: lastSession?.id,
        // map to scope, since type will always be the same for an application
        session_type: lastSession?.scope,
    };
};

const trackInternal = async (...args: Parameters<typeof analyticInstance.track>) => {
    try {
        await analyticInstance.track(...args);
    } catch (error) {
        if (error instanceof Error && logger) {
            logger?.(error);
        } else {
            console.error('Failed to track event', error);
        }
    }
};

export const track = (pageName: string, eventName: string, payload?: TrackPayload) => {
    trackInternal(eventName, preparePayload(pageName, payload));
};

export const trackPage = (pageName: string, { type, ...payload }: TrackPayload & { type?: 'error' } = {}) => {
    lastPage = pageName;

    if (type === 'error') {
        trackInternal('error_page_view', preparePayload(pageName, payload));
    } else {
        trackInternal('page_view', preparePayload(pageName, payload));
    }
};

export const trackInput = (pageName: string, inputName: string, payload?: TrackPayload) => {
    trackInternal('user_input', preparePayload(pageName, { ...payload, input_name: `${pageName}_${inputName}` }));
};

export const trackError = (errorName: string, payload?: TrackPayload) => {
    trackInternal('user_error', preparePayload(lastPage, { ...payload, error_code: errorName }));
};

export const trackClick = (pageName: string, buttonName: string, payload?: TrackPayload) => {
    trackInternal('button_click', preparePayload(pageName, { ...payload, button_name: `${pageName}_${buttonName}` }));
};
