import { bool, required } from '@zastrpay/common';

export const otpResendWaitSeconds = 30;

export const endpoints = {
    custAuthCmdh: required(import.meta.env.VITE_CUST_AUTH_SVC_CMDH_URL, 'VITE_CUST_AUTH_SVC_CMDH_URL'),
    custAuthQryh: required(import.meta.env.VITE_CUST_AUTH_SVC_QRYH_URL, 'VITE_CUST_AUTH_SVC_QRYH_URL'),
    custCmdh: required(import.meta.env.VITE_CUST_SVC_CMDH_URL, 'VITE_CUST_SVC_CMDH_URL'),
    custQryh: required(import.meta.env.VITE_CUST_SVC_QRYH_URL, 'VITE_CUST_SVC_QRYH_URL'),
    trxCmdh: required(import.meta.env.VITE_TRX_SVC_CMDH_URL, 'VITE_TRX_SVC_CMDH_URL'),
    trxQryh: required(import.meta.env.VITE_TRX_SVC_QRYH_URL, 'VITE_TRX_SVC_QRYH_URL'),
    distrQryh: required(import.meta.env.VITE_DISTR_SVC_QRYH_URL, 'VITE_DISTR_SVC_QRYH_URL'),
    merchQryh: required(import.meta.env.VITE_MERCH_SVC_QRYH_URL, 'VITE_MERCH_SVC_QRYH_URL'),
    locQryh: required(import.meta.env.VITE_LOC_SVC_QRYH_URL, 'VITE_LOC_SVC_QRYH_URL'),
};

export const eventHubs = {
    trxEvh: required(import.meta.env.VITE_TRX_SVC_EVH_URL, 'VITE_TRX_SVC_EVH_URL'),
    custExtEvp: required(import.meta.env.VITE_CUST_SVC_EXTEVP_URL, 'VITE_CUST_SVC_EXTEVP_URL'),
};

export const featureToggles = {};

export const applicationInsights = {
    instrumentationKey: required(import.meta.env.VITE_APPINSIGHTS_KEY, 'VITE_APPINSIGHTS_KEY'),
    enabled: bool(import.meta.env.VITE_APPINSIGHTS_ENABLED),
};

export const requestConfigs = {
    retries: Number(required(import.meta.env.VITE_REQUEST_RETRIES, 'VITE_REQUEST_RETRIES')),
    delay: Number(required(import.meta.env.VITE_REQUEST_RETRY_DELAY, 'VITE_REQUEST_RETRY_DELAY')),
};

export const pageWidth = 480;
export const headerHeight = 48;

export const idRequirementThreshold = Number(import.meta.env.VITE_DEFAULT_ID_REQUIREMENT_THRESHOLD ?? 2000);
export const MOCK_AUTHENTICATION_HEADERS = import.meta.env.VITE_MOCK_AUTHENTICATION_HEADERS === 'true';
