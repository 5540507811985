import { makeStyles } from '@fluentui/react-components';

import { Body, Title } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export type PageTitleProps = {
    title: string;
    subTitle?: string;
};

export const PageTitle: React.FC<PageTitleProps> = (props) => {
    const classes = useStyles();

    return (
        <section className={classes.container}>
            <Title className={classes.title}>{props.title}</Title>
            <Body>{props.subTitle}</Body>
        </section>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: tokens.spacingVerticalL,
        gap: tokens.spacingVerticalXXS,
    },
    title: {
        color: tokens.colorBrandForeground1,
        fontSize: tokens.fontSizeBase500,
    },
});
