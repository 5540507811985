import { makeStyles } from '@fluentui/react-components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { EMAIL_REGEX, ValidationError } from '@zastrpay/common';
import { Alert, Body, Button, ErrorTrans, Input, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

export type NewEmailProps = {
    error?: React.ReactNode;
    onChange?: (email: string) => void;
};

export const NewEmail: React.FC<NewEmailProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('auth-flow');
    const [newEmail, setNewEmail] = useState<string>('');
    const [validationError, setValidationError] = useState<ValidationError<'EmailRegex'>>();

    useEffect(() => {
        trackPage('new_email');
    }, []);

    const verifyEmail = (email: string) => {
        if (email && EMAIL_REGEX.test(email)) {
            setValidationError(undefined);
            return true;
        }
        return false;
    };

    const changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value.trimStart();
        setNewEmail(email);
        verifyEmail(email);
    };

    const next = () => {
        trackClick('new_email', 'next');

        if (verifyEmail(newEmail)) {
            props?.onChange?.(newEmail);
        } else {
            setValidationError(new ValidationError('EmailRegex'));
        }
    };

    return (
        <Page>
            <Title>{t('changeEmail.newEmail.title')}</Title>
            <Body>{t('changeEmail.newEmail.subTitle')}</Body>

            <div className={classes.emailContainer}>
                <Body className={classes.email}>{t('changeEmail.newEmail.email')}</Body>
                <Input
                    className={classes.emailInput}
                    value={newEmail}
                    type="email"
                    name="email"
                    autoComplete="email"
                    size="large"
                    onChange={changeEmail}
                />
                {validationError && (
                    <Alert type="error">
                        <ErrorTrans t={t} i18nKey="changeEmail.error.validation" error={validationError} />
                    </Alert>
                )}
                {!validationError && props.error && <Alert type="error">{props.error}</Alert>}
            </div>

            <Button appearance="primary" size="large" disabled={!newEmail?.length} onClick={next}>
                {t('changeEmail.newEmail.next')}
            </Button>
        </Page>
    );
};

const useStyles = makeStyles({
    emailContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        marginBottom: tokens.spacingVerticalM,
        gap: tokens.spacingHorizontalXS,
    },
    emailInput: {
        paddingLeft: 0,
        alignItems: 'stretch',
        alignSelf: 'stretch',
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
    email: {
        alignSelf: 'start',
    },
});
