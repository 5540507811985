import { makeStyles } from '@fluentui/react-components';
import { InfoRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { trackClick } from '@zastrpay/analytics';
import { Body, Dialog, Link } from '@zastrpay/components';
import { useLayout } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { ActiveKycRequestProvider, ActiveKycRequestType } from './ActiveKycRequestProvider';
import { useKycRequest } from './KycRequestProvider';
import { enforced } from './models';

type WithActiveKycRequestProps = {
    mode?: ActiveKycRequestType;
    skippable?: boolean;
    onSkip?: () => void;
    children?: React.ReactNode;
};

export const KycRequestPage: React.FC<WithActiveKycRequestProps> = (props: WithActiveKycRequestProps) => {
    const classes = useStyles();
    const { t } = useTranslation('kyc-requests');

    const { setHeaderSlot } = useLayout();
    const { current, all, skip, seen } = useKycRequest();

    const [skipConfirmation, setSkipConfirmation] = useState(false);

    const { mode = 'page' as ActiveKycRequestType, skippable } = props;

    useEffect(() => {
        if (mode === 'alert') {
            return;
        }

        if (!skippable) {
            const unregisterHandle = setHeaderSlot('right', <span />);
            return () => unregisterHandle();
        }

        const due = current && enforced(current);
        if (due) {
            return;
        }

        const unregisterHandle = setHeaderSlot(
            'right',
            <Link className={classes.link} onClick={() => setSkipConfirmation(true)}>
                {t('alert.skip')}
            </Link>,
        );

        return () => unregisterHandle();
    }, [current, mode, skippable, setHeaderSlot, t, classes.link]);

    const onSkip = async () => {
        trackClick('kyc_request_info', 'skip', { kyc_req_type: current?.type });

        skip();

        if (all.length === 1) {
            props.onSkip?.();
        }

        setSkipConfirmation(false);
    };

    return (
        <ActiveKycRequestProvider mode={mode} onSeen={seen} onSkip={onSkip} current={current}>
            {props.children}

            {mode === 'page' && skippable && (
                <Dialog
                    open={skipConfirmation}
                    align="bottom"
                    title={t('alert.skipDialog.title')}
                    icon={<InfoRegular />}
                    onOpenChange={setSkipConfirmation}
                    dismissible={false}
                    actions={[
                        { text: t('alert.skipDialog.yes'), onClick: onSkip },
                        {
                            text: t('alert.skipDialog.no'),
                            style: 'preferred',
                            onClick: () => setSkipConfirmation(false),
                        },
                    ]}
                >
                    <Body block>
                        <Trans
                            t={t}
                            i18nKey="alert.skipDialog.subTitle"
                            components={{
                                portalLink: <Link href="https://zastrpay.com" target="_blank" />,
                            }}
                        />
                    </Body>
                </Dialog>
            )}
        </ActiveKycRequestProvider>
    );
};

const useStyles = makeStyles({
    link: {
        fontSize: tokens.fontSizeBase200,
        marginRight: tokens.spacingHorizontalM,
    },
});
