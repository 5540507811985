import { ShieldPersonRegular } from '@fluentui/react-icons';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogProps } from '@zastrpay/components';
import { SpinnerPage } from '@zastrpay/layout';

import { useDialogStyles } from './styles';

export type PrivacyPolicyDialogProps = Omit<DialogProps, 'title'>;

const English = lazy(() => import('./en/PrivacyPolicy').then((module) => ({ default: module.PrivacyPolicy })));
const German = lazy(() => import('./de/PrivacyPolicy').then((module) => ({ default: module.PrivacyPolicy })));

export const PrivacyPolicyDialog: React.FC<PrivacyPolicyDialogProps> = (props) => {
    const { t, i18n } = useTranslation('analytics');
    const classes = useDialogStyles();

    return (
        <Dialog
            title={t('legal.privacy')}
            open={props.open}
            onOpenChange={props.onOpenChange}
            align="bottom"
            icon={<ShieldPersonRegular />}
        >
            <Suspense
                fallback={
                    <div className={classes.loadingContainer}>
                        <SpinnerPage />
                    </div>
                }
            >
                {i18n.language === 'en' ? <English /> : <German />}
            </Suspense>
        </Dialog>
    );
};
