import { api, Serialized } from '@zastrpay/common';

import { ACCOUNTING_QRYH } from './config';
import { Account } from './models';

const deserializeAccount = (account: Serialized<Account>): Account => ({
    ...account,
    createdOn: new Date(account.createdOn),
});

export const get = async (customerId: string): Promise<Account> => {
    return api.get<Account>(`${ACCOUNTING_QRYH}/accounts/${customerId}`).then(deserializeAccount);
};
