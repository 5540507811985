import { ChevronLeft24Regular } from '@fluentui/react-icons';

import { Button } from '@zastrpay/components';

export type BackButtonProps = {
    onClick: () => void;
};

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
    return <Button appearance="transparent" size="large" onClick={onClick} icon={<ChevronLeft24Regular />} />;
};
