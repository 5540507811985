import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { tokens } from '@zastrpay/theme';

type OrderedBulletListProps = DetailedHTMLProps<HTMLAttributes<HTMLOListElement>, HTMLUListElement> & {
    as: 'ol';
    size?: 'medium' | 'large';
};

type UnorderedBulletListProps = DetailedHTMLProps<HTMLAttributes<HTMLOListElement>, HTMLUListElement> & {
    as: 'ul';
    size?: 'small' | 'medium' | 'large';
};

export type BulletListProps = Omit<OrderedBulletListProps | UnorderedBulletListProps, 'ref'>;

export const BulletList: React.FC<BulletListProps> = ({ as, className, size = 'medium', ...props }) => {
    const classes = useStyles();

    return as === 'ol' ? (
        <ol
            className={mergeClasses(
                classes.list,
                classes.orderedList,
                { small: classes.sizeSmall, medium: classes.sizeMedium, large: classes.sizeLarge }[size],
                className,
            )}
            {...props}
        />
    ) : (
        <ul
            className={mergeClasses(
                classes.list,
                classes.unorderedList,
                { small: classes.sizeSmall, medium: classes.sizeMedium, large: classes.sizeLarge }[size],
                className,
            )}
            {...props}
        />
    );
};

const useStyles = makeStyles({
    list: {
        boxSizing: 'border-box',
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        margin: '0',

        '& > li': {
            position: 'relative',
            '::before': {
                position: 'absolute',
                left: 0,
                backgroundColor: tokens.colorNeutralForeground2,
                color: tokens.colorNeutralBackground1,
                textAlign: 'center',
                borderRadius: tokens.borderRadiusCircular,
            },
        },
    },
    orderedList: {
        counterReset: 'step-counter',

        '& > li': {
            counterIncrement: 'step-counter',
            '::before': {
                content: 'counter(step-counter)',
            },
        },
    },
    unorderedList: {
        listStyleType: 'none',
        padding: '0',

        '& > li': {
            '::before': {
                content: '""',
            },
        },
    },
    sizeLarge: {
        gap: '16px 0',

        '& > li': {
            paddingLeft: '32px',
            '::before': {
                marginTop: '1px',
                width: '20px',
                height: '20px',
                fontSize: tokens.fontSizeBase200,
                lineHeight: '20px',
            },
        },
    },
    sizeMedium: {
        gap: '8px 0',

        '& > li': {
            paddingLeft: '24px',
            '::before': {
                marginTop: '3px',
                width: '15px',
                height: '15px',
                fontSize: tokens.fontSizeBase100,
                lineHeight: '15px',
            },
        },
    },
    sizeSmall: {
        gap: '4px 0',

        '& > li': {
            paddingLeft: '16px',
            '::before': {
                marginTop: '8px',
                width: '6px',
                height: '6px',
                fontSize: tokens.fontSizeBase100,
                lineHeight: '10px',
            },
        },
    },
});
