import { getLocales } from './config';

export type Currency = 'EUR';

export type CurrencyFormat = 'currency' | 'sign';

const format = (value: number | undefined, options: Intl.NumberFormatOptions) => {
    if (value === null || value === undefined) {
        return;
    }

    return Number(value).toLocaleString(getLocales(), options);
};

export const formatCurrency = (value: number | undefined, currency: Currency | undefined, currencyFormat?: CurrencyFormat) => {
    const style = currencyFormat === 'sign' ? 'currency' : undefined;

    const formattedValue = format(value, { maximumFractionDigits: 2, minimumFractionDigits: 2, currency, style });

    return currencyFormat === 'sign' ? formattedValue : `${formattedValue} ${currency}`.trim();
};
