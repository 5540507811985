import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@zastrpay/auth';
import { ensureResponseError } from '@zastrpay/common';
import { ErrorTrans } from '@zastrpay/components';
import { useRequestLoader } from '@zastrpay/hooks';

import { EmailOtpInput } from './EmailOtpInput';
import { FlowOverview } from './FlowOverview';
import { Step } from './models';
import { PhoneOtpInput } from './PhoneOtpInput';
import { PinInput } from './PinInput';
import { useFlow } from './use-flow';

export type FlowProps = ReturnType<typeof useFlow> & {
    renderStep: (step: Step) => ReactNode | undefined;
};

export const Flow: React.FC<FlowProps> = ({ step, setStep, renderStep, id, type, next, start }) => {
    const loading = useRequestLoader();

    const { t } = useTranslation('auth-flow');
    const { verifyPin } = useAuth();

    const doVerifyPin = async (pin: string, flowId?: string) => {
        await verifyPin(pin, flowId);
        next();
    };

    const doWithErrorHandling = async (currentStep: Step, f: () => Promise<unknown>) => {
        try {
            await f();
        } catch (error) {
            setStep({ ...currentStep, error: ensureResponseError(error) });
        }
    };

    const [overviewTitle, overviewSubtitle] =
        type === 'ChangeEmail'
            ? [t('settings.changeEmail.title'), t('settings.changeEmail.flowOverview')]
            : type === 'ChangePin'
              ? [t('settings.changePin.title'), t('settings.changePin.flowOverview')]
              : [t('settings.changePhone.title'), t('settings.changePhone.flowOverview')];

    const renderedError = useMemo(
        () => step?.error && <ErrorTrans t={t} error={step.error} />,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [step?.error],
    );

    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (step?.type) {
        // Common steps
        case 'flow-overview':
            return (
                <FlowOverview
                    requiredChallenges={step.requiredChallenges}
                    title={overviewTitle}
                    subTitle={overviewSubtitle}
                    onStart={start}
                    type={type}
                />
            );
        case 'verify-phone':
            return <PhoneOtpInput phone={step.phone} flowId={id} onVerify={next} />;
        case 'verify-email':
            return <EmailOtpInput email={step.email} flowId={id} onVerify={next} />;
        case 'verify-pin':
            return (
                <PinInput
                    title={t('settings.currentPin.title')}
                    subTitle={t('settings.currentPin.subTitle')}
                    resetPin={t('settings.currentPin.forgot')}
                    onPinEnter={(pin) => doWithErrorHandling(step, () => doVerifyPin(pin, id))}
                    error={renderedError}
                    disabled={loading}
                />
            );
        default:
            // Delegate component-specific steps to a function passed by the component
            if (step) {
                const renderedStep = renderStep(step);

                if (!renderedStep) {
                    console.error('Step rendering not defined for step:', step.type);
                }

                return renderedStep;
            } else {
                return null;
            }
    }
};
