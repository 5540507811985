import axiosRetry from 'axios-retry';

import { api } from '@zastrpay/common';

import { requestConfigs } from '../config';

api.apply((instance) => {
    axiosRetry(instance, {
        retries: requestConfigs.retries,
        retryDelay: (retryCount) => retryCount * requestConfigs.delay,
    });
});
