import { makeHubListener } from '@zastrpay/hooks';

import { CUSTOMER_AUTHENTICATION_EXTEVP } from './config';
import { AuthFlowEventType } from './models';

export type AuthFlowEventChannel = 'AuthFlowEvents';

export type AuthFlowEvent = {
    state: Extract<AuthFlowEventType, 'AuthFlowCompleted' | 'AuthFlowCancelled'>;
    authFlowId: string;
};

export const useHubListener = makeHubListener<AuthFlowEventChannel, AuthFlowEvent>(CUSTOMER_AUTHENTICATION_EXTEVP);
