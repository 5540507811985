import { ReactNode } from 'react';

import { useKycRequest } from './KycRequestProvider';
import { KycRequest } from './models';
import { EmailVerificationRequest } from './types/EmailVerificationRequest';
import { InitialVerificationRequest } from './types/InitialVerificationRequest';
import { PoaDocumentRequest } from './types/PoaDocumentRequest';
import { PoiDocumentRequest } from './types/PoiDocumentRequest';
import { SelfDeclaredLinksQuestionnaireRequest } from './types/SelfDeclaredLinksQuestionnaireRequest';
import { SelfDeclaredPepQuestionnaireRequest } from './types/SelfDeclaredPepQuestionnaireRequest';
import { SofwDocumentRequest } from './types/SofwDocumentRequest';
import { SofwQuestionnaireRequest } from './types/SofwQuestionnaireRequest';
import { withActiveKycRequest } from './with-active-kyc-request';

const getType = (request: KycRequest): ReactNode => {
    switch (request.type) {
        case 'EmailVerification':
            return <EmailVerificationRequest request={request} />;
        case 'InitialVerification':
            return <InitialVerificationRequest request={request} />;
        case 'PoaDocument':
            return <PoaDocumentRequest request={request} />;
        case 'PoiDocument':
            return <PoiDocumentRequest request={request} />;
        case 'SofDocument':
        case 'SowDocument':
            return <SofwDocumentRequest request={request} />;
        case 'SofQuestionnaire':
        case 'SowQuestionnaire':
            return <SofwQuestionnaireRequest request={request} />;
        case 'SelfDeclaredLinksQuestionnaire':
            return <SelfDeclaredLinksQuestionnaireRequest request={request} />;
        case 'SelfDeclaredPepQuestionnaire':
            return <SelfDeclaredPepQuestionnaireRequest request={request} />;
    }
};

export const ActiveKycRequest = withActiveKycRequest((): ReactNode => {
    const { current, loaded } = useKycRequest();

    if (!current || !loaded) {
        return null as ReactNode;
    }

    return getType(current);
});
