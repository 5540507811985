import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';

import { tokens } from '@zastrpay/theme';

export type LoaderProps = {
    size?: 'small' | 'medium' | 'large';
};

export const Loader: React.FC<LoaderProps> = ({ size }) => {
    const classes = useStyles();

    const sizeClass = {
        small: classes.sizeSmall,
        medium: classes.sizeMedium,
        large: classes.sizeLarge,
    }[size ?? 'medium'];

    return (
        <div className={mergeClasses(classes.loader, sizeClass)}>
            <div className={sizeClass} />
            <div className={sizeClass} />
            <div className={sizeClass} />
        </div>
    );
};

const useStyles = makeStyles({
    sizeSmall: {
        height: '10px',
        '&>div': {
            height: '5px',
            width: '5px',
        },
    },
    sizeMedium: {
        height: '20px',
        '&>div': {
            height: '10px',
            width: '10px',
        },
    },
    sizeLarge: {
        height: '26px',
        '&>div': {
            height: '13px',
            width: '13px',
        },
    },
    loader: {
        display: 'flex',
        gap: '5px',
        position: 'relative',

        '&>div': {
            borderRadius: '999px',
            backgroundColor: tokens.colorBrandForeground1,
            animationDuration: '1.5s',
            animationName: {
                '0%,100%': {
                    transform: 'translateY(0%)',
                },
                '50%': {
                    transform: 'translateY(100%)',
                },
            },
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease-in-out',

            '&:nth-child(2)': {
                animationDelay: '0.3s',
                opacity: '0.8',
            },
            '&:nth-child(3)': {
                animationDelay: '0.6s',
                opacity: '0.6',
            },
        },
    },
});
