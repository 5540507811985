import { CheckmarkCircleRegular, DismissCircleRegular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { track } from '@zastrpay/analytics';
import { MessagePage } from '@zastrpay/layout';
import { Merchant, Transaction } from '@zastrpay/transactions';

import { AuthorizeTransactionIntent } from './AuthorizeTransactionIntent';
import { TransactionIntent } from './models';
import { TransactionIntentCode } from './TransactionIntentCode';

export type ViewTransactionIntentProps = {
    intent: TransactionIntent;
    merchant?: Merchant;
    onCancel?: () => void;
    onExpire?: () => void;
    onRefresh?: () => void;
    onAuthorize?: (intent: TransactionIntent) => void;
    onFinalize?: (transaction: Transaction) => void;
    onFailure: () => void;
    onSuccess: () => void;
    onCancelled: () => void;
};

export const ViewTransactionIntent: React.FC<ViewTransactionIntentProps> = (props) => {
    const { t } = useTranslation('transaction-intents');
    const merchantName = props.merchant?.displayName ?? props.merchant?.name;

    const backText = merchantName ? t('common.back', { merchant: merchantName }) : t('common.back', { context: 'GenericMerchant' });

    useEffect(() => {
        if (props.intent.state === 'Expired') {
            track('qr_code_error', 'qr_code_expired', { trx_type: props.intent.type });
        } else if (props.intent.state === 'Declined') {
            track('qr_code_error', 'qr_code_declined', { trx_type: props.intent.type });
        }
    }, [props.intent.state, props.intent.type]);

    switch (props.intent.state) {
        case 'Expired':
            return (
                <MessagePage
                    icon={<DismissCircleRegular />}
                    title={t('expired.title')}
                    message={t('expired.subTitle')}
                    action={backText}
                    onAction={props.onFailure}
                />
            );
        case 'Finalized':
            // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
            switch (props.intent.stateDetails?.finalizeReason) {
                case 'TransactionCompleted':
                    return (
                        <MessagePage
                            icon={<CheckmarkCircleRegular />}
                            title={t('finalized.completed.title', { context: props.intent.type })}
                            message={t('finalized.completed.subTitle', { merchant: merchantName })}
                            action={backText}
                            // onAction={success}
                        />
                    );
                case 'TransactionDeclined':
                    return (
                        <MessagePage
                            icon={<CheckmarkCircleRegular />}
                            title={t('finalized.declined.title', { context: props.intent.type })}
                            message={t('finalized.declined.subTitle')}
                            action={backText}
                            onAction={props.onFailure}
                        />
                    );
                case 'TransactionCancelled':
                    return (
                        <MessagePage
                            icon={<CheckmarkCircleRegular />}
                            title={t('finalized.cancelled.title', { context: props.intent.type })}
                            message={t('finalized.cancelled.subTitle', {
                                context: props.intent.type,
                                merchant: merchantName,
                            })}
                            action={backText}
                            onAction={() => {
                                props.onFailure();
                            }}
                        />
                    );
                default:
                    return (
                        <MessagePage
                            icon={<CheckmarkCircleRegular />}
                            title={t('finalized.title', { context: props.intent.type })}
                            message={t('finalized.subTitle', { merchant: merchantName })}
                            action={backText}
                            onAction={props.onFailure}
                        />
                    );
            }
        case 'Cancelled':
            return (
                <MessagePage
                    icon={<DismissCircleRegular />}
                    title={t('cancelled.title', { context: props.intent.type })}
                    message={t('cancelled.subTitle')}
                    action={backText}
                    onAction={props.onCancelled}
                />
            );
        case 'Declined':
            return (
                <MessagePage
                    icon={<DismissCircleRegular />}
                    title={t('declined.title')}
                    message={t('declined.subTitle')}
                    action={backText}
                    onAction={props.onFailure}
                />
            );
        case 'PendingApproval':
            return <AuthorizeTransactionIntent intent={props.intent} merchant={merchantName} onAuthorize={props.onAuthorize} />;
        case 'Pending':
            return (
                <TransactionIntentCode
                    intent={props.intent}
                    merchant={merchantName}
                    onCancel={props.onCancel}
                    onExpire={props.onExpire}
                    onRefresh={props.onRefresh}
                    onFinalize={props.onFinalize}
                />
            );
        case 'Created':
            throw new Error('Unhandled transaction intent state'); //TODO: replace with error handling
    }
};
