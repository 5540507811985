import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { Alert } from '@zastrpay/components';
import { useNetworkState, useRequestLoader } from '@zastrpay/hooks';
import { tokens } from '@zastrpay/theme';

import { Header } from './Header';
import { useLayout } from './LayoutProvider';
import { SpinnerOverlay } from './SpinnerOverlay';
import { SpinnerPage } from './SpinnerPage';

export const Layout: React.FC = () => {
    const classes = useStyles();

    const { t } = useTranslation('layout');

    const network = useNetworkState();
    const loading = useRequestLoader();

    const { state } = useAuth();
    const { header, background } = useLayout();

    const leftHeaderSlot = header.left?.length ? header.left[header.left.length - 1] : [];
    const rightHeaderSlot = header.right?.length ? header.right[header.right.length - 1] : [];

    if (state === 'loading') {
        return <SpinnerPage />;
    }

    return (
        <>
            <Header className={classes.header} left={leftHeaderSlot} right={rightHeaderSlot} />
            {network === 'offline' && (
                <Alert className={classes.alert} type="warning">
                    {t('offlineError.title')}
                </Alert>
            )}
            {/* Nest spinner overlay in suspense to never show two loading indicators at the same time */}
            <Suspense fallback={<SpinnerPage />}>
                <SpinnerOverlay loading={loading} />

                <div className={mergeClasses(classes.content, background === 'subtle' && classes.withSubtleBackground)}>
                    <Outlet />
                </div>
            </Suspense>
        </>
    );
};

const useStyles = makeStyles({
    header: {
        position: 'sticky',
        inset: '0 0 auto',
        zIndex: 1,
        paddingTop: tokens.customHeaderPadding,
    },
    content: {
        display: 'flex',
        flexDirection: 'column', // Stack children vertically
        flexGrow: 1, // always take full height
    },
    alert: {
        margin: '0',
        borderRadius: '0',
    },
    withSubtleBackground: {
        backgroundColor: tokens.colorNeutralBackground6,
    },
});
