import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { useMemo } from 'react';

import { tokens } from '@zastrpay/theme';

export type StepperProps = {
    className?: string;
    count: number;
    /** Current active step, from `1` to `count` */
    current: number;
};

export const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
    const classes = useStyles();
    const steps = useMemo(() => Array.from({ length: props.count }), [props.count]);

    return (
        <div className={mergeClasses(classes.root, props.className)}>
            {steps.map((_, index) => (
                <div
                    key={index}
                    className={mergeClasses(
                        classes.step,
                        props.current === index + 1 && classes.stepActive,
                        props.current > index && classes.stepCompleted,
                    )}
                />
            ))}
        </div>
    );
};
const useStyles = makeStyles({
    root: {
        display: 'flex',
        boxSizing: 'border-box',
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        gap: tokens.spacingHorizontalS,
    },
    step: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
        height: '8px',
        width: '100%',
        borderRadius: '4px',
    },
    stepActive: {
        backgroundColor: tokens.customPaletteGreen,
        opacity: 0.6,
    },
    stepCompleted: {
        backgroundColor: tokens.customPaletteGreen,
    },
});
