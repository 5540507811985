import { useEffect } from 'react';

import { isNetworkError, isResponseError, memoryStorage } from '@zastrpay/common';
import { Header } from '@zastrpay/layout';

import { RedirectSession } from '../auth/models';
import { Merchant } from '../merchants/models';
import { GeneralError } from './GeneralError';
import { NetworkError } from './NetworkError';
import { SessionExpired } from './SessionExpired';

export type UnhandledErrorProps = {
    error: unknown;
    reset: () => void;
};

export const UnhandledError: React.FC<UnhandledErrorProps> = ({ error, reset }) => {
    useEffect(() => {
        const navigateRoot = () => {
            window.history.replaceState({}, '', '/');
            // reset the error after url change so the new url can get rendered
            reset();
        };

        window.addEventListener('popstate', navigateRoot);

        return () => window.removeEventListener('popstate', navigateRoot);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const go = (targetHref?: string) => {
        if (targetHref) {
            window.location.href = targetHref;
        } else if (document.referrer) {
            window.location.href = document.referrer;
        } else {
            window.history.back();
        }
    };

    const merchant = memoryStorage.retrieve<Merchant>('merchant');
    const redirectSession = memoryStorage.retrieve<RedirectSession>('redirectSession');

    return (
        <>
            <Header />
            {isNetworkError(error) ? (
                <NetworkError merchant={merchant} onBack={() => go(redirectSession?.redirectData.failureUrl)} />
            ) : isResponseError(error, 'Unauthorized') ? (
                <SessionExpired merchant={merchant} onBack={() => go(redirectSession?.redirectData.abortUrl)} />
            ) : (
                <GeneralError merchant={merchant} onBack={() => go(redirectSession?.redirectData.failureUrl)} />
            )}
        </>
    );
};
