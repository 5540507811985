import { makeStyles } from '@fluentui/react-components';
import { MoneyHandRegular } from '@fluentui/react-icons';
import { Trans, useTranslation } from 'react-i18next';

import { Account } from '@zastrpay/accounts';
import { useAuth } from '@zastrpay/auth';
import { FormattedCurrency } from '@zastrpay/components';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';
import { CreateTransaction, Merchant } from '@zastrpay/transactions';

import { getTransactionTypeFromRedirectTypeOrDirection, TransactionData } from '../auth/models';

export type WalletTransferConfirmationProps = {
    transactionData: TransactionData;
    merchant?: Merchant;
    account?: Account;
    onConfirmation?: (createTransaction: CreateTransaction) => void;
};

export const WalletTransferConfirmation: React.FC<WalletTransferConfirmationProps> = ({
    transactionData,
    merchant,
    account,
    onConfirmation,
}) => {
    const { t } = useTranslation('payment');
    const { customerId } = useAuth();
    const classes = useStyles();

    const onSubmit = () => {
        const createTransaction = {
            amount: transactionData.amount,
            currency: transactionData.currency,
            customerId: customerId,
            merchantId: merchant?.id,
            externalReference: transactionData.externalReference,
            type: getTransactionTypeFromRedirectTypeOrDirection(transactionData.direction, transactionData.type),
        } as CreateTransaction;

        onConfirmation?.(createTransaction);
    };

    const transactionKind = transactionData.direction ?? transactionData.type!;

    switch (transactionKind) {
        case 'CustomerToMerchant':
        case 'CustomerToMerchantTransfer':
        case 'MerchantToCustomer':
        case 'MerchantToCustomerTransfer':
            break;
        case 'PassthroughDeposit':
        case 'PassthroughWithdrawal':
        case 'CustomerToMerchantPassthrough':
        case 'MerchantToCustomerPassthrough':
            throw new Error(`Unsupported transaction kind ${transactionKind}`);
    }

    return (
        <MessagePage
            icon={<MoneyHandRegular />}
            title={
                <Trans
                    t={t}
                    i18nKey="transactionIntent.confirmation.title"
                    context={transactionKind}
                    components={{
                        amount: <FormattedCurrency currency={transactionData.currency} value={transactionData.amount} format="sign" />,
                    }}
                />
            }
            message={
                <div className={classes.text}>
                    <Trans
                        t={t}
                        i18nKey="transactionIntent.confirmation.subTitle"
                        context={transactionKind}
                        values={{ merchant: merchant?.displayName ?? merchant?.name }}
                        components={{
                            amount: (
                                <FormattedCurrency
                                    className={classes.amount}
                                    currency={transactionData.currency}
                                    value={transactionData.amount}
                                    format="sign"
                                />
                            ),
                        }}
                    />
                    {transactionData.direction === 'CustomerToMerchant' && (
                        <div className={classes.balance}>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="transactionIntent.confirmation.balance"
                                    values={{ merchant: merchant?.displayName ?? merchant?.name }}
                                    components={{
                                        balance: (
                                            <FormattedCurrency
                                                className={classes.amount}
                                                currency={account?.currency}
                                                value={account?.balance}
                                                format="sign"
                                            />
                                        ),
                                    }}
                                />
                            </li>
                            <li>
                                <Trans
                                    t={t}
                                    i18nKey="transactionIntent.confirmation.remainingBalance"
                                    values={{ merchant: merchant?.displayName ?? merchant?.name }}
                                    components={{
                                        balance: (
                                            <FormattedCurrency
                                                className={classes.amount}
                                                currency={account?.currency}
                                                value={(account?.balance ?? 0) - transactionData.amount}
                                                format="sign"
                                            />
                                        ),
                                    }}
                                />
                            </li>
                        </div>
                    )}
                </div>
            }
            action={t('transactionIntent.confirmation.action', { context: transactionKind })}
            onAction={onSubmit}
        />
    );
};

const useStyles = makeStyles({
    amount: {
        fontWeight: tokens.fontWeightBold,
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
    },
    balance: {
        marginTop: tokens.spacingVerticalL,
        marginLeft: tokens.spacingHorizontalS,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
});
