import { AxiosError, AxiosResponse, HttpStatusCode, InternalAxiosRequestConfig } from 'axios';

export abstract class TranslatableError<TKey extends string = string> extends Error {
    abstract readonly defaultTranslationKey: TKey;
    abstract readonly translationContext: string;
    abstract readonly translationPlaceholders: Record<string, unknown>;

    constructor(message: string) {
        super(message);
    }
}

export const ensureTranslatableError = <TErrorCode extends string = string>(error: unknown): TranslatableError<TErrorCode> => {
    if (error instanceof TranslatableError) {
        return error;
    }
    if (typeof error === 'string') {
        throw new Error(error);
    }
    if (error instanceof AxiosError) {
        error.stack = new Error().stack;
    }

    throw error;
};

export class ResponseError<
    TErrorCode extends string = string,
    TErrorDetails = Record<string, unknown>,
> extends TranslatableError<'error.response'> {
    override readonly defaultTranslationKey = 'error.response';

    private constructor(
        public readonly code: TErrorCode,
        message: string,
        public readonly responseCode: number,
        public readonly details?: TErrorDetails,

        public readonly requestMethod?: string,
        public readonly requestUrl?: string,
        public readonly requestConfig?: InternalAxiosRequestConfig,
        public readonly correlationId?: string,
        public readonly translationContext: string = code,
        public readonly translationPlaceholders: Record<string, unknown> = { ...details },
    ) {
        super(message);
    }

    static fromAxiosResponse<TErrorCode extends string = string, TErrorDetails = Record<string, unknown>>(
        response: AxiosResponse,
    ): ResponseError<TErrorCode, TErrorDetails> {
        let { message } = response.data;

        // we parse and extract the correlation id from the message and add it as a custom property during logging
        // in this way we are able to group the log entries by message in application insights
        const match = /\(CorrelationId: (.*)\)/.exec(message);

        const correlationMatch = match?.[0];
        const correlationId = match?.[1];

        if (correlationMatch && correlationId) {
            message = message.replace(correlationMatch, correlationMatch.replace(correlationId, '{correlationId}'));
        }

        return new ResponseError(
            response.data.code,
            message,
            response.status,
            response.data.details,
            response.config.method,
            response.config.url,
            response.config,
            correlationId,
        );
    }
}

export const isResponseError = <TErrorCode extends string = string>(
    error: unknown,
    codeOrStatus: TErrorCode | HttpStatusCode,
): error is ResponseError<TErrorCode> => {
    if (error instanceof ResponseError) {
        if (typeof codeOrStatus === 'string') {
            return error.code === codeOrStatus;
        } else if (typeof codeOrStatus === 'number') {
            return error.responseCode === codeOrStatus;
        }
    }

    return false;
};

export const ensureResponseError = <TErrorCode extends string = string>(error: unknown): ResponseError<TErrorCode> => {
    if (error instanceof ResponseError) {
        return error;
    }
    if (typeof error === 'string') {
        throw new Error(error);
    }
    if (error instanceof AxiosError) {
        error.stack = new Error().stack;
    }

    throw error;
};

export const isAxiosError = (error: unknown, type: string): error is AxiosError => {
    return error instanceof AxiosError && error.code === type;
};

export const isNetworkError = (error: unknown): error is AxiosError => {
    return isAxiosError(error, AxiosError.ERR_NETWORK);
};

export const isAuthenticationError = (error: unknown): error is AxiosError => {
    return isAxiosError(error, AxiosError.ERR_BAD_REQUEST) && error.response?.status === 401;
};

export const isAuthorizationError = (error: unknown): error is AxiosError => {
    return isAxiosError(error, AxiosError.ERR_BAD_REQUEST) && error.response?.status === 403;
};

export class ValidationError<TErrorCode extends string = string> extends TranslatableError<'error.validation'> {
    override readonly defaultTranslationKey = 'error.validation';
    override get translationContext() {
        return this.code;
    }
    override readonly translationPlaceholders = {};

    constructor(public readonly code: TErrorCode) {
        super('ValidationError: ' + code);
    }
}

export const isValidationError = <TErrorCode extends string = string>(
    error: unknown,
    errorCode: TErrorCode,
): error is ValidationError<TErrorCode> => {
    if (error instanceof ValidationError) {
        return error.code === errorCode;
    }

    return false;
};
