import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ChevronDown20Filled } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PrivacyPolicyDialog, TermsAndConditionsDialog, trackClick, trackPage } from '@zastrpay/analytics';
import { LANGUAGES, mapLanguage, ValidationError } from '@zastrpay/common';
import { Alert, Body, Button, Divider, ErrorTrans, Link, Phone, PHONE_REGEX, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

export type PhoneInputProps = {
    defaultValue?: string;
    error?: React.ReactElement;
    layout?: 'next' | 'login' | 'register';
    onInput?: (phone: string) => void;
    onChange?: () => void;
    onAction?: () => void;
};

export const PhoneInput: React.FC<PhoneInputProps> = (props) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation('pages');

    const [termsVisible, setTermsVisible] = useState(false);
    const [privacyVisible, setPrivacyVisible] = useState(false);

    const [language, setLanguage] = useState(mapLanguage(i18n.language));
    const [phone, setPhone] = useState<string>();
    const [validationError, setValidationError] = useState<ValidationError<'PhoneRegex'>>();

    useEffect(() => {
        trackPage('phone_input');
    }, []);

    const changeLanguage = (value: string) => {
        const language = LANGUAGES.find((current) => current.code === value);
        if (language) {
            setLanguage(language);

            if (language.code !== i18n.language) {
                i18n.changeLanguage(language.code);
            }
        }
    };
    const updatePhone = (value?: string) => {
        setPhone(value);
    };

    const verifyPhone = async () => {
        if (phone) {
            if (PHONE_REGEX.test(phone)) {
                setValidationError(undefined);
                props.onInput?.(phone);
            } else {
                setValidationError(new ValidationError('PhoneRegex'));
            }
        }
    };

    const openTerms = () => {
        setTermsVisible(true);
    };

    const openPolicy = () => {
        setPrivacyVisible(true);
    };

    const visibleLayout = props.layout ?? 'next';

    const translationContext = {
        login: 'Login' as const,
        register: 'Register' as const,
        next: 'Next' as const,
    }[visibleLayout];

    return (
        <Page className={classes.page}>
            <Title>{t('phone.title', { context: translationContext })}</Title>
            <Body>{t('phone.subTitle', { context: translationContext })}</Body>

            <div className={classes.phoneContainer}>
                <Phone onChange={updatePhone} defaultValue={props.defaultValue} />
                {validationError ? (
                    <Alert type="error">
                        <ErrorTrans t={t} i18nKey="phone.error.validation" error={validationError} />
                    </Alert>
                ) : (
                    props.error && <Alert type="error">{props.error}</Alert>
                )}
            </div>

            <Button
                appearance="primary"
                size="large"
                onClick={() => {
                    trackClick('phone_input', 'next');
                    verifyPhone();
                }}
                disabled={!phone}
            >
                {t('phone.continue', { context: translationContext })}
            </Button>

            {visibleLayout === 'next' && (
                <Body>
                    <Trans
                        t={t}
                        i18nKey="phone.agreement"
                        values={{ action: t('phone.continue', { context: translationContext }) }}
                        components={{
                            termsLink: <Link inline onClick={openTerms} />,
                            policyLink: <Link inline onClick={openPolicy} />,
                        }}
                    />
                </Body>
            )}

            {visibleLayout === 'login' && (
                <>
                    <Divider>{t('phone.or')}</Divider>
                    <Button
                        appearance="outline"
                        size="large"
                        onClick={() => {
                            trackClick('phone_input', 'next');
                            return props.onAction?.();
                        }}
                    >
                        {t('phone.continue', { context: 'Register' })}
                    </Button>
                    <Body>
                        <Trans
                            t={t}
                            i18nKey="phone.agreement"
                            values={{ action: t('phone.continue', { context: 'Register' }) }}
                            components={{
                                termsLink: <Link inline onClick={openTerms} />,
                                policyLink: <Link inline onClick={openPolicy} />,
                            }}
                        />
                    </Body>
                </>
            )}

            {visibleLayout === 'register' && (
                <>
                    <Body>
                        <Trans
                            t={t}
                            i18nKey="phone.agreement"
                            values={{ action: t('phone.continue', { context: translationContext }) }}
                            components={{
                                termsLink: <Link inline onClick={openTerms} />,
                                policyLink: <Link inline onClick={openPolicy} />,
                            }}
                        />
                    </Body>
                    <Divider>{t('phone.or')}</Divider>
                    <Button appearance="outline" size="large" onClick={props.onAction}>
                        {t('phone.continue', { context: 'Login' })}
                    </Button>
                </>
            )}

            {props.onChange && (
                <Link
                    className={mergeClasses(classes.link, classes.linkPhone)}
                    onClick={() => {
                        trackClick('phone_input', 'change_mobile_number');
                        return props.onChange?.();
                    }}
                >
                    {t('phone.changePhone')}
                </Link>
            )}

            <div className={classes.languageContainer}>
                <Link className={mergeClasses(classes.link, classes.linkDropdown)}>
                    {language.name} <ChevronDown20Filled />
                </Link>
                <select value={i18n.language} className={classes.languageDropdown} onChange={(event) => changeLanguage(event.target.value)}>
                    {LANGUAGES.map(({ code, name }) => (
                        <option value={code} key={code}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>

            <TermsAndConditionsDialog open={termsVisible} onOpenChange={setTermsVisible} />
            <PrivacyPolicyDialog open={privacyVisible} onOpenChange={setPrivacyVisible} />
        </Page>
    );
};

const useStyles = makeStyles({
    page: {
        justifyContent: 'center',
        // standard defines flex: 1 as flex: 1 1 0; but chrome treads it as flex: 1 1 0%; which is what we need here
        flex: '1 1 0%',
    },
    phoneContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        marginBottom: tokens.spacingVerticalM,
        gap: tokens.spacingHorizontalXS,
    },
    languageContainer: {
        position: 'relative',
    },
    link: {
        fontVariant: 'lining-nums',
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
    linkPhone: {
        margin: tokens.spacingVerticalS,
        textAlign: 'center',
    },
    linkDropdown: {
        display: 'inline-flex',
        gap: tokens.spacingHorizontalXS,
    },
    languageDropdown: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
    },
});
