import { createContext, PropsWithChildren, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { useErrorBoundary } from '@zastrpay/components';

import { skip as doSkip, submitData as doSubmitData, getList } from './api';
import { EMAIL_KYC_ENABLED } from './config';
import { enforced, KycRequest, sort, SubmitDataRequest } from './models';

type SubmittedState = {
    id: string;
    reactivateOn: Date;
};

type KycRequestState = {
    loaded: boolean;
    submitted?: SubmittedState;
    current?: KycRequest;
    completed: boolean;
    requests: KycRequest[];
    skippedRequests: string[];
};

export type KycRequestContext = {
    current?: KycRequest;
    all: KycRequest[];
    loaded: boolean;
    completed: boolean;
    skip: () => void;
    seen: () => Promise<void>;
    submit: () => void;
    complete: () => void;
    submitData: (data: SubmitDataRequest, partial?: boolean) => Promise<void>;
    refresh: () => Promise<void>;
};

export type KycRequestProviderProps = {
    /**
     * challenges only the required kyc requests if only at least one of them is required otherwise it chains all of them
     * @default 'all'
     */
    prioritize?: 'required' | 'all';
};

const Context = createContext<KycRequestContext | null>(null);

const REACTIVATE_AFTER = 10_000;

export const KycRequestProvider: React.FC<PropsWithChildren<KycRequestProviderProps>> = (props) => {
    const handle = useRef<ReturnType<typeof setTimeout>>();

    const { trackError } = useErrorBoundary();
    const { state, customerId, refreshCustomer } = useAuth();
    const navigate = useNavigate();

    const [{ current, loaded, requests, skippedRequests, completed }, setState] = useState<KycRequestState>({
        loaded: false,
        completed: true,
        requests: [],
        skippedRequests: [],
    });

    const submit = () => {
        if (current && handle.current === undefined) {
            const now = Date.now();
            const remainingRequests = requests.filter((request) => request.id !== current.id);

            setState({
                loaded: true,
                requests: remainingRequests,
                skippedRequests,
                current: remainingRequests[0],
                completed: false,
                submitted: {
                    id: current.id,
                    reactivateOn: new Date(now + REACTIVATE_AFTER),
                },
            });

            handle.current = setTimeout(() => refresh(), REACTIVATE_AFTER);
        }
    };

    const complete = () => {
        refreshCustomer().then(() => {
            setState((state) => ({ ...state, completed: !state.requests?.length }));
        });
    };

    const submitData = async (data: SubmitDataRequest, skipPendingExternalVerification?: boolean) => {
        if (current) {
            const updated = await doSubmitData(current.id, data);

            // if the request is still pending, we should wait for the external verification
            // the consumer of this api should handle the push notifications
            if (!skipPendingExternalVerification || updated.state !== 'PendingExternalVerification') {
                const remainingRequests = requests.filter((request) => request.id !== current.id);

                setState({
                    loaded: true,
                    completed: false,
                    requests: remainingRequests,
                    skippedRequests,
                    current: remainingRequests[0],
                });
            }
        } else {
            throw new Error('submitData: No kyc request to submit');
        }
    };

    const skip = () => {
        if (current?.state === 'Pending' || current?.type === 'EmailVerification') {
            const remainingSkippedRequests = [...skippedRequests, current.id];
            const remainingRequests = requests.filter((request) => !remainingSkippedRequests.includes(request.id));

            setState({
                loaded: true,
                requests: remainingRequests,
                completed: !remainingRequests.length,
                skippedRequests: remainingSkippedRequests,
                current: remainingRequests[0],
            });

            if (remainingRequests.length) {
                navigate('/kyc-request');
            }
        }
    };

    const seen = async () => {
        if (current?.dueAfter) {
            const skipped = await doSkip(current.id);

            setState({
                loaded: true,
                completed: false,
                requests: requests.map((request) => (request.id === skipped.id ? skipped : request)),
                skippedRequests,
                current: skipped,
            });
        }
    };

    const refresh = useCallback(async () => {
        const updateState = (updated: SetStateAction<KycRequestState>) => {
            setState(updated);

            if (handle.current !== undefined) {
                clearTimeout(handle.current);
                handle.current = undefined;
            }
        };

        try {
            if (state === 'authenticated') {
                const requests = await getList(customerId);

                const requiredRequests: KycRequest[] = [];
                const optionalRequests: KycRequest[] = [];

                for (const request of requests) {
                    if (!EMAIL_KYC_ENABLED && request.type === 'EmailVerification') {
                        continue;
                    }

                    if (enforced(request)) {
                        requiredRequests.push(request);
                    } else {
                        optionalRequests.push(request);
                    }
                }

                requiredRequests.sort(sort);
                optionalRequests.sort(sort);

                const visibleRequests =
                    props.prioritize === 'required' && requiredRequests.length
                        ? requiredRequests
                        : [...requiredRequests, ...optionalRequests];

                updateState(({ skippedRequests }) => {
                    const filteredRequests = visibleRequests.filter((request) => !skippedRequests.includes(request.id));

                    return {
                        loaded: true,
                        failed: false,
                        completed: completed && !filteredRequests.length,
                        skippedRequests,
                        requests: filteredRequests,
                        current: filteredRequests[0],
                    };
                });
            }
        } catch (error) {
            trackError(error);

            updateState({ loaded: true, completed: true, requests: [], skippedRequests: [] });
        }
    }, [state, customerId, props.prioritize, trackError, completed]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return (
        <Context.Provider value={{ current, all: requests, loaded, completed, submit, complete, refresh, seen, skip, submitData }}>
            {props.children}
        </Context.Provider>
    );
};

export const useKycRequest = (): KycRequestContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useKycRequest must be used within a KycRequestProvider');
    }

    return context;
};
