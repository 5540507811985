import { required } from '@zastrpay/common';

export const CUSTOMER_AUTHENTICATION_CMDH = required(import.meta.env.VITE_CUST_AUTH_SVC_CMDH_URL, 'VITE_CUST_AUTH_SVC_CMDH_URL');

export const MOCK_AUTHENTICATION_HEADERS = import.meta.env.VITE_MOCK_AUTHENTICATION_HEADERS === 'true';
export const MOCK_AUTHENTICATION_APP = MOCK_AUTHENTICATION_HEADERS
    ? required(import.meta.env.VITE_MOCK_AUTHENTICATION_APP, 'VITE_MOCK_AUTHENTICATION_APP')
    : undefined;

export const RECAPTCHA_SITE_KEY: string | undefined = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
export const PREFERRED_AUTH_METHOD_DURATION_DAYS = Number(import.meta.env.VITE_PREFERRED_AUTH_METHOD_DURATION_DAYS || 0);

// Temporary A/B testing feature toggle to only serve alternative login methods to a subset of users
export const ALTERNATIVE_LOGIN_REGEX = new RegExp(import.meta.env.VITE_AB_TESTING_ALTERNATIVE_LOGIN_REGEX || 'DISABLED');
