import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { register } from '@zastrpay/common';
import { Button, InputField } from '@zastrpay/components';
import { Page, PageTitle } from '@zastrpay/layout';

import { Identity } from '../models';

export type PersonalData = Pick<Identity, 'firstName' | 'lastName' | 'maidenName' | 'middleName'>;

export type PersonalDataFormProps = {
    defaultValues?: Partial<PersonalData>;
    onComplete?: (details: PersonalData) => void;
};

const NAME_REGEX = /^[\p{L}'\- ]{2,50}$/u;

export const PersonalDataForm: React.FC<PersonalDataFormProps> = ({ defaultValues, onComplete }) => {
    const { t } = useTranslation('registration');
    const { control, handleSubmit } = useForm<PersonalData>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues,
    });

    useEffect(() => {
        trackPage('personal_details');
    }, []);

    const submit = handleSubmit((data) => {
        trackClick('personal_details', 'continue');

        onComplete?.(data);
    });

    return (
        <Page align="fill">
            <PageTitle title={t('personalDetails.title')} subTitle={t('personalDetails.subTitle')} />

            <InputField
                {...register(control, 'firstName', {
                    required: true,
                    pattern: { value: NAME_REGEX, message: t('personalDetails.patternError') },
                })}
                size="large"
                label={t('personalDetails.firstName')}
                autoComplete="given-name"
                autoFocus
            />

            {/* <InputField
                {...register(control, 'middleName', {
                    pattern: { value: NAME_REGEX, message: t('personalDetails.patternError') },
                })}
                size="large"
                label={
                    <Trans
                        t={t}
                        i18nKey="personalDetails.middleName"
                        components={{ optional: <span className={classes.optional} /> }}
                    />
                }
                autoComplete="additional-name"
            /> */}

            <InputField
                {...register(control, 'lastName', {
                    required: true,
                    pattern: { value: NAME_REGEX, message: t('personalDetails.patternError') },
                })}
                size="large"
                label={t('personalDetails.lastName')}
                autoComplete="family-name"
            />

            {/* <InputField
                {...register(control, 'maidenName', {
                    pattern: { value: NAME_REGEX, message: t('personalDetails.patternError') },
                })}
                size="large"
                label={
                    <Trans
                        t={t}
                        i18nKey="personalDetails.maidenName"
                        components={{ optional: <span className={classes.optional} /> }}
                    />
                }
            /> */}

            <Button appearance="primary" onClick={submit} size="large">
                {t('personalDetails.submit')}
            </Button>
        </Page>
    );
};

// const useStyles = makeStyles({
//     optional: {
//         color: tokens.colorNeutralForeground4,
//         fontWeight: tokens.fontWeightRegular,
//         fontSize: tokens.fontSizeBase300,
//     },
// });
