import { api, PagedList } from '@zastrpay/common';

import { LOCATION_QRYH } from '../config';
import { SearchAddress } from './models';

export const searchAddresses = (
    query: string,
    language: string,
    countries: string[] = [],
    limit = 5,
): Promise<PagedList<SearchAddress>> => {
    return api.get<PagedList<SearchAddress>>(`${LOCATION_QRYH}/addresses/search`, {
        params: { query, language, countries: countries.join(','), _limit: limit },
        // don't display the loader overlay since this is inside of a search box
        loader: false,
    });
};
