import { makeStyles } from '@fluentui/react-components';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { required } from '@zastrpay/common';
import { Alert, Body, Link, Pin, PinElement, Timer, TimerElement } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export const OTP_RESEND_WAIT = Number(required(import.meta.env.VITE_OTP_RESEND_WAIT, 'VITE_OTP_RESEND_WAIT'));

export type OtpInputElement = {
    focus: () => void;
    reset: () => void;
};

export type OtpInputProps = {
    error?: React.ReactElement;
    disabled?: boolean;
    onInput?: (otp?: string) => void;
    onResend?: () => void;
};

export const OtpInput = forwardRef<OtpInputElement, OtpInputProps>((props, ref) => {
    const pinRef = useRef<PinElement>(null);
    const timer = useRef<TimerElement>(null);
    const { t } = useTranslation('pages');
    const [canSend, setCanSend] = useState(true);
    const classes = useStyles();

    useImperativeHandle(ref, () => ({
        reset: () => {
            pinRef.current?.reset();
        },
        focus: () => {
            pinRef.current?.focus();
        },
    }));

    const timerEnded = () => {
        setCanSend(true);
    };

    const resendOtp = () => {
        pinRef.current?.reset();
        props.onResend?.();

        setCanSend(false);
        timer.current?.reset();
    };

    return (
        <div className={classes.root}>
            <Pin length={6} ref={pinRef} onInput={props.onInput} autoFill autoFocus hyphenated={true} disabled={props.disabled} />

            {props.error && <Alert type="error">{props.error}</Alert>}

            <Body className={classes.body}>
                {canSend ? (
                    <Link onClick={resendOtp} inline className={classes.link}>
                        {t('otpInput.sendAgain')}
                    </Link>
                ) : (
                    <Trans
                        t={t}
                        i18nKey="otpInput.sendAgainIn"
                        className={classes.timer}
                        components={{
                            timer: <Timer seconds={OTP_RESEND_WAIT} ref={timer} onEnd={timerEnded} />,
                        }}
                    />
                )}
            </Body>
        </div>
    );
});

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalM,
    },
    body: {
        fontSize: '.8125rem',
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
    },
    timer: {
        color: tokens.colorNeutralForeground2,
        fontWeight: tokens.fontWeightSemibold,
    },
});
