import { makeStyles } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { AlertDialog } from '@zastrpay/components';
import { PinSetup } from '@zastrpay/registration';
import { tokens } from '@zastrpay/theme';

import { Flow } from '../Flow';
import { Step } from '../models';
import { useFlow } from '../use-flow';

export type ChangePinSettingsProps = {
    onChange?: () => void;
};
export const ChangePinSettings: React.FC<ChangePinSettingsProps> = ({ onChange }) => {
    const classes = useStyles();

    const { t } = useTranslation('auth-flow');

    const flow = useFlow('ChangePin', () => ({ type: 'pin-setup', success: false }));

    const onPinSetupCompleted = () => {
        flow.setStep({ type: 'pin-setup', success: true });
        trackPage('change_pin_success');
    };

    const renderStep = (step: Step) => {
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (step.type) {
            case 'pin-setup':
                return (
                    <>
                        <PinSetup onComplete={onPinSetupCompleted} flowId={flow?.id} className={classes.page} />
                        <AlertDialog
                            type="success"
                            title={t('changePin.pinIsChanged.title')}
                            content={t('changePin.pinIsChanged.subTitle')}
                            open={step.success}
                            onOpenChange={onChange}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return <Flow {...flow} renderStep={renderStep} />;
};
const useStyles = makeStyles({
    page: {
        backgroundColor: tokens.colorNeutralBackground1,
        flexGrow: 1,
    },
});
