import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackClick, trackInput, trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, TranslatableError } from '@zastrpay/common';
import { ErrorTrans } from '@zastrpay/components';
import { useRequestLoader } from '@zastrpay/hooks';
import { DeprecatedOtpInput } from '@zastrpay/pages';

import { EmailData, EmailForm } from './EmailForm';

export type EmailVerificationFormProps = {
    defaultValues?: Partial<EmailData>;
    onComplete?: (details: EmailData) => void;
};

type SetupEmailStep = {
    type: 'setup-email';
    email?: string;
};

type VerifyEmailStep = {
    type: 'verify-email';
    email: string;
};

type Step = SetupEmailStep | VerifyEmailStep;

export const EmailVerificationForm: React.FC<EmailVerificationFormProps> = (props) => {
    const loading = useRequestLoader();

    const { t, i18n } = useTranslation('registration');
    const { createOtpAuthFactor, generateOtpCode, verifyOtpCode } = useAuth();

    const [step, setStep] = useState<Step>({ type: 'setup-email', email: props.defaultValues?.email });
    const [error, setError] = useState<TranslatableError>();

    useEffect(() => {
        if (step.type === 'verify-email') {
            trackPage('email_verify');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tryHandleError = (error: unknown) => {
        setError(ensureResponseError(error));
    };

    const doSetEmail = async (emailForm: EmailData) => {
        try {
            setError(undefined);

            if (emailForm.email) {
                await createOtpAuthFactor('Email', emailForm.email, i18n.language);
                setStep({ type: 'verify-email', email: emailForm.email });
            } else {
                props.onComplete?.({ email: emailForm.email });
            }
        } catch (error) {
            tryHandleError(error);
        }
    };

    const doVerifyEmailOtp = async (email: string, code: string) => {
        trackInput('email_verify', 'enter_email_otp');

        try {
            setError(undefined);

            await verifyOtpCode('Email', code);
            props.onComplete?.({ email: email });
        } catch (error) {
            tryHandleError(error);
        }
    };

    const resendCustomerEmailOtp = async () => {
        setError(undefined);
        trackClick('email_verify', 'resend_sms_otp');

        try {
            await generateOtpCode('Email', undefined, i18n.language);
        } catch (error) {
            tryHandleError(error);
        }
    };

    switch (step.type) {
        case 'setup-email':
            return (
                <EmailForm
                    defaultValues={props.defaultValues}
                    error={error && <ErrorTrans t={t} error={error} additionalContext="Email" />}
                    onComplete={(data) => doSetEmail(data)}
                />
            );
        case 'verify-email':
            return (
                <DeprecatedOtpInput
                    onInput={(otp) => otp && doVerifyEmailOtp(step.email, otp)}
                    onResend={() => resendCustomerEmailOtp()}
                    error={error && <ErrorTrans t={t} error={error} additionalContext="Email" />}
                    value={step.email}
                    disabled={loading}
                    onNoAccess={() => setStep({ type: 'setup-email', email: step.email })}
                    mode="email"
                />
            );
    }
};
