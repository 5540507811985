import { bool, required } from '@zastrpay/common';

export const otpResendWaitSeconds = 30;
export const idRequirementThreshold = Number(import.meta.env.VITE_DEFAULT_ID_REQUIREMENT_THRESHOLD ?? 2000);

export const TRANSACTION_CMDH = required(import.meta.env.VITE_TRX_SVC_CMDH_URL, 'VITE_TRX_SVC_CMDH_URL');
export const TRANSACTION_QRYH = required(import.meta.env.VITE_TRX_SVC_QRYH_URL, 'VITE_TRX_SVC_QRYH_URL');

export const eventHubs = {
    trxSvcEvhUrl: required(import.meta.env.VITE_TRX_SVC_EVH_URL, 'VITE_TRX_SVC_EVH_URL'),
};

export const feature = {
    clearTextCode: bool(import.meta.env.VITE_CLEAR_TEXT_TRX_CODE),
};
