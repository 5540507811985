// eslint-disable-next-line no-restricted-imports
import { Select as FuiSelect, SelectProps as FuiSelectProps, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { forwardRef } from 'react';

import { tokens } from '@zastrpay/theme';

export const Select: React.FC<FuiSelectProps> = forwardRef<HTMLSelectElement, FuiSelectProps>(({ className, ...props }, ref) => {
    const classes = useStyles();

    return <FuiSelect ref={ref} {...props} className={mergeClasses(className, classes.select)} />;
});

const useStyles = makeStyles({
    select: {
        fontVariant: 'lining-nums',
        '& > select': {
            ...shorthands.borderColor(tokens.colorNeutralStroke1),
            ':hover': {
                ...shorthands.borderColor(tokens.colorNeutralStroke1),
            },
            ':active': {
                ...shorthands.borderColor(tokens.colorNeutralStroke1Selected),
            },
            ':focus': {
                ...shorthands.borderColor(tokens.colorNeutralStroke1Selected),
            },
            ':focus-within': {
                ...shorthands.borderColor(tokens.colorNeutralStroke1Selected),
            },
        },
        borderBottomStyle: 'none',
        ':hover': {
            borderBottomStyle: 'none',
        },
        ':active': {
            borderBottomStyle: 'none',
        },
        ':focus-within': {
            borderBottomStyle: 'none',
            '::after': {
                content: 'none',
            },
        },
    },
});
