import { TranslatableError } from '@zastrpay/common';

type AuthenticationErrorCode = 'NoCustomerId' | 'NoSessionId' | 'NoVerificationToComplete' | 'NoCaptchaToken' | 'InvalidSession';

export class AuthenticationError extends TranslatableError<'error.authentication'> {
    override readonly defaultTranslationKey = 'error.authentication';

    override get translationContext() {
        return this.code;
    }

    override get translationPlaceholders() {
        return { ...this.details };
    }

    constructor(
        public message: string,
        public code: AuthenticationErrorCode,
        public details?: Record<string, unknown>,
    ) {
        super(message);
    }
}

export const isAuthenticationError = (error: unknown, errorCode: AuthenticationErrorCode): error is AuthenticationError => {
    if (error instanceof AuthenticationError) {
        return error.code === errorCode;
    }

    return false;
};
