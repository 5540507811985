import { DismissCircleRegular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { MessagePage } from '@zastrpay/layout';

import { Merchant } from '../merchants/models';

export type NetworkErrorProps = {
    merchant?: Merchant;
    onBack: () => void;
};

export const NetworkError: React.FC<NetworkErrorProps> = (props) => {
    const { t } = useTranslation('payment');

    const name = props.merchant?.displayName ?? props.merchant?.name ?? t('common.merchant');

    useEffect(() => {
        trackPage('error_network_error', { type: 'error' });
    }, []);

    return (
        <MessagePage
            icon={<DismissCircleRegular />}
            title={t('common.networkError.title')}
            message={t('common.networkError.subTitle', { merchant: name })}
            action={t('common.networkError.back', { merchant: name })}
            onAction={props.onBack}
        />
    );
};
