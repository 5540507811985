import { useEffect } from 'react';

import { trackPage } from '@zastrpay/analytics';
import { Support as SharedSupport } from '@zastrpay/pages';

import { useBackNavigation } from '../layout/SessionNavigationProvider';

export const Support: React.FC = () => {
    useBackNavigation();

    useEffect(() => {
        trackPage('support_page');
    }, []);

    return <SharedSupport />;
};
