import { makeStyles } from '@fluentui/react-components';
import { ChevronRight24Regular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { Body, Button, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { AuthFlowChallenge, AuthFlowType } from './models';

export type FlowOverviewProps = {
    title: string;
    subTitle: string;
    type: AuthFlowType;
    requiredChallenges: AuthFlowChallenge[];
    onStart?: () => void;
};

export const FlowOverview: React.FC<FlowOverviewProps> = (props) => {
    const { t } = useTranslation('auth-flow');
    const classes = useStyles();

    useEffect(() => {
        trackPage('flow_overview', { auth_flow_type: props.type });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toDisplayText = (challenge: AuthFlowChallenge): string => {
        switch (challenge) {
            case 'Email':
                return challenge.toString();
            case 'Pin':
            case 'Sms':
                return challenge.toUpperCase();
        }
    };
    return (
        <Page align="fill" className={classes.page}>
            <Title>{props.title}</Title>
            <Body>{props.subTitle}</Body>
            <div className={classes.requiredChallenges}>
                {props.requiredChallenges.map((challenge) => (
                    <div key={challenge} className={classes.root}>
                        <ChevronRight24Regular className={classes.headerIcon} />
                        <div className={classes.text}>
                            <Body className={classes.title}>{toDisplayText(challenge)}</Body>
                        </div>
                    </div>
                ))}
            </div>
            <Button appearance="primary" size="large" onClick={() => props.onStart?.()}>
                {t('settings.next')}
            </Button>
        </Page>
    );
};
const useStyles = makeStyles({
    page: {
        backgroundColor: tokens.colorNeutralBackground1,
        flexGrow: 1,
    },
    requiredChallenges: {
        width: '100%',
    },
    root: {
        display: 'grid',
        gridTemplateColumns: '32px 1fr',
        alignItems: 'center',
        justifyItems: 'center',
        padding: `${tokens.spacingVerticalS} 0`,
        transition: `background-color ${tokens.durationNormal} ${tokens.curveEasyEase}`,
    },
    text: {
        justifySelf: 'start',
        gridColumnStart: '2',
        margin: '0 0 0 -8px',
    },
    title: {
        display: 'block',
        textAlign: 'start',
    },
    headerIcon: {
        textAlign: 'left',
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeHero900,
        margin: '-2px 0 -2px -20px',
    },
});
