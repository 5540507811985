/* eslint-disable no-restricted-imports */
import {
    Textarea as FuiTextarea,
    TextareaProps as FuiTextareaProps,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components';
import { forwardRef } from 'react';

import { tokens } from '@zastrpay/theme';

export const Textarea = forwardRef<HTMLTextAreaElement, FuiTextareaProps>(({ className, ...props }, ref) => {
    const classes = useStyles();

    return <FuiTextarea {...props} ref={ref} className={mergeClasses(className, classes.textarea, props.disabled && classes.disabled)} />;
});

const useStyles = makeStyles({
    textarea: {
        fontVariant: 'lining-nums',
        borderBottomColor: tokens.colorNeutralStroke1,
        ':hover': {
            borderBottomColor: tokens.colorNeutralStroke1,
        },
        ':focus-within': {
            ...shorthands.borderColor(`${tokens.colorNeutralStroke1Selected} !important`),
            '::after': {
                content: 'none',
            },
        },
    },
    disabled: {
        backgroundColor: `${tokens.colorNeutralBackgroundDisabled} !important`,
    },
});
