import { Subtitle2 } from '@fluentui/react-components';
import { DocumentCheckmarkRegular, LocationRegular } from '@fluentui/react-icons';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { track, trackClick, trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { Alert, Body, BrandedQrCode, BulletList, Button, Dialog, Link, Title } from '@zastrpay/components';
import { Page, SpinnerOverlay } from '@zastrpay/layout';

import { useHubListener } from './hub';

const ShopListDialog = lazy(() => import('@zastrpay/shops').then((module) => ({ default: module.ShopListDialog })));

export type CodePageProps = {
    onComplete?: () => void;
};

const idDocuments = ['IdCard', 'Passport', 'ResidencePermit', 'OtherDocument'] as const;

export const CodePage: React.FC<CodePageProps> = (props) => {
    const { t } = useTranslation('registration');
    const { customerId } = useAuth();

    useEffect(() => {
        trackPage('registration_qr_code');
    }, []);

    useHubListener('CustomerEvents', (event) => {
        if (event.type === 'CustomerRegistered') {
            track('registration_qr_code', 'registration_successful');
            props.onComplete?.();
        }
    });

    const [shopsOpen, setShopsOpen] = useState(false);
    const [idDocumentsOpen, setIdDocumentsOpen] = useState(false);

    // we do not check authentication state as when we handle re-registration we verify only
    // phone number, but the customer is not signed in
    if (!customerId) {
        return <Navigate to="/login" />;
    }

    const findShops = () => {
        trackClick('registration_qr_code', 'find_shops');

        setShopsOpen(true);
    };

    return (
        <Page>
            <Title>{t('qr.title')}</Title>

            <Alert type="info">
                <Trans t={t} i18nKey="qr.info" components={{ showMoreLink: <Link inline onClick={() => setIdDocumentsOpen(true)} /> }} />
            </Alert>

            <BrandedQrCode content={`C:${customerId}`} />

            <Button appearance="outline" size="large" onClick={findShops} icon={<LocationRegular />}>
                {t('qr.findShops')}
            </Button>

            <Body>
                <Trans
                    t={t}
                    i18nKey="qr.later"
                    components={{ homepageLink: <Link inline href="https://zastrpay.com" target="_blank" /> }}
                />
            </Body>

            <Dialog
                icon={<DocumentCheckmarkRegular />}
                title={t('qr.acceptedDocuments')}
                open={idDocumentsOpen}
                onOpenChange={setIdDocumentsOpen}
                align="bottom"
            >
                <BulletList as="ul" size="small">
                    {idDocuments.map((doc) => (
                        <li key={`id-doc-${doc.toLowerCase()}`}>
                            <Trans t={t} i18nKey="qr.idDocument" context={doc} components={{ title: <Subtitle2 /> }} />
                        </li>
                    ))}
                </BulletList>
            </Dialog>

            <Suspense fallback={<SpinnerOverlay loading />}>
                {shopsOpen && <ShopListDialog open={shopsOpen} onOpenChange={setShopsOpen} />}
            </Suspense>
        </Page>
    );
};
