export class Time {
    public days: number;
    public hours: number;
    public minutes: number;
    public seconds: number;

    constructor(private readonly value: string) {
        const match = value.match(/^(\d{0,4}\.)?(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/);

        if (match) {
            this.days = parseInt(match[1] ?? '0');
            this.hours = parseInt(match[2]);
            this.minutes = parseInt(match[3]);
            this.seconds = parseInt(match[4]);
        } else {
            throw new Error(`Invalid time format: ${value}`);
        }
    }

    public toString(): string {
        return this.value;
    }
}
