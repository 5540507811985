import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@zastrpay/auth';
import { ensureResponseError } from '@zastrpay/common';

import { start as doStart, getConfig } from './api';
import { AuthFlow, AuthFlowChallenge, AuthFlowType, Step } from './models';

export const useFlow = (type: AuthFlowType, handleChangeStep: () => Step | Promise<Step>) => {
    const { i18n } = useTranslation('auth-flow');
    const { state, phone, generateOtpCode } = useAuth();

    const [step, setStep] = useState<Step | undefined>();
    const [flow, setFlow] = useState<AuthFlow>();
    const [pendingChallenges, setPendingChallenges] = useState<AuthFlowChallenge[]>();

    useEffect(() => {
        const initializeStep = async () => {
            const config = await getConfig(type);

            if (config.requiredChallenges.length === 0) {
                await start();
            } else {
                setStep({ type: 'flow-overview', requiredChallenges: config.requiredChallenges });
            }
        };

        initializeStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (flow && pendingChallenges) {
            progressFlow(flow.id, pendingChallenges[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingChallenges]);

    const triggerEmailVerification = async (flowId: string) => {
        const email = await generateOtpCode('Email', undefined, i18n.language, flowId);
        setStep({ type: 'verify-email', email });
    };

    const triggerPhoneVerification = async (flowId: string, phone: string) => {
        await generateOtpCode('Phone', phone, i18n.language, flowId);
        setStep({ type: 'verify-phone', phone: phone });
    };

    const start = async () => {
        const flow = await doStart(type);

        setFlow(flow);

        switch (flow.state) {
            case 'PendingChallenges':
                return setPendingChallenges(flow.stateDetails?.pendingChallengesReason);
            case 'PendingChange':
            case 'Completed':
            case 'Cancelled':
                return setPendingChallenges([]);
        }
    };

    const progressFlow = async (flowId: string, challenge: AuthFlowChallenge | undefined) => {
        if (state !== 'authenticated') {
            return; // we can not do anything if the customer is not signed in
        }

        try {
            if (challenge === 'Email') {
                await triggerEmailVerification(flowId);
            } else if (challenge === 'Sms') {
                await triggerPhoneVerification(flowId, phone);
            } else if (challenge === 'Pin') {
                setStep({ type: 'verify-pin' });
            } else {
                const nextStep = handleChangeStep();

                if (nextStep instanceof Promise) {
                    const resultStep = await nextStep;

                    setStep(resultStep);
                } else {
                    setStep(nextStep);
                }
            }
        } catch (error) {
            setStep({ ...step, error: ensureResponseError(error) } as Step);
        }
    };

    const next = () => {
        if (pendingChallenges && pendingChallenges.length > 0) {
            const copy = pendingChallenges.slice();
            copy.splice(0, 1);

            setPendingChallenges(copy);
        }
    };

    return { step, setStep, ...flow, start, next, type };
};
