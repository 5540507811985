import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { EMAIL_REGEX, register } from '@zastrpay/common';
import { Alert, Button, InputField } from '@zastrpay/components';
import { Page, PageTitle } from '@zastrpay/layout';

import { Contact } from '../models';

export type EmailData = Pick<Contact, 'email'>;

export type EmailFormProps = {
    defaultValues?: Partial<EmailData>;
    error?: React.ReactElement;
    onComplete?: (details: EmailData) => void;
};

export const EmailForm: React.FC<EmailFormProps> = (props) => {
    const { t } = useTranslation('registration');
    const { control, handleSubmit } = useForm<EmailData>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: props.defaultValues,
    });

    useEffect(() => {
        trackPage('email_input');
    }, []);

    const submit = handleSubmit((data) => {
        trackClick('email_input', 'continue');

        props.onComplete?.(data);
    });

    return (
        <Page align="fill">
            <PageTitle title={t('contactDetails.title')} subTitle={t('contactDetails.subTitle')} />

            <InputField
                {...register(control, 'email', { required: true, pattern: EMAIL_REGEX })}
                size="large"
                label={t('contactDetails.email')}
                type="email"
                autoComplete="email"
                autoFocus
            />

            {props.error && <Alert type="error">{props.error}</Alert>}

            <Button appearance="primary" onClick={submit} size="large">
                {t('personalDetails.submit')}
            </Button>
        </Page>
    );
};
