import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { v4 as uuid } from 'uuid';

import {
    api,
    dateUtil,
    isAuthenticationError as isApiAuthenticationError,
    isAuthorizationError as isApiAuthorizationError,
    isResponseError,
    localStorage,
    sessionStorage,
} from '@zastrpay/common';
import { useErrorBoundary } from '@zastrpay/components';
import { Customer, get as getCustomer } from '@zastrpay/customers';

import {
    createEmailOtpAuthFactor,
    createPhoneOtpAuthFactor,
    changePin as doChangePin,
    createPin as doCreatePin,
    refreshToken as doRefreshToken,
    startCustomerSessionByEmail as doStartCustomerSessionByEmail,
    startCustomerSessionByPhone as doStartCustomerSessionByPhone,
    verifyPinByCustomerId as doVerifyPinByCustomerId,
    verifyPinByEmail as doVerifyPinByEmail,
    verifyPinById as doVerifyPinById,
    verifyPinByPhoneNumber as doVerifyPinByPhoneNumber,
    generateEmailOtp,
    generateOtp,
    generatePhoneOtp,
    getOtpAuthFactor,
    verifyOtp,
} from './api';
import {
    ALTERNATIVE_LOGIN_REGEX,
    MOCK_AUTHENTICATION_APP,
    MOCK_AUTHENTICATION_HEADERS,
    PREFERRED_AUTH_METHOD_DURATION_DAYS,
    RECAPTCHA_SITE_KEY,
} from './config';
import { AuthenticationError } from './errors';
import { CustomerSessionScope, OtpAuthFactorType } from './models';
import { AuthenticationMethod, parseToken, SessionScope, SessionType, TokenPayload } from './token';

const TOKEN_KEY = 'sessionToken';
const SESSION_KEY = 'sessionState';
const PHONE_STORAGE_KEY = 'lastPhone';
const AUTH_METHOD_KEY = 'preferredAuthMethod';

const OTP_VERIFICATION_KEY = 'otpVerification';

export type User = {
    id: string;
    phone: string;
};

export type Session = {
    id: string;
    type: SessionType;
    scope: SessionScope;
    expiration?: number;
};

export type AuthenticationStateInitializerResult = AuthenticationState & {
    token?: string;
};

export type AuthenticationStateInitializer = (
    storedState: AuthenticationState | undefined,
    storedToken: string | undefined,
    parser: (token: string) => AuthenticationState,
) => Promise<AuthenticationStateInitializerResult>;

export type AuthenticationState =
    | {
          state: 'loading' | 'error' | 'anonymous';
          session?: Session;
          sessionExpiration?: undefined;
          customerId?: string;
          phone?: string;
          verifyNext?: AuthenticationMethod[];
          registerNext?: AuthenticationMethod;
          provided?: AuthenticationMethod[];
      }
    | {
          state: 'expired';
          session?: Session;
          sessionExpiration?: undefined;
          failureUrl?: string;
          customerId?: string;
          phone?: string;
          verifyNext?: AuthenticationMethod[];
          registerNext?: AuthenticationMethod;
          provided?: AuthenticationMethod[];
      }
    | {
          state: 'authenticated';
          session: Session;
          customerId: string;
          phone: string;
          verifyNext?: AuthenticationMethod[];
          registerNext?: AuthenticationMethod;
          provided?: AuthenticationMethod[];
      };

type AuthenticationStateRef = Pick<AuthenticationState, 'customerId' | 'session'> & { expirationTimer?: ReturnType<typeof setTimeout> };

export type AuthenticationVerifyResult = {
    next?: AuthenticationMethod[];
    nextIncomplete?: AuthenticationMethod;
    pinId?: string;
};

export type AuthenticationContext = AuthenticationState & {
    startCustomerSession: (scope: CustomerSessionScope, type: OtpAuthFactorType, contact: string) => Promise<AuthenticationVerifyResult>;
    refreshToken: (phone: string) => Promise<AuthenticationVerifyResult>;

    lastAuthenticatedPhone?: string;

    currentOtpVerificationStep?: OtpVerificationStep;

    createOtpAuthFactor: (type: OtpAuthFactorType, contact: string, locale: string, flowId?: string) => Promise<void>;
    generateOtpCode: {
        (type: 'Phone', contact: string, locale: string, flowId?: string): Promise<string>;
        (type: 'Email', contact: string | undefined, locale: string, flowId?: string): Promise<string>;
    };
    verifyOtpCode: (type: OtpAuthFactorType, otp: string, flowId?: string) => Promise<AuthenticationVerifyResult>;

    createPin: (pin: string, flowId?: string) => Promise<string>;

    verifyPin: (pin: string, flowId?: string) => Promise<AuthenticationVerifyResult>;
    verifyPinById: (pinId: string, pin: string, flowId?: string) => Promise<AuthenticationVerifyResult>;
    verifyPinByOtpAuthFactor: (
        type: OtpAuthFactorType,
        contact: string,
        pin: string,
        flowId?: string,
    ) => Promise<AuthenticationVerifyResult>;

    changePin: (pin: string) => Promise<void>;

    setPreferredAuthMethod(method: AuthenticationMethod): void;

    logout: () => void;
    clear: () => void;

    customer?: Customer;
    refreshCustomer: () => Promise<Customer | undefined>;
};

const Context = createContext<AuthenticationContext | null>(null);

type AuthMethodPreference = {
    method: AuthenticationMethod;
    date: Date;
};

type PendingOtpVerification = {
    type: 'pending-verification';
    otpId: string;
    otpAuthFactor: {
        id?: string;
        type: OtpAuthFactorType;
        contact: string;
    };
    customerId?: string;
    sessionId: string;
};

type FailedOtpGeneration = {
    type: 'failed-generation';
    otpAuthFactor: {
        id?: string;
        type: OtpAuthFactorType;
        contact?: string;
    };
    sessionId: string;
};

type OtpVerificationStep = PendingOtpVerification | FailedOtpGeneration;

export type AuthenticationProviderConfig = {
    init?: AuthenticationStateInitializer;
    captcha?: boolean;
    lastUsedPhone?: boolean;
    alternativeLoginCustomerIdRegex?: string;
};

export type AuthenticationProviderProps = {
    config?: AuthenticationProviderConfig;
};

type InterceptorReference = { request?: number; response?: number };

export const AuthenticationProvider: React.FC<React.PropsWithChildren<AuthenticationProviderProps>> = (props) => {
    const [state, setState] = useState<AuthenticationState>({ state: 'loading' });
    const [config] = useState(props.config || {});
    const [customer, setCustomer] = useState<Customer | undefined>();
    const [, setInterceptors] = useState<InterceptorReference>({});

    const { trackError } = useErrorBoundary();

    const [otpVerificationStep, setOtpVerificationStep] = useState(sessionStorage.retrieve<OtpVerificationStep>(OTP_VERIFICATION_KEY));

    const [lastUsedPhone, setLastUsedPhone] = useState<string | undefined>();

    // this is needed for dev as the useEffect hooks run twice in dev mode
    const init = useRef(false);

    // todo: this is readonly purpose as when validating captcha and doing directly an operation that requires customerId fails
    const stateRef = useRef<AuthenticationStateRef>();

    const captcha = useRef<ReCAPTCHA>(null);
    const requireCaptcha = useMemo(
        // captcha is only required if we don't have a session yet
        () => !!config.captcha && state.state === 'anonymous',
        [config.captcha, state.state],
    );

    useEffect(() => {
        initState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state.state === 'authenticated' && !customer) {
            getCustomer(state.customerId)
                .then((customer) => {
                    setCustomer(customer);
                })
                .catch((error) => {
                    if (isResponseError(error, 'CustomerNotFound')) {
                        setCustomer(undefined);
                    }
                });
        } else if (state.state !== 'authenticated' && state.state !== 'loading') {
            setCustomer(undefined);
        }
    }, [state.state, state.customerId, customer]);

    const parseState = useCallback(
        (token: string | undefined, { phone }: Pick<AuthenticationState, 'phone'>): AuthenticationState => {
            const payload = token ? parseToken(token) : undefined;

            const registerNext = payload?.ami?.[0];
            const provided = payload?.acr ?? [];

            const preferredAuthMethod = localStorage.retrieve<AuthMethodPreference>(AUTH_METHOD_KEY);

            if (payload?.amr) {
                // by default the backend wants us to validate pin first, but for customers not in the testing subset
                // we want to validate the phone number first (as we did in the past), so reverse the provided options
                if (!ALTERNATIVE_LOGIN_REGEX.test(payload?.sub ?? '')) {
                    payload.amr = payload.amr.reverse();
                }

                if (preferredAuthMethod && dateUtil.addDays(preferredAuthMethod.date, PREFERRED_AUTH_METHOD_DURATION_DAYS) >= new Date()) {
                    payload.amr = payload.amr.sort((a, b) => (a[0] === preferredAuthMethod.method ? -1 : 1));
                } else {
                    localStorage.store(AUTH_METHOD_KEY, undefined);
                }
            }

            // get the next auth method for all options which is not yet provided
            const verifyNext = payload?.amr?.map((options) => options.filter((method) => !provided?.includes(method))?.[0]).filter(Boolean);

            if (payload) {
                phone ??= payload.tel;

                const now = new Date();
                const expiration = new Date(payload.exp * 1_000);
                const session = {
                    id: payload.sid,
                    type: payload.typ,
                    scope: payload.scope,
                    expiration: expiration.getTime(),
                };

                if (expiration < now) {
                    return { phone, verifyNext, registerNext, provided, state: 'expired' };
                } else if (payload.auth) {
                    if (!payload.sub || phone === undefined) {
                        const errorState: AuthenticationState = { phone, verifyNext, registerNext, provided, state: 'error' };

                        trackError(
                            new Error('Invalid authentication provider state, expected customerId and phone number to be provided', {
                                cause: {
                                    customerId: payload.sub,
                                    ...errorState,
                                    // mask phone number for data protection
                                    phone: phone?.replace(/./g, '*'),
                                },
                            }),
                        );

                        return errorState;
                    }

                    return {
                        phone,
                        verifyNext,
                        registerNext,
                        provided: provided,
                        state: 'authenticated',
                        customerId: payload.sub,
                        session,
                    };
                } else {
                    return {
                        phone,
                        verifyNext,
                        registerNext,
                        provided,
                        state: 'anonymous',
                        customerId: payload.sub,
                        session,
                    };
                }
            }

            return {
                phone,
                verifyNext,
                registerNext,
                provided,
                state: 'anonymous',
            };
        },
        [trackError],
    );

    const initState = async () => {
        if (init.current) {
            return;
        }

        init.current = true;

        // if it is an iframe and it is the first page opening we need to start with a fresh state
        // so we clear any token existing from any previous session
        if (window.parent !== window) {
            sessionStorage.store(TOKEN_KEY);
        }

        const persistedToken = sessionStorage.retrieve<string>(TOKEN_KEY);
        const persistedState = sessionStorage.retrieve<AuthenticationState>(SESSION_KEY) ?? parseState(persistedToken, {});

        updateInterceptor(persistedToken);

        if (config.lastUsedPhone) {
            setLastUsedPhone(localStorage.retrieve<string>(PHONE_STORAGE_KEY));
        }

        if (config.init) {
            try {
                const { token, ...initiatedState } = await config.init(persistedState, persistedToken, (value) => {
                    return parseState(value, {});
                });

                storeState(initiatedState);
                storeToken(token);

                const sessionId = initiatedState.session?.id;

                setOtpVerificationStep((current) => (current && current.sessionId === sessionId ? current : undefined));
            } catch (error) {
                trackError(error);

                storeState({ state: 'error' });
            }
        } else {
            storeState(persistedState);
        }
    };

    const updateInterceptor = (token?: string) => {
        api.apply((instance) => {
            const requestInterceptor = instance.interceptors.request.use((request) => {
                if (MOCK_AUTHENTICATION_HEADERS) {
                    request.headers.set('x-triggered-by-type', MOCK_AUTHENTICATION_APP);
                }

                if (token) {
                    if (!request.headers.has('authorization') && request.headerOptions?.addAuthentication !== false) {
                        request.headers.set('authorization', `Bearer ${token}`);
                    }

                    if (MOCK_AUTHENTICATION_HEADERS) {
                        const parts = token.split('.');
                        const parsed = parseToken<TokenPayload & { merchId?: string }>(token);

                        request.headers.set('x-triggered-by-customer-id', parsed?.sub);
                        request.headers.set('x-triggered-by-merchant-id', parsed?.merchId);
                        request.headers.set('x-session-authentication', parsed?.acr?.join(','));
                        request.headers.set('x-session-token-claims', parts[1]);
                        request.headers.set('x-session-id', parsed?.sid);
                    }
                }

                return request;
            });

            const responseInterceptor = instance.interceptors.response.use(undefined, (error) => {
                if (isApiAuthenticationError(error)) {
                    if (token) {
                        setState((state) => ({ ...state, state: 'expired' }));
                    } else {
                        setState((state) => ({ ...state, state: 'error' }));
                    }
                }

                if (isApiAuthorizationError(error)) {
                    setState((state) => ({ ...state, state: 'error' }));
                }

                return Promise.reject(error);
            });

            setInterceptors((interceptors) => {
                if (interceptors.request !== undefined) {
                    instance.interceptors.request.eject(interceptors.request);
                }

                if (interceptors.response !== undefined) {
                    instance.interceptors.response.eject(interceptors.response);
                }

                return { request: requestInterceptor, response: responseInterceptor };
            });
        });
    };

    const storeToken = (token?: string) => {
        updateInterceptor(token);
        sessionStorage.store(TOKEN_KEY, token);
    };

    const storeState = (state: AuthenticationState) => {
        clearTimeout(stateRef.current?.expirationTimer);

        stateRef.current = {
            customerId: state.customerId,
            session: state.session,
        };

        if (state.session?.expiration && state.state === 'authenticated') {
            const expirationTime = state.session.expiration - Date.now();

            stateRef.current.expirationTimer = setTimeout(() => {
                setState((state) => ({ ...state, state: 'expired' }));
            }, expirationTime);
        }

        setState(state);
        sessionStorage.store(SESSION_KEY, state);
    };

    const updateState = (token?: string, phone?: string): AuthenticationState => {
        const parsed = parseState(token, { phone: phone ?? state.phone });

        storeState(parsed);
        storeToken(token);

        return parsed;
    };

    const startCustomerSession = async (scope: CustomerSessionScope, type: OtpAuthFactorType, contact: string) => {
        let captchaToken;

        try {
            captcha.current?.reset();
            captchaToken = await captcha.current?.executeAsync();
        } catch (error) {
            throw new AuthenticationError('startCustomerSession: error during captcha execution ' + error, 'NoCaptchaToken');
        }

        if (!captchaToken) {
            throw new AuthenticationError('startCustomerSession: missing captcha token', 'NoCaptchaToken');
        }

        const { token } =
            type === 'Phone'
                ? await doStartCustomerSessionByPhone(contact, scope, captchaToken)
                : await doStartCustomerSessionByEmail(contact, scope, captchaToken);

        const { verifyNext, registerNext } = updateState(token, type === 'Phone' ? contact : state.phone);
        return { next: verifyNext, nextIncomplete: registerNext };
    };

    const refreshToken = async (phone: string) => {
        if (!stateRef.current?.session?.id) {
            throw new AuthenticationError(`refreshToken: No session id provided`, 'NoSessionId');
        }

        // during a redirect session for a payment, if the customer id was not specified we refresh the token once we have the phone number
        // so we can challenge the proper authentication method
        if (stateRef.current.session?.type !== 'Redirect' && stateRef.current.session?.scope !== 'NewTransactionIntent') {
            throw new AuthenticationError(
                `refreshToken: ${stateRef.current.session?.type} session with scope ${stateRef.current.session?.scope} can't be used`,
                'InvalidSession',
            );
        }

        const { token } = await doRefreshToken(stateRef.current.session.id, phone);
        const { verifyNext, registerNext } = updateState(token);

        return { next: verifyNext, nextIncomplete: registerNext };
    };

    const storeOtpVerificationStep = (verificationStep?: OtpVerificationStep) => {
        setOtpVerificationStep(verificationStep);
        sessionStorage.store(OTP_VERIFICATION_KEY, verificationStep);
    };

    const createOtpAuthFactor = async (type: OtpAuthFactorType, contact: string, locale: string, flowId?: string) => {
        if (!stateRef.current?.customerId) {
            throw new AuthenticationError(`createOtpAuthFactor: No customer id provided to create ${type}`, 'NoCustomerId');
        } else if (!stateRef.current?.session?.id) {
            throw new AuthenticationError(`createOtpAuthFactor: No session id provided to create ${type}`, 'NoSessionId');
        }

        try {
            const otpAuthFactor =
                type === 'Phone' ? await createPhoneOtpAuthFactor(contact, flowId) : await createEmailOtpAuthFactor(contact, flowId);
            const { id } = await generateOtp(otpAuthFactor.id, locale, flowId);

            storeOtpVerificationStep({
                type: 'pending-verification',
                otpId: id,
                otpAuthFactor: {
                    id: otpAuthFactor.id,
                    type,
                    contact,
                },
                customerId: stateRef.current?.customerId,
                sessionId: stateRef.current.session.id,
            });
        } catch (error) {
            storeOtpVerificationStep({
                type: 'failed-generation',
                otpAuthFactor: {
                    type,
                    contact,
                },
                sessionId: stateRef.current.session.id,
            });
            throw error;
        }
    };

    const generateOtpCode = async (
        type: OtpAuthFactorType,
        contact: string | undefined,
        locale: string,
        flowId?: string,
    ): Promise<string> => {
        if (!stateRef.current?.session?.id) {
            throw new AuthenticationError(`generateOtpCode: No session id provided for ${type} otp`, 'NoSessionId');
        }

        try {
            const storedOtpAuthFactorId =
                otpVerificationStep?.otpAuthFactor.type === type && (!contact || otpVerificationStep.otpAuthFactor.contact === contact)
                    ? otpVerificationStep.otpAuthFactor.id
                    : undefined;

            const { id: otpId, otpAuthFactorId } = storedOtpAuthFactorId
                ? await generateOtp(storedOtpAuthFactorId, locale, flowId)
                : type === 'Phone' && contact // type narrowing doesn't pick this up correctly, so we also check for contact
                  ? await generatePhoneOtp(contact, locale, flowId)
                  : await generateEmailOtp(contact, locale, flowId);

            const { contact: displayContact } = contact ? { contact } : await getOtpAuthFactor(otpAuthFactorId);

            storeOtpVerificationStep({
                type: 'pending-verification',
                otpId,
                otpAuthFactor: {
                    id: otpAuthFactorId,
                    type: type,
                    contact: displayContact,
                },
                customerId: stateRef.current.customerId,
                sessionId: stateRef.current.session.id,
            });

            return displayContact;
        } catch (error) {
            storeOtpVerificationStep({
                type: 'failed-generation',
                otpAuthFactor: {
                    type,
                    contact,
                },
                sessionId: stateRef.current.session.id,
            });
            throw error;
        }
    };

    const verifyOtpCode = async (type: OtpAuthFactorType, otp: string, flowId?: string) => {
        if (otpVerificationStep?.type !== 'pending-verification' || otpVerificationStep?.otpAuthFactor.type !== type) {
            throw new AuthenticationError(`verifyOtpCode: No ${type} verification to complete`, 'NoVerificationToComplete');
        }

        const { token } = await verifyOtp(otpVerificationStep.otpId, otp, flowId);
        const { verifyNext, registerNext } = updateState(token, type === 'Phone' ? otpVerificationStep.otpAuthFactor.contact : state.phone);

        storeOtpVerificationStep();

        if (config.lastUsedPhone && otpVerificationStep.otpAuthFactor.type === 'Phone') {
            setLastUsedPhone(otpVerificationStep.otpAuthFactor.contact);
            localStorage.store(PHONE_STORAGE_KEY, otpVerificationStep.otpAuthFactor.contact);
        }

        return { next: verifyNext, nextIncomplete: registerNext };
    };

    const createPin = async (pin: string, flowId?: string) => {
        if (!stateRef.current?.customerId) {
            throw new AuthenticationError('createPin: Pin can not be created without customerId', 'NoCustomerId');
        }

        const id = uuid();
        await doCreatePin(id, pin, flowId);
        return id;
    };

    const changePin = async (pin: string) => {
        if (!stateRef.current?.customerId) {
            throw new AuthenticationError('changePin: Pin can not be changed if user did not login', 'NoCustomerId');
        }

        await doChangePin(pin);
    };

    const verifyPinById = async (pinId: string, pin: string, flowId?: string) => {
        const { token } = await doVerifyPinById(pin, pinId, flowId);
        const { verifyNext, registerNext } = updateState(token);

        return { next: verifyNext, nextIncomplete: registerNext };
    };

    const verifyPinByOtpAuthFactor = async (type: OtpAuthFactorType, contact: string, pin: string, flowId?: string) => {
        const { token } =
            type === 'Phone' ? await doVerifyPinByPhoneNumber(contact, pin, flowId) : await doVerifyPinByEmail(contact, pin, flowId);
        const { verifyNext, registerNext } = type === 'Phone' ? updateState(token, contact) : updateState(token);

        if (type === 'Phone' && config.lastUsedPhone) {
            setLastUsedPhone(contact);
            localStorage.store(PHONE_STORAGE_KEY, contact);
        }

        return { next: verifyNext, nextIncomplete: registerNext };
    };

    const verifyPin = async (pin: string, flowId?: string) => {
        if (!stateRef.current?.customerId) {
            throw new AuthenticationError('verifyPinByCustomerId: Pin can not be verified without customerId', 'NoCustomerId');
        }

        const { token } = await doVerifyPinByCustomerId(pin, flowId);
        // HACK: during phone reset flow after user has provided both an email and a pin and is already authenticated
        // we need to set temporary authenticated state with an empty phone number (any value will do)
        // and at the next step (reset) user will either provide a new phone number or email or abandon the session
        const emptyPhone = '';
        const { verifyNext, registerNext } = updateState(token, state.phone ?? emptyPhone);

        return { next: verifyNext, nextIncomplete: registerNext };
    };

    const setPreferredAuthMethod = (method: AuthenticationMethod) => {
        localStorage.store(AUTH_METHOD_KEY, { method, date: new Date() });
    };

    const logout = () => {
        return updateState();
    };

    const clear = () => {
        sessionStorage.store(SESSION_KEY);
        sessionStorage.store(TOKEN_KEY);

        sessionStorage.store(OTP_VERIFICATION_KEY);
    };

    const refreshCustomer = async () => {
        if (state.state === 'authenticated' && state.customerId) {
            const customer = await getCustomer(state.customerId);
            setCustomer(customer);
            return customer;
        }

        return undefined;
    };

    return (
        <Context.Provider
            value={{
                ...state,
                lastAuthenticatedPhone: state.phone ?? lastUsedPhone,
                refreshToken,
                currentOtpVerificationStep: otpVerificationStep,
                createOtpAuthFactor,
                generateOtpCode,
                verifyOtpCode,
                createPin,
                changePin,
                verifyPinByOtpAuthFactor,
                verifyPinById,
                verifyPin,
                startCustomerSession,
                setPreferredAuthMethod,
                logout,
                clear,
                customer,
                refreshCustomer,
            }}
        >
            {props.children}
            {requireCaptcha && RECAPTCHA_SITE_KEY && <ReCAPTCHA ref={captcha} size="invisible" sitekey={RECAPTCHA_SITE_KEY} />}
        </Context.Provider>
    );
};

//A simple hooks to facilitate the access to the AuthContext
// and permit components to subscribe to AuthContext updates
export const useAuth = (): AuthenticationContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useAuth must be used within an AuthenticationProvider');
    }

    return context;
};
