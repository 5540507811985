import { Field, FieldProps, InputProps, makeStyles } from '@fluentui/react-components';
import React, { useMemo, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControllerFieldProps, validate } from '@zastrpay/common';

import { DigitInput } from './DigitInput';

export type SplitDateInputFieldProps = InputProps &
    Pick<FieldProps, 'hint' | 'label'> & {
        autoComplete?: 'bday';
    };

export const SplitDateInputField: React.FC<SplitDateInputFieldProps & ControllerFieldProps> = ({
    rules,
    className,
    label,
    hint,
    autoComplete,
    ...props
}) => {
    const classes = useStyles();
    const { t } = useTranslation('components');
    const fieldConfig = useMemo(
        () => [
            {
                length: 2,
                autoComplete: autoComplete === 'bday' ? 'bday-day' : 'off',
                label: t('splitDateInputField.dayPlaceholder'),
            },
            {
                length: 2,
                autoComplete: autoComplete === 'bday' ? 'bday-month' : 'off',
                label: t('splitDateInputField.monthPlaceholder'),
            },
            {
                length: 4,
                autoComplete: autoComplete === 'bday' ? 'bday-year' : 'off',
                label: t('splitDateInputField.yearPlaceholder'),
            },
        ],
        [autoComplete, t],
    );
    const [values, setValues] = useState(['', '', '']);
    const [activeInput, setActiveInput] = useState(props.autoFocus ? 0 : -1);

    const focusInput = (inputIndex: number) => setActiveInput(inputIndex);

    const focusPrevInput = () => focusInput(activeInput - 1);

    const focusNextInput = () => focusInput(activeInput + 1);

    const removeFocus = () => setActiveInput(-1);

    const handleOnFocus = (index: number) => () => focusInput(index);

    const handleOnBlur = (field: ControllerRenderProps) => {
        removeFocus();
        field.onBlur();
    };

    const updateValues = (field: ControllerRenderProps, val: string[]) => {
        setValues(val);

        const [day, month, year] = val;
        field.onChange?.(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);

        const isComplete = activeInput === 2 && val[2].length === 4;
        if (isComplete) {
            removeFocus();
        }
    };

    const changeValueAtFocus = (field: ControllerRenderProps, str: string) => {
        const updatedValues = [...values];
        updatedValues[activeInput] = str ?? '';
        updateValues(field, updatedValues);
    };

    const handleOnInput = (field: ControllerRenderProps, e: React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        e.preventDefault();

        if (!val?.match(/^[0-9]+$/)) {
            return;
        }

        if (val.length <= fieldConfig[activeInput].length) {
            changeValueAtFocus(field, val);
        }

        if (val.length === fieldConfig[activeInput].length) {
            focusNextInput();
        }
    };

    const handleOnKeyDown = (field: ControllerRenderProps, e: React.KeyboardEvent<HTMLInputElement>) => {
        const { value, selectionStart } = e.currentTarget;
        switch (e.key) {
            case 'Unidentified':
                break;
            case 'Backspace':
            case 'Delete': {
                const fullContentDeleted = value.length === 1;
                if (fullContentDeleted) {
                    changeValueAtFocus(field, '');
                    focusPrevInput();
                }
                break;
            }
            case 'ArrowLeft': {
                const shouldFocusPreviousInput = selectionStart === 0;
                if (shouldFocusPreviousInput) {
                    e.preventDefault();
                    focusPrevInput();
                }
                break;
            }
            case 'ArrowRight': {
                const shouldFocusNextInput = selectionStart === value.length;
                if (shouldFocusNextInput) {
                    e.preventDefault();
                    focusNextInput();
                }
                break;
            }
            default: {
                // allow shortcuts like ctrl+v, cmd+c, etc
                if (e.metaKey || e.ctrlKey) {
                    break;
                }

                const isDigit = e.key.match(/^[0-9]$/);
                const shouldResetField = value.length === fieldConfig[activeInput].length;
                if (isDigit && shouldResetField) {
                    changeValueAtFocus(field, '');
                }
            }
        }
    };

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={rules}
            render={({ field, fieldState }) => {
                const validationResult = validate(fieldState);
                const hasError = validationResult.validationState === 'error' && activeInput === -1;
                const validationState = hasError ? 'error' : 'none';
                const errorMessage = hasError ? validationResult.validationMessage : null;

                return (
                    <Field label={label} hint={hint} validationMessage={errorMessage} className={className}>
                        <div className={classes.inputGroup}>
                            {fieldConfig.map((config, index) => (
                                <Field validationState={validationState} key={config.label}>
                                    <DigitInput
                                        autoComplete={config.autoComplete}
                                        key={index}
                                        maxLength={config.length}
                                        input={{
                                            className: classes.digitInput,
                                        }}
                                        placeholder={config.label}
                                        focus={activeInput === index}
                                        value={values && values[index]}
                                        onFocus={handleOnFocus(index)}
                                        onInput={(e) => handleOnInput(field, e)}
                                        onKeyDown={(e) => handleOnKeyDown(field, e)}
                                        onBlur={() => handleOnBlur(field)}
                                        disabled={props.disabled}
                                    />
                                </Field>
                            ))}
                        </div>
                    </Field>
                );
            }}
        />
    );
};

const useStyles = makeStyles({
    inputGroup: {
        display: 'flex',
        gap: '8px',
        width: '100%',
        justifyItems: 'start',
        position: 'relative',
    },
    digitInput: {
        width: '100%',
    },
});
