import { useEffect, useState } from 'react';

export type NetworkState = 'online' | 'offline';

export const useNetworkState = () => {
    const [state, setState] = useState<NetworkState>(navigator.onLine ? 'online' : 'offline');

    useEffect(() => {
        const setOnline = () => setState('online');
        const setOffline = () => setState('offline');

        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return state;
};
