import { makeStyles } from '@fluentui/react-components';
import { PhoneChatRegular } from '@fluentui/react-icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackClick, trackInput, trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, ResponseError } from '@zastrpay/common';
import { Body, BodyStrong, ErrorTrans, Title } from '@zastrpay/components';
import { useRequestLoader } from '@zastrpay/hooks';
import { Page } from '@zastrpay/layout';
import { OtpInput, OtpInputElement } from '@zastrpay/pages';
import { tokens } from '@zastrpay/theme';

export type PhoneOtpInputProps = {
    phone: string;
    error?: React.ReactElement;
    onVerify?: () => void;
    onSkip?: () => void;
};

export const PhoneOtpInput: React.FC<PhoneOtpInputProps> = (props) => {
    const loading = useRequestLoader();
    const classes = useStyles();

    const { t, i18n } = useTranslation('auth-flow');
    const { verifyOtpCode, generateOtpCode } = useAuth();
    const otpRef = useRef<OtpInputElement>(null);

    const [error, setError] = useState<ResponseError>();

    useEffect(() => {
        trackPage('phone_verify');
    }, []);

    const verify = async (otp?: string) => {
        if (otp) {
            trackInput('phone_verify', 'enter_phone_otp');

            try {
                await verifyOtpCode('Phone', otp);

                props.onVerify?.();
            } catch (error) {
                otpRef.current?.reset();
                setError(ensureResponseError(error));
            }
        }
    };

    const resend = async () => {
        trackClick('phone_verify', 'resend_sms_otp');

        try {
            await generateOtpCode('Phone', props.phone, i18n.language);
        } catch (error) {
            setError(ensureResponseError(error));
        }
    };

    return (
        <Page>
            <PhoneChatRegular className={classes.icon} />
            <Title>{t('verifyEmail.phoneOtp.title')}</Title>
            <Body>{t('verifyEmail.phoneOtp.subTitle')}</Body>

            <BodyStrong>{props.phone}</BodyStrong>

            <OtpInput
                ref={otpRef}
                error={error ? <ErrorTrans t={t} error={error} i18nKey="verifyEmail.phoneOtp.error.response" /> : props.error}
                disabled={loading}
                onInput={verify}
                onResend={resend}
            />
        </Page>
    );
};

const useStyles = makeStyles({
    icon: {
        height: tokens.iconSizeTitle,
        width: tokens.iconSizeTitle,
    },
});
