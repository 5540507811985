import { makeStyles } from '@fluentui/react-components';
import { DialpadFilled } from '@fluentui/react-icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackInput, trackPage } from '@zastrpay/analytics';
import { AuthenticationMethod } from '@zastrpay/auth';
import { Alert, Body, Button, Dialog, Divider, Link, Pin, PinElement, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

type AuthOption = { title: string; value: AuthenticationMethod };

export type PinInputProps = {
    title: string;
    subTitle: string;
    resetPin: string;
    error?: React.ReactElement;
    disabled?: boolean;
    alternativeAuthOptions?: AuthOption[];
    onPinEnter?: (pin: string) => void;
    onResetPin?: () => void;
    onAlternativeAuth?: (value: AuthenticationMethod) => void;
};

export const PinInput: React.FC<PinInputProps> = ({
    title,
    subTitle,
    resetPin,
    alternativeAuthOptions,
    disabled,
    error,
    onPinEnter,
    onResetPin,
    onAlternativeAuth,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('auth-flow');

    const [errorCount, setErrorCount] = useState(0);
    const [pinResetOpen, setPinResetOpen] = useState(false);
    const [pinProblemOpen, setPinProblemOpen] = useState(false);

    const pinRef = useRef<PinElement>(null);

    useEffect(() => {
        trackPage('pin_input');
    }, []);

    useEffect(() => {
        if (error) {
            setErrorCount((count) => count + 1);
            pinRef.current?.reset();
        }
    }, [error]);

    useEffect(() => {
        if (errorCount === 1) {
            setPinProblemOpen(true);
        }
    }, [errorCount]);

    const pinEntered = (pin?: string) => {
        if (!pin) {
            return;
        }

        onPinEnter?.(pin);
        trackInput('pin_input', 'enter_pin');
    };

    const handleResetPin = () => {
        if (alternativeAuthOptions?.length) {
            setPinResetOpen(true);
        } else {
            onResetPin?.();
        }
    };

    return (
        <Page>
            <DialpadFilled className={classes.icon} />
            <Title>{title}</Title>
            <Body>{subTitle}</Body>
            <Pin ref={pinRef} length={4} onInput={pinEntered} autoFocus disabled={disabled} />
            {error && <Alert type="error">{error}</Alert>}
            {onResetPin && (
                <Link className={classes.link} onClick={handleResetPin}>
                    {resetPin}
                </Link>
            )}
            {!!alternativeAuthOptions?.length && onAlternativeAuth && (
                <>
                    <Divider>{t('pinInput.or')}</Divider>
                    {alternativeAuthOptions.map((option) => (
                        <Button key={option.value} appearance="outline" size="large" onClick={() => onAlternativeAuth?.(option.value)}>
                            {option.title}
                        </Button>
                    ))}
                    <Dialog
                        open={pinResetOpen}
                        title={t('pinInput.reset.title')}
                        align="bottom"
                        onOpenChange={setPinResetOpen}
                        actions={[
                            {
                                text: t('pinInput.reset.confirm'),
                                onClick: onResetPin,
                            },
                            {
                                text: alternativeAuthOptions[0].title,
                                style: 'preferred',
                                onClick: () => onAlternativeAuth?.(alternativeAuthOptions[0].value),
                            },
                        ]}
                    >
                        <Body block>{t('pinInput.reset.message')}</Body>
                    </Dialog>
                    <Dialog
                        open={pinProblemOpen}
                        onOpenChange={setPinProblemOpen}
                        title={t('pinInput.problem.title')}
                        align="bottom"
                        actions={[
                            {
                                text: alternativeAuthOptions[0].title,

                                onClick: () => onAlternativeAuth?.(alternativeAuthOptions[0].value),
                            },
                            {
                                text: t('pinInput.problem.confirm'),
                                style: 'preferred',
                                onClick: () => setPinProblemOpen(false),
                            },
                        ]}
                    >
                        <Body block>{t('pinInput.problem.message')}</Body>
                    </Dialog>
                </>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
    icon: {
        height: tokens.iconSizeTitle,
        width: tokens.iconSizeTitle,
    },
});
