import { InputProps, makeStyles } from '@fluentui/react-components';
import React, { useLayoutEffect, useRef } from 'react';

import { Input } from './Input';

export type DigitInputProps = InputProps & {
    focus?: boolean;
};

export const DigitInput: React.FC<DigitInputProps> = ({ focus, ...props }) => {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null);

    useLayoutEffect(() => {
        if (inputRef.current) {
            if (focus) {
                inputRef.current.focus();
                inputRef.current.select();
            } else {
                inputRef.current.blur();
            }
        }
    }, [focus]);

    return (
        <Input
            ref={inputRef}
            input={{ className: classes.input }}
            className={classes.inputWrapper}
            inputMode="numeric"
            maxLength={1}
            size="large"
            type="text"
            {...props}
        />
    );
};

const useStyles = makeStyles({
    inputWrapper: {
        padding: '0',
    },
    input: {
        width: '100%',
        textAlign: 'center',
        padding: '0',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: '0',
        },
        '&[type=number]': {
            MozAppearance: 'textfield',
        },
    },
});
