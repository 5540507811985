import { useNavigate } from 'react-router-dom';

import { BackButton, useHeaderSlot } from '@zastrpay/layout';
import { DeprecatedOtpInput, DeprecatedOtpInputProps } from '@zastrpay/pages';

export const LoginOtpInput: React.FC<DeprecatedOtpInputProps> = (props) => {
    const navigate = useNavigate();

    useHeaderSlot('left', <BackButton onClick={() => navigate('/')} />);

    return <DeprecatedOtpInput {...props} />;
};
