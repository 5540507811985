import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { UIEvent, useEffect, useRef, useState } from 'react';

import { ProgressBar } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export type SpinnerOverlayProps = { loading?: boolean };

export const SpinnerOverlay: React.FC<SpinnerOverlayProps> = ({ loading }) => {
    const classes = useStyles();
    const handle = useRef<ReturnType<typeof setTimeout>>();

    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        if (!loading && handle.current) {
            clearTimeout(handle.current);
            setShowProgress(false);

            handle.current = undefined;
        } else if (loading && !handle.current) {
            handle.current = setTimeout(() => setShowProgress(true), 200);
        }

        return () => {
            if (handle.current) {
                clearTimeout(handle.current);
            }
        };
    }, [loading]);

    const preventEvent = (event: UIEvent) => {
        event.stopPropagation();
        event.preventDefault();
    };

    if (!loading) {
        return null;
    }

    return (
        <div
            className={mergeClasses(classes.root, showProgress && classes.progressBackground)}
            onClick={preventEvent}
            onTouchStart={preventEvent}
        >
            {showProgress && <ProgressBar shape="square" />}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        top: tokens.customHeaderHeight,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        backgroundColor: 'transparent',
        // this disables clicks to the loader which actually let's you click through
        // pointerEvents: 'none',
        userSelect: 'none',
        zIndex: Number.MAX_SAFE_INTEGER, // needs to be above everything
    },
    progressBackground: {
        backgroundColor: 'rgba(255,255,255,0.45)',
    },
});
