const COUNTRY_LIST = {
    AF: { callingCode: '+93' },
    AL: { callingCode: '+355' },
    DZ: { callingCode: '+213' },
    AS: { callingCode: '+1684' },
    AD: { callingCode: '+376' },
    AO: { callingCode: '+244' },
    AI: { callingCode: '+1264' },
    AG: { callingCode: '+1268' },
    AR: { callingCode: '+54' },
    AM: { callingCode: '+374' },
    AW: { callingCode: '+297' },
    AC: { callingCode: '+247' },
    AU: { callingCode: '+61' },
    AT: { callingCode: '+43' },
    AZ: { callingCode: '+994' },
    BS: { callingCode: '+1242' },
    BH: { callingCode: '+973' },
    BD: { callingCode: '+880' },
    BB: { callingCode: '+1246' },
    BY: { callingCode: '+375' },
    BE: { callingCode: '+32' },
    BZ: { callingCode: '+501' },
    BJ: { callingCode: '+229' },
    BM: { callingCode: '+1441' },
    BT: { callingCode: '+975' },
    BO: { callingCode: '+591' },
    BQ: { callingCode: '+599' },
    BA: { callingCode: '+387' },
    BW: { callingCode: '+267' },
    BR: { callingCode: '+55' },
    BN: { callingCode: '+673' },
    BG: { callingCode: '+359' },
    BF: { callingCode: '+226' },
    BI: { callingCode: '+257' },
    KH: { callingCode: '+855' },
    CM: { callingCode: '+237' },
    CA: { callingCode: '+1' },
    CV: { callingCode: '+238' },
    KY: { callingCode: '+1345' },
    CF: { callingCode: '+236' },
    TD: { callingCode: '+235' },
    CL: { callingCode: '+56' },
    CN: { callingCode: '+86' },
    CO: { callingCode: '+57' },
    KM: { callingCode: '+269' },
    CG: { callingCode: '+242' },
    CD: { callingCode: '+243' },
    CK: { callingCode: '+682' },
    CR: { callingCode: '+506' },
    CI: { callingCode: '+225' },
    HR: { callingCode: '+385' },
    CU: { callingCode: '+53' },
    CW: { callingCode: '+599' },
    CY: { callingCode: '+357' },
    CZ: { callingCode: '+420' },
    DK: { callingCode: '+45' },
    IO: { callingCode: '+246' },
    DJ: { callingCode: '+253' },
    DM: { callingCode: '+1767' },
    DO: { callingCode: '+1' },
    EC: { callingCode: '+593' },
    EG: { callingCode: '+20' },
    SV: { callingCode: '+503' },
    GQ: { callingCode: '+240' },
    ER: { callingCode: '+291' },
    EE: { callingCode: '+372' },
    ET: { callingCode: '+251' },
    FK: { callingCode: '+500' },
    FO: { callingCode: '+298' },
    FJ: { callingCode: '+679' },
    FI: { callingCode: '+358' },
    FR: { callingCode: '+33' },
    GF: { callingCode: '+594' },
    PF: { callingCode: '+689' },
    GA: { callingCode: '+241' },
    GM: { callingCode: '+220' },
    GE: { callingCode: '+995' },
    DE: { callingCode: '+49' },
    GH: { callingCode: '+233' },
    GI: { callingCode: '+350' },
    GR: { callingCode: '+30' },
    GL: { callingCode: '+299' },
    GD: { callingCode: '+1473' },
    GP: { callingCode: '+590' },
    GU: { callingCode: '+1671' },
    GT: { callingCode: '+502' },
    GN: { callingCode: '+224' },
    GW: { callingCode: '+245' },
    GY: { callingCode: '+592' },
    HT: { callingCode: '+509' },
    HN: { callingCode: '+504' },
    HK: { callingCode: '+852' },
    HU: { callingCode: '+36' },
    IS: { callingCode: '+354' },
    IN: { callingCode: '+91' },
    ID: { callingCode: '+62' },
    IR: { callingCode: '+98' },
    IQ: { callingCode: '+964' },
    IE: { callingCode: '+353' },
    IL: { callingCode: '+972' },
    IT: { callingCode: '+39' },
    JM: { callingCode: '+1' },
    JP: { callingCode: '+81' },
    JO: { callingCode: '+962' },
    KZ: { callingCode: '+7' },
    KE: { callingCode: '+254' },
    KI: { callingCode: '+686' },
    XK: { callingCode: '+383' },
    KW: { callingCode: '+965' },
    KG: { callingCode: '+996' },
    LA: { callingCode: '+856' },
    LV: { callingCode: '+371' },
    LB: { callingCode: '+961' },
    LS: { callingCode: '+266' },
    LR: { callingCode: '+231' },
    LY: { callingCode: '+218' },
    LI: { callingCode: '+423' },
    LT: { callingCode: '+370' },
    LU: { callingCode: '+352' },
    MO: { callingCode: '+853' },
    MK: { callingCode: '+389' },
    MG: { callingCode: '+261' },
    MW: { callingCode: '+265' },
    MY: { callingCode: '+60' },
    MV: { callingCode: '+960' },
    ML: { callingCode: '+223' },
    MT: { callingCode: '+356' },
    MH: { callingCode: '+692' },
    MQ: { callingCode: '+596' },
    MR: { callingCode: '+222' },
    MU: { callingCode: '+230' },
    MX: { callingCode: '+52' },
    FM: { callingCode: '+691' },
    MD: { callingCode: '+373' },
    MC: { callingCode: '+377' },
    MN: { callingCode: '+976' },
    ME: { callingCode: '+382' },
    MS: { callingCode: '+1664' },
    MA: { callingCode: '+212' },
    MZ: { callingCode: '+258' },
    MM: { callingCode: '+95' },
    NA: { callingCode: '+264' },
    NR: { callingCode: '+674' },
    NP: { callingCode: '+977' },
    NL: { callingCode: '+31' },
    NC: { callingCode: '+687' },
    NZ: { callingCode: '+64' },
    NI: { callingCode: '+505' },
    NE: { callingCode: '+227' },
    NG: { callingCode: '+234' },
    NU: { callingCode: '+683' },
    NF: { callingCode: '+672' },
    KP: { callingCode: '+850' },
    MP: { callingCode: '+1' },
    NO: { callingCode: '+47' },
    OM: { callingCode: '+968' },
    PK: { callingCode: '+92' },
    PW: { callingCode: '+680' },
    PS: { callingCode: '+970' },
    PA: { callingCode: '+507' },
    PG: { callingCode: '+675' },
    PY: { callingCode: '+595' },
    PE: { callingCode: '+51' },
    PH: { callingCode: '+63' },
    PL: { callingCode: '+48' },
    PT: { callingCode: '+351' },
    PR: { callingCode: '+1' },
    QA: { callingCode: '+974' },
    RE: { callingCode: '+262' },
    RO: { callingCode: '+40' },
    RU: { callingCode: '+7' },
    RW: { callingCode: '+250' },
    BL: { callingCode: '+590' },
    SH: { callingCode: '+290' },
    KN: { callingCode: '+1869' },
    LC: { callingCode: '+1758' },
    MF: { callingCode: '+590' },
    PM: { callingCode: '+508' },
    VC: { callingCode: '+1784' },
    WS: { callingCode: '+685' },
    SM: { callingCode: '+378' },
    ST: { callingCode: '+239' },
    SA: { callingCode: '+966' },
    SN: { callingCode: '+221' },
    RS: { callingCode: '+381' },
    SC: { callingCode: '+248' },
    SL: { callingCode: '+232' },
    SG: { callingCode: '+65' },
    SX: { callingCode: '+1721' },
    SK: { callingCode: '+421' },
    SI: { callingCode: '+386' },
    SB: { callingCode: '+677' },
    SO: { callingCode: '+252' },
    ZA: { callingCode: '+27' },
    KR: { callingCode: '+82' },
    SS: { callingCode: '+211' },
    ES: { callingCode: '+34' },
    LK: { callingCode: '+94' },
    SD: { callingCode: '+249' },
    SR: { callingCode: '+597' },
    SZ: { callingCode: '+268' },
    SE: { callingCode: '+46' },
    CH: { callingCode: '+41' },
    SY: { callingCode: '+963' },
    TW: { callingCode: '+886' },
    TJ: { callingCode: '+992' },
    TZ: { callingCode: '+255' },
    TH: { callingCode: '+66' },
    TL: { callingCode: '+670' },
    TG: { callingCode: '+228' },
    TK: { callingCode: '+690' },
    TO: { callingCode: '+676' },
    TT: { callingCode: '+1868' },
    TN: { callingCode: '+216' },
    TR: { callingCode: '+90' },
    TM: { callingCode: '+993' },
    TC: { callingCode: '+1649' },
    TV: { callingCode: '+688' },
    UG: { callingCode: '+256' },
    UA: { callingCode: '+380' },
    AE: { callingCode: '+971' },
    GB: { callingCode: '+44' },
    US: { callingCode: '+1' },
    UY: { callingCode: '+598' },
    UZ: { callingCode: '+998' },
    VU: { callingCode: '+678' },
    VA: { callingCode: '+379' },
    VE: { callingCode: '+58' },
    VN: { callingCode: '+84' },
    VG: { callingCode: '+1284' },
    VI: { callingCode: '+1340' },
    WF: { callingCode: '+681' },
    YE: { callingCode: '+967' },
    ZM: { callingCode: '+260' },
    ZW: { callingCode: '+263' },
};

export type CountryCode = keyof typeof COUNTRY_LIST;
// this wrapper is not necessarily needed but provides a nicer type for the outside
export const COUNTRIES: Record<CountryCode, { callingCode: string }> = COUNTRY_LIST;
