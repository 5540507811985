import { ClockRegular, ErrorCircleRegular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { useApp } from '../AppProvider';
import { CancelSessionButton } from './CancelSessionButton';

export type CancelSessionProps = {
    dialog: 'phone-otp' | 'registration' | 'registration-finalization' | 'default';
};

export type CancelSessionDialogType = CancelSessionProps['dialog'];

export const CancelSession: React.FC<CancelSessionProps> = ({ dialog = 'default' }) => {
    const { merchant, codeGenerated } = useApp();
    const { t } = useTranslation('payment');

    const merchantName = merchant?.displayName ?? merchant?.name;

    switch (dialog) {
        case 'phone-otp':
            return (
                <CancelSessionButton
                    icon={<ClockRegular />}
                    title={t('cancelSession.otp.title')}
                    confirm={t('cancelSession.otp.confirm', merchantName ? { merchant: merchantName } : { context: 'GenericMerchant' })}
                    close={t('cancelSession.otp.decline')}
                />
            );
        case 'registration':
            return (
                <CancelSessionButton
                    icon={<ErrorCircleRegular />}
                    title={t('cancelSession.registration.title')}
                    subTitle={t('cancelSession.registration.message')}
                    confirm={t('cancelSession.registration.confirm')}
                    close={t('cancelSession.registration.decline')}
                />
            );
        case 'registration-finalization':
            return (
                <CancelSessionButton
                    icon={<ErrorCircleRegular />}
                    title={t('cancelSession.registrationFinalization.title')}
                    subTitle={t('cancelSession.registrationFinalization.message')}
                    confirm={t('cancelSession.registrationFinalization.confirm')}
                    close={t('cancelSession.registrationFinalization.decline')}
                />
            );
        case 'default':
            return (
                <CancelSessionButton
                    icon={<ErrorCircleRegular />}
                    title={t('cancelSession.default.title', merchantName ? { merchant: merchantName } : { context: 'GenericMerchant' })}
                    subTitle={codeGenerated ? t('cancelSession.default.message', { context: 'QrCode' }) : undefined}
                    confirm={t('cancelSession.default.confirm')}
                    close={t('cancelSession.default.decline')}
                />
            );
    }
};
