import { makeStyles } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { register } from '@zastrpay/common';
import { Button, InputField, SelectField, useErrorBoundary } from '@zastrpay/components';
import { useCountries } from '@zastrpay/hooks';
import { Page, PageTitle } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { AddressSearch } from './AddressSearch';
import { searchAddresses } from './api';
import { Address, SearchAddress } from './models';

export type AddressFormProps = {
    mode?: 'register' | 'update';
    defaultValues?: Partial<Address>;
    onComplete?: (address: Address) => void;
};

const defaultCountry = 'DE';
const allowedCountry = /DE/;

const STREET_REGEX = /^[\p{L}0-9', \-.\\/()]{2,50}$/u;
const CITY_REGEX = /^[\p{L}0-9', \-.\\/()]{2,50}$/u;
const POSTAL_CODE_REGEX = /\d{5}/; // Has to be adapted when we have multiple countries in the address

export const AddressForm: React.FC<AddressFormProps> = ({ mode, defaultValues, onComplete }) => {
    const classes = useStyles();
    const { i18n, t } = useTranslation('pages');
    const { trackError } = useErrorBoundary();

    const { countries } = useCountries({ sort: 'name' });

    const { handleSubmit, control, setValue, reset } = useForm<Address>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: defaultValues,
    });

    useEffect(() => {
        trackPage('address_input');
    }, []);

    const [showForm, setShowForm] = useState(!!defaultValues);

    const submit = handleSubmit((data) => {
        trackClick('address_input', 'continue');

        onComplete?.(data);
    });

    const findAddress = async (search: string): Promise<SearchAddress[]> => {
        setShowForm(false);
        reset();

        if (search.trim()) {
            try {
                const { items } = await searchAddresses(search, i18n.language, [defaultCountry]);

                return items;
            } catch (e) {
                // don't do anything if address search does not work, just log it and return empty result to
                // show the option to enter address manually
                trackError(e);
            }
        }

        return [];
    };

    const handleSelected = (result?: SearchAddress) => {
        setValue('streetAddress', result?.streetAddress ?? '');
        setValue('city', result?.city ?? '');
        setValue('postalCode', result?.postalCode ?? '');
        setValue('country', result?.countryCode ?? defaultCountry);

        setShowForm(true);
    };

    const country = defaultValues?.country || defaultCountry;

    return (
        <Page align="fill">
            <PageTitle title={t('address.details.title')} subTitle={t('address.details.subTitle')} />

            <AddressSearch onSearch={findAddress} onSelected={handleSelected} manualEntry={!showForm} />

            {showForm && (
                <>
                    <InputField
                        {...register(control, 'streetAddress', { required: true, pattern: STREET_REGEX })}
                        size="large"
                        label={t('address.details.streetAddress')}
                        autoComplete="street-address"
                    />

                    <div className={classes.group}>
                        <InputField
                            {...register(control, 'postalCode', { required: true, pattern: POSTAL_CODE_REGEX })}
                            size="large"
                            label={t('address.details.postalCode')}
                            autoComplete="postal-code"
                            input={{ className: classes.groupItem }}
                        />

                        <InputField
                            {...register(control, 'city', { required: true, pattern: CITY_REGEX })}
                            size="large"
                            label={t('address.details.city')}
                            autoComplete="city"
                            input={{ className: classes.groupItem }}
                        />
                    </div>

                    <SelectField
                        {...register(control, 'country', { required: true, pattern: allowedCountry })}
                        size="large"
                        label={t('address.details.country')}
                        autoComplete="country"
                        disabled={country === defaultCountry}
                        options={countries.map((c) => ({ key: c.countryCode, text: c.name, value: c.countryCode }))}
                    />

                    <Button appearance="primary" onClick={submit} size="large">
                        {t('address.details.submit')}
                    </Button>
                </>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    group: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        columnGap: tokens.spacingHorizontalM,
        minWidth: 0,
        minHeight: 0,
    },
    groupItem: {
        minWidth: 0,
        maxWidth: '100%',
    },
});
