import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ensureResponseError, isResponseError, TranslatableError } from '@zastrpay/common';
import { Alert, Body, ErrorTrans, FormattedCurrency } from '@zastrpay/components';
import { get as getCustomer } from '@zastrpay/customers';
import { tokens } from '@zastrpay/theme';

import { TransactionIntent } from './models';

export type TransactionIntentInfoProps = {
    intent: TransactionIntent;
    merchant?: string;
    className?: string;
};

export const TransactionIntentInfo: React.FC<TransactionIntentInfoProps> = (props) => {
    const { t } = useTranslation('transaction-intents');

    const classes = useStyles();

    const [recipient, setRecipient] = useState<string>();
    const [error, setError] = useState<TranslatableError>();

    useEffect(() => {
        if (props.intent.type === 'PassthroughDeposit') {
            setRecipient(props.merchant);
        } else {
            getCustomer(props.intent.customerId)
                .then((customer) => {
                    setRecipient(`${customer?.firstName} ${customer?.lastName}`);
                })
                .catch((e) => setError(ensureResponseError(e)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.intent, props.merchant]);

    return (
        <article className={mergeClasses(classes.list, props.className)}>
            <Body className={classes.property}>
                <span>{t('common.amount')}:</span>
                <b>
                    <FormattedCurrency currency={props.intent.currency} value={props.intent.amount} />
                </b>
            </Body>
            <Body className={classes.property}>
                <span>{t('common.recipient')}: </span>
                <b data-clarity-mask="true">{recipient}</b>
                {error && (
                    <Alert type="error">
                        <ErrorTrans
                            t={t}
                            error={error}
                            values={{
                                intentType: t('error.intentType', { context: props.intent.type }),
                            }}
                            additionalContext={
                                isResponseError(error, 'CustomerPermissionDenied')
                                    ? (error.details?.customerPermissionDeniedReason as string)
                                    : undefined
                            }
                        />
                    </Alert>
                )}
            </Body>
        </article>
    );
};

const useStyles = makeStyles({
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalS,
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalL}`,
        border: `1px solid ${tokens.colorNeutralStroke1}`,
        borderRadius: tokens.borderRadiusMedium,
    },
    property: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: tokens.spacingHorizontalS,
    },
});
