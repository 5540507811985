import { makeStyles } from '@fluentui/react-components';
import { MailRegular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { Body, BodyStrong, Button, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

export const Support: React.FC = (props) => {
    const { t } = useTranslation('pages');
    const classes = useStyles();

    return (
        <Page>
            <Title>{t('support.title')}</Title>
            <Body>{t('support.subTitle')}</Body>

            <BodyStrong className={classes.email}>{t('support.emailSupport')}</BodyStrong>

            <Button as="a" href={`mailto:${t('support.emailContact')}`} appearance="outline" size="large" icon={<MailRegular />}>
                {t('support.emailContact')}
            </Button>
        </Page>
    );
};

const useStyles = makeStyles({
    email: {
        marginTop: tokens.spacingVerticalM,
    },
});
