import { DividerProps, Divider as FuiDivider, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import React from 'react';

import { tokens } from '@zastrpay/theme';

export const Divider: React.FC<DividerProps> = ({ className, ...props }) => {
    const classes = useStyles();
    return <FuiDivider {...props} className={mergeClasses(classes.divider, className)} />;
};

const useStyles = makeStyles({
    divider: {
        flexGrow: 0,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase600,
        '::before': {
            ...shorthands.borderColor(tokens.customPaletteGreen),
        },
        '::after': {
            ...shorthands.borderColor(tokens.customPaletteGreen),
        },
    },
});
