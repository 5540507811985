import { api } from '@zastrpay/common';

import { endpoints } from '../config';
import { Merchant } from './models';

export const get = async (id: string): Promise<Merchant> => {
    // most customers currently will query this merchant, so for now we hardcode it
    // in the future we should think about a more sophisticated caching logic
    return id === '7eca6b62-23ce-4fbf-855b-7f6c1c54d226'
        ? {
              id: '7eca6b62-23ce-4fbf-855b-7f6c1c54d226',
              name: 'Tipico',
          }
        : await api.get<Merchant>(`${endpoints.merchQryh}/merchants/${id}`);
};
