import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticationMethod, AuthenticationVerifyResult, isAuthenticationError, useAuth } from '@zastrpay/auth';
import { ensureResponseError, isResponseError, TranslatableError } from '@zastrpay/common';
import { ErrorTrans, Link } from '@zastrpay/components';
import { useRequestLoader } from '@zastrpay/hooks';
import { PhoneInput } from '@zastrpay/pages';
import { PinSetup } from '@zastrpay/registration';

import { LoginOtpInput } from './LoginOtpInput';
import { LoginPinInput } from './LoginPinInput';

type PhoneStep = {
    type: 'phone';
};

type VerifyPhoneStep = {
    type: 'verify-phone';
    phone: string;
    alternatives?: AuthenticationMethod[];
};

type VerifyEmailStep = {
    type: 'verify-email';
    email: string;
    phone: string;
};

type PinStep = {
    type: 'pin';
    phone: string;
    alternatives?: AuthenticationMethod[];
};

type PinSetupStep = {
    type: 'pin-setup';
    phone: string;
};

type Step = PhoneStep | PinStep | PinSetupStep | VerifyPhoneStep | VerifyEmailStep;

export type StepType = Step['type'];

const OTP_AUTH_FACTOR_NOT_FOUND = 'OtpAuthFactorNotFound';
const SESSION_MISMATCH = 'OtpAuthFactorSessionMismatch';
const OTP_AUTH_FACTOR_NOT_REGISTERED = 'OtpAuthFactorIsNotRegisteredForCustomer';
const CUSTOMER_WITHOUT_EMAIL = 'CustomerWithoutEmail';

export type LoginProps = {
    handleIncompleteAuthentication?: boolean;
    onRegister?: () => void;
    onLogin?: () => void;
    onPinChange?: () => void;
    onEmailChange?: () => void;
    onPhoneChange?: () => void;
    onStepChange?: (step: StepType) => void;
    compact?: boolean;
};

export const Login: React.FC<LoginProps> = (props) => {
    const loading = useRequestLoader();

    const { t, i18n } = useTranslation('login');

    const {
        refreshToken,
        currentOtpVerificationStep,
        createOtpAuthFactor,
        generateOtpCode,
        verifyOtpCode,
        startCustomerSession,
        verifyPinByOtpAuthFactor,
        setPreferredAuthMethod,
        verifyNext,
        registerNext,
        phone,
        lastAuthenticatedPhone,
        state,
        customerId,
        session,
    } = useAuth();

    const initVerification = (): Step => {
        if (phone && verifyNext) {
            if (registerNext === 'Pin') {
                return { type: 'pin-setup', phone };
            }

            if (currentOtpVerificationStep?.type === 'pending-verification') {
                if (verifyNext?.[0] === 'Email' && currentOtpVerificationStep.otpAuthFactor.type === 'Email') {
                    return { type: 'verify-email', phone, email: currentOtpVerificationStep.otpAuthFactor.contact };
                }

                if (verifyNext?.[0] === 'Sms' && currentOtpVerificationStep.otpAuthFactor.type === 'Phone') {
                    return { type: 'verify-phone', phone };
                }
            }
        }

        return { type: 'phone' };
    };

    const [step, setStep] = useState<Step>(initVerification);
    const [error, setError] = useState<TranslatableError>();

    useEffect(() => {
        if (state === 'authenticated') {
            props.onLogin?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (step) {
            props.onStepChange?.(step.type);
        }
    }, [step, props]);

    const nextVerification = async ({ next, nextIncomplete }: AuthenticationVerifyResult, phone: string) => {
        if (!next) {
            return props.onLogin?.();
        }

        if (nextIncomplete === 'Pin') {
            props.onStepChange?.('pin-setup');
            return setStep({ type: 'pin-setup', phone });
        }

        const primary = next?.[0];
        const alternatives = next?.slice(1);

        switch (primary) {
            case 'Pin':
                return setStep({ type: 'pin', phone, alternatives });
            case 'Email':
                return await doRequestEmailVerification(phone);
            case 'Sms':
                return await doRequestPhoneVerification(phone, alternatives);
            case undefined:
                return;
        }
    };

    const startVerification = async (phone: string) => {
        setError(undefined);

        let result: AuthenticationVerifyResult | undefined;

        try {
            // if the request is captcha protected we do that otherwise we do refresh the token so we get the proper next step
            if (!session || (session.type === 'Customer' && session.scope !== 'ExistingCustomer')) {
                result = await startCustomerSession('ExistingCustomer', 'Phone', phone);
            } else if (!customerId && session.type === 'Redirect' && session.id) {
                result = await refreshToken(phone);
            } else {
                result = { next: verifyNext };
            }

            if (result) {
                await nextVerification(result, phone);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleError = (error: unknown) => {
        setError(isAuthenticationError(error, 'NoCustomerId') ? error : ensureResponseError(error));
    };

    const doRequestPhoneVerification = async (phone: string, alternatives?: AuthenticationMethod[]) => {
        setError(undefined);

        try {
            await generateOtpCode('Phone', phone, i18n.language);

            setStep({ type: 'verify-phone', phone, alternatives });
        } catch (error) {
            if (
                props.handleIncompleteAuthentication &&
                // if phone number was never setup or setup for a different session but never enabled
                (isResponseError(error, OTP_AUTH_FACTOR_NOT_FOUND) || isResponseError(error, SESSION_MISMATCH))
            ) {
                await doCreatePhoneVerification(phone);
            } else if (isResponseError(error, OTP_AUTH_FACTOR_NOT_REGISTERED)) {
                setStep({ type: 'phone' });
                handleError(error);
            } else {
                handleError(error);
            }
        }
    };

    const doCreatePhoneVerification = async (phone: string) => {
        setError(undefined);

        try {
            await createOtpAuthFactor('Phone', phone, i18n.language);

            setStep({ type: 'verify-phone', phone });
        } catch (error) {
            handleError(error);
        }
    };

    const doVerifyPhone = async (otp: string | undefined, phone: string) => {
        setError(undefined);

        if (otp) {
            try {
                const result = await verifyOtpCode('Phone', otp);

                await nextVerification(result, phone);
            } catch (error) {
                handleError(error);
            }
        }
    };

    const doRequestEmailVerification = async (phone: string) => {
        setError(undefined);

        try {
            const email = await generateOtpCode('Email', undefined, i18n.language);
            setStep({ type: 'verify-email', phone, email });
        } catch (error) {
            if (isResponseError(error, CUSTOMER_WITHOUT_EMAIL)) {
                props.onEmailChange?.();
            } else {
                handleError(error);
            }
        }
    };

    const doVerifyEmail = async (otp: string | undefined, phone: string) => {
        setError(undefined);

        if (otp) {
            try {
                const result = await verifyOtpCode('Email', otp);
                await nextVerification(result, phone);
            } catch (error) {
                handleError(error);
            }
        }
    };

    const doVerifyPin = async (pin: string, phone: string) => {
        setError(undefined);

        try {
            const result = await verifyPinByOtpAuthFactor('Phone', phone, pin);

            await nextVerification(result, phone);
        } catch (error) {
            if (isResponseError(error, OTP_AUTH_FACTOR_NOT_REGISTERED)) {
                setStep({ type: 'phone' });
            }
            handleError(error);
        }
    };

    const changePin = async (phone: string) => {
        setError(undefined);

        try {
            // handle it separately instead of calling doCreatePhoneVerification because it does not throw an error
            // and we don't want to call onPinChange if there was an error
            await generateOtpCode('Phone', phone, i18n.language);
            props.onPinChange?.();
        } catch (error) {
            if (isResponseError(error, OTP_AUTH_FACTOR_NOT_REGISTERED)) {
                setStep({ type: 'phone' });
            }
            handleError(error);
        }
    };

    const completePin = async (result: AuthenticationVerifyResult, phone: string) => {
        await nextVerification(result, phone);
    };

    const handleAlternativeAuth = (method: AuthenticationMethod) => {
        setPreferredAuthMethod(method);
        setError(undefined);

        if (step.type === 'pin' && method === 'Sms') {
            const alternatives = verifyNext?.filter((m) => m !== 'Sms');
            // set step before requesting the sms so we show possible errors on sms otp entry
            setStep({ type: 'verify-phone', phone: step.phone, alternatives: alternatives });
            doRequestPhoneVerification(step.phone, alternatives);
        } else if (step.type === 'verify-phone' && method === 'Pin') {
            setStep({ type: 'pin', phone: step.phone, alternatives: verifyNext?.filter((m) => m !== 'Pin') });
        }
    };

    const renderedError = useMemo(
        () =>
            error && (
                <ErrorTrans
                    t={t}
                    error={error}
                    components={{
                        changePhoneLink: <Link inline as="a" onClick={props.onPhoneChange} />,
                    }}
                    additionalContext={currentOtpVerificationStep?.otpAuthFactor.type ?? 'Phone'}
                />
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error, step.type, currentOtpVerificationStep?.otpAuthFactor.type],
    );

    switch (step.type) {
        case 'phone':
            return (
                <PhoneInput
                    defaultValue={lastAuthenticatedPhone}
                    onInput={startVerification}
                    onChange={props.onPhoneChange}
                    onAction={props.onRegister}
                    error={renderedError}
                    layout={props.compact ? 'next' : 'login'}
                />
            );
        case 'verify-phone':
            return (
                <LoginOtpInput
                    onInput={(otp) => doVerifyPhone(otp, step.phone)}
                    onResend={() => doRequestPhoneVerification(step.phone, step.alternatives)}
                    error={renderedError}
                    value={step.phone}
                    disabled={loading}
                    mode="phone"
                    alternativeAuthOptions={step.alternatives?.map((method) => ({
                        title: t('alternative.title', { context: method }),
                        value: method,
                    }))}
                    onNoAccess={registerNext === 'Sms' ? undefined : props.onPhoneChange}
                    onAlternativeAuth={handleAlternativeAuth}
                />
            );
        case 'verify-email':
            return (
                <LoginOtpInput
                    onInput={(otp) => doVerifyEmail(otp, step.phone)}
                    onResend={() => doRequestEmailVerification(step.phone)}
                    error={renderedError}
                    value={step.email}
                    disabled={loading}
                    mode="email"
                    onNoAccess={props.onEmailChange}
                />
            );
        case 'pin':
            return (
                <LoginPinInput
                    title={t('pin.title')}
                    subTitle={t('pin.subTitle')}
                    resetPin={t('pin.forgot')}
                    alternativeAuthOptions={step.alternatives?.map((method) => ({
                        title: t('alternative.title', { context: method }),
                        value: method,
                    }))}
                    onPinEnter={(pin) => pin && doVerifyPin(pin, step.phone)}
                    onAlternativeAuth={handleAlternativeAuth}
                    onResetPin={() => changePin(step.phone)}
                    error={renderedError}
                    disabled={loading}
                />
            );
        case 'pin-setup':
            return <PinSetup onComplete={(result) => completePin(result, step.phone)} />;
    }
};
