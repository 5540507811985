import { makeStyles } from '@fluentui/react-components';

import { Body, Button, Link } from '@zastrpay/components';
import { PageTitle } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

type Action = {
    title: string;
    onClick: () => void;
    disabled?: boolean;
};

export type AdditionalDataQuestionProps = {
    icon: React.ReactNode;
    title: string;
    confirmAction: Action;
    declineAction?: Action;
} & React.PropsWithChildren;

export const AdditionalDataQuestion: React.FC<AdditionalDataQuestionProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Body align="start" className={classes.title}>
                <div className={classes.icon}>{props.icon}</div>
                <PageTitle title={props.title} />
                {props.children}
            </Body>
            <Button appearance="primary" onClick={props.confirmAction.onClick} size="large" disabled={props.confirmAction.disabled}>
                {props.confirmAction.title}
            </Button>
            {props.declineAction && (
                <Link className={classes.decline} onClick={props.declineAction.onClick} disabled={props.declineAction.disabled}>
                    {props.declineAction.title}
                </Link>
            )}
        </div>
    );
};

const useStyles = makeStyles({
    icon: {
        fontSize: '48px',
    },
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: tokens.spacingVerticalM,
    },
    title: {
        textAlign: 'center',
    },
    decline: {
        fontWeight: tokens.fontWeightSemibold,
    },
});
