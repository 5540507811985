import { api, sanitize, Serialized } from '@zastrpay/common';

import { TRANSACTION_CMDH, TRANSACTION_QRYH } from './config';
import { ApproveTransactionIntent, CreateTransactionIntent, TransactionIntent } from './models';

const deserializeTransactionIntent = (transactionIntent: Serialized<TransactionIntent>): TransactionIntent => {
    return {
        ...transactionIntent,
        createdOn: new Date(transactionIntent.createdOn),
    };
};

export const get = (id: string): Promise<TransactionIntent> => {
    return api.get<TransactionIntent>(`${TRANSACTION_QRYH}/transaction-intents/${id}`).then(deserializeTransactionIntent);
};

export const create = (id: string, data: CreateTransactionIntent): Promise<TransactionIntent> => {
    return api
        .put<TransactionIntent>(`${TRANSACTION_CMDH}/transaction-intents/${id}`, sanitize(data), { headers: { 'x-request-id': id } })
        .then(deserializeTransactionIntent);
};

export const cancel = (id: string): Promise<TransactionIntent> => {
    return api.post<TransactionIntent>(`${TRANSACTION_CMDH}/transaction-intents/${id}/cancel`).then(deserializeTransactionIntent);
};

export const approve = (id: string, data: ApproveTransactionIntent): Promise<TransactionIntent> => {
    return api
        .post<TransactionIntent>(`${TRANSACTION_CMDH}/transaction-intents/${id}/approve`, sanitize(data))
        .then(deserializeTransactionIntent);
};

export const reset = (id: string): Promise<TransactionIntent> => {
    // empty json body to be removed in later release, already optional in the request handling
    return api
        .post<TransactionIntent>(`${TRANSACTION_CMDH}/transaction-intents/${id}/reset-approval`, {})
        .then(deserializeTransactionIntent);
};
