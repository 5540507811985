import { makeStyles } from '@fluentui/react-components';
import { BuildingBankRegular, ErrorCircleRegular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { trackClick } from '@zastrpay/analytics';
import { BulletList, Dialog, DialogProps } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export type PepConfirmationDialogProps = Omit<DialogProps, 'title'> & {
    onConfirm?: (confirmed: boolean) => void;
};

export const PepDialog: React.FC<PepConfirmationDialogProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('registration');

    const confirmLinks = (confirmed: boolean) => {
        if (confirmed) {
            trackClick('additional_information', 'pep_yes');
        } else {
            trackClick('additional_information', 'pep_no');
        }

        props.onConfirm?.(confirmed);
    };

    return (
        <Dialog
            icon={props.onConfirm ? <ErrorCircleRegular className={classes.icon} /> : <BuildingBankRegular />}
            title={t('additionalDetails.pepDialog.title', { context: props.onConfirm ? 'Confirmation' : 'Info' })}
            open={props.open}
            onOpenChange={props.onOpenChange}
            dismissible={!props.onConfirm}
            align="bottom"
            actions={
                props.onConfirm
                    ? [
                          { text: t('additionalDetails.yes'), onClick: () => confirmLinks(true) },
                          { text: t('additionalDetails.no'), style: 'preferred', onClick: () => confirmLinks(false) },
                      ]
                    : []
            }
        >
            <div className={classes.dialogContent}>
                <BulletList as="ul" size="small">
                    <li>{t('additionalDetails.pepDialog.pepExamples1')}</li>
                    <li>{t('additionalDetails.pepDialog.pepExamples2')}</li>
                    <li>{t('additionalDetails.pepDialog.pepExamples3')}</li>
                    <li>{t('additionalDetails.pepDialog.pepExamples4')}</li>
                    <li>{t('additionalDetails.pepDialog.pepExamples5')}</li>
                    <li>{t('additionalDetails.pepDialog.pepExamples6')}</li>
                    <li>{t('additionalDetails.pepDialog.pepExamples7')}</li>
                </BulletList>
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalS,
    },
    icon: {
        color: tokens.customPaletteRed,
    },
});
