import { Dismiss24Regular } from '@fluentui/react-icons';

import { Button } from '@zastrpay/components';

export type CloseButtonProps = {
    onClick: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
    return <Button appearance="transparent" size="large" onClick={onClick} icon={<Dismiss24Regular />} />;
};
