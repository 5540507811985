import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLayout } from '@zastrpay/layout';
import { Login as LoginFlow, StepType } from '@zastrpay/login';

import { CancelSession } from '../session-cancellation/CancelSession';

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const { setHeaderSlot } = useLayout();
    const [step, setStep] = useState<StepType>();

    useEffect(() => {
        if (step !== 'verify-phone') {
            return;
        }
        const unregisterHandle = setHeaderSlot('right', <CancelSession dialog="phone-otp" />);

        return () => unregisterHandle();
    }, [step, setHeaderSlot]);

    return (
        <LoginFlow
            compact={true}
            handleIncompleteAuthentication={true}
            onLogin={() => navigate('/intent')}
            onPinChange={() => navigate('/change-pin')}
            onPhoneChange={() => navigate('/change-phone')}
            onEmailChange={() => navigate('/change-email')}
            onStepChange={(step) => setStep(step)}
        />
    );
};
