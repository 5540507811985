import { makeStyles } from '@fluentui/react-components';
import { DismissCircleRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { ensureResponseError } from '@zastrpay/common';
import { Link, Stepper } from '@zastrpay/components';
import { BackButton, MessagePage, useLayout } from '@zastrpay/layout';
import { Address, AddressForm } from '@zastrpay/pages';
import { tokens } from '@zastrpay/theme';

import { AdditionalData, AdditionalDataForm } from './account/AdditionalDataForm';
import { EmailData } from './account/EmailForm';
import { EmailVerificationForm } from './account/EmailVerificationForm';
import { GeneralData, GeneralDataForm } from './account/GeneralDataForm';
import { PersonalData, PersonalDataForm } from './account/PersonalDataForm';
import { preCheckRegistration, registerCustomer } from './api';
import { Merchant, PreCheckRegistrationCommand, RegisterCustomerCommand } from './models';

export type AccountSetupProps = {
    defaultValues?: DeepPartial<{
        personal: PersonalData;
        general: GeneralData;
        email: EmailData;
        address: Address;
    }>;
    merchant?: Merchant;
    defaultStep?: Step;
    onChange?: (step: Step) => void;
    onError?: () => void;
    onComplete?: () => void;
    onBack?: () => void;
};

type PersonalDataStep = {
    type: 'personal';
};

type GeneralDataStep = {
    type: 'general';
    personal: PersonalData;
};

type EmailStep = {
    type: 'email';
    personal: PersonalData;
    general: GeneralData;
};

type AddressStep = {
    type: 'address';
    personal: PersonalData;
    general: GeneralData;
    email: EmailData;
};

type LegalStep = {
    type: 'legal';
    personal: PersonalData;
    general: GeneralData;
    email: EmailData;
    address: Address;
    highRiskCountries: string[];
};

type ErrorStep = {
    type: 'error';
    code?: string;
};

export type Step = PersonalDataStep | GeneralDataStep | EmailStep | AddressStep | LegalStep | ErrorStep;

type StepType = Step['type'];

const steps: StepType[] = ['personal', 'general', 'email', 'address', 'legal'];

export const AccountSetup: React.FC<AccountSetupProps> = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const { t } = useTranslation('registration');
    const { customerId, phone } = useAuth();

    const [step, setStep] = useState<Step>(props.defaultStep ?? { type: 'personal' });

    const [defaultValues, setDefaultValues] = useState(props.defaultValues);

    const { setHeaderSlot } = useLayout();

    const goBack = () => {
        if (step.type === 'legal') {
            setStep({ ...step, type: 'address' });
        } else if (step.type === 'address') {
            setStep({ ...step, type: 'email' });
        } else if (step.type === 'email') {
            setStep({ ...step, type: 'general' });
        } else if (step.type === 'general') {
            setStep({ ...step, type: 'personal' });
        } else {
            props.onBack?.();
        }
    };

    useEffect(() => {
        setHeaderSlot('left', <BackButton onClick={goBack} />);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    if (!customerId || !phone) {
        return <Navigate to="/login" />;
    }

    const changeStep = (target: Step) => {
        props.onChange?.(target);

        setStep(target);
    };

    const setPersonalData = (identity: PersonalData) => {
        changeStep({ type: 'general', personal: identity });
        setDefaultValues({ ...defaultValues, personal: identity });
    };

    const setGeneralData = (data: GeneralDataStep, other: GeneralData) => {
        changeStep({ ...data, type: 'email', general: other });
        setDefaultValues({ ...defaultValues, general: other });
    };

    const setEmail = (data: EmailStep, email: EmailData) => {
        changeStep({ ...data, type: 'address', email });
        setDefaultValues({ ...defaultValues, email });
    };

    const setAddress = async (data: AddressStep, address: Address) => {
        try {
            const { highRiskCountries } = await performPreCheck(data, address);

            changeStep({ ...data, type: 'legal', address, highRiskCountries });
            setDefaultValues({ ...defaultValues, address });
        } catch {
            setStep({ type: 'error' });
        }
    };

    const performPreCheck = (data: AddressStep, address: Address) => {
        const request: PreCheckRegistrationCommand = {
            addressCountry: address.country,
            countryOfBirth: data.general.countryOfBirth,
            nationality: data.general.nationality,
        };
        return preCheckRegistration(request);
    };

    const completeRegistration = async (
        customerId: string,
        data: LegalStep,
        phoneNumber: string | undefined,
        additionalData: AdditionalData,
    ) => {
        const request: RegisterCustomerCommand = {
            ...data.personal,
            ...data.general,
            ...data.email,
            ...additionalData,
            phoneNumber,
            address: data.address,
        };

        try {
            await registerCustomer(customerId, request);

            props.onComplete?.();
        } catch (error) {
            setStep({ type: 'error', code: ensureResponseError(error).code });
        }
    };

    if (step.type === 'error') {
        return (
            <MessagePage
                icon={<DismissCircleRegular />}
                title={t('failed.title')}
                message={
                    <Trans
                        t={t}
                        i18nKey="failed.subTitle"
                        context={step.code === 'DuplicateCustomerRegistrationNotAllowed' ? step.code : undefined}
                        values={{ merchant: props.merchant?.displayName ?? props.merchant?.name }}
                        components={{
                            supportLink: <Link inline onClick={() => navigate('/support')} />,
                        }}
                    />
                }
                action={t('failed.back', { merchant: props.merchant?.displayName ?? props.merchant?.name })}
                onAction={props.onError}
            />
        );
    }

    return (
        <>
            <Stepper className={classes.progress} count={steps.length} current={steps.indexOf(step.type) + 1} />

            {step.type === 'personal' && (
                <PersonalDataForm defaultValues={defaultValues?.personal} onComplete={(data) => setPersonalData(data)} />
            )}

            {step.type === 'general' && (
                <GeneralDataForm defaultValues={defaultValues?.general} onComplete={(data) => setGeneralData(step, data)} />
            )}

            {step.type === 'email' && (
                <EmailVerificationForm defaultValues={defaultValues?.email} onComplete={(data) => setEmail(step, data)} />
            )}

            {step.type === 'address' && (
                <AddressForm defaultValues={defaultValues?.address} onComplete={(data) => setAddress(step, data)} />
            )}

            {step.type === 'legal' && (
                <AdditionalDataForm
                    highRiskCountries={step.highRiskCountries}
                    merchant={props.merchant}
                    onComplete={(data) => completeRegistration(customerId, step, phone, data)}
                    onBack={goBack}
                />
            )}
        </>
    );
};

const useStyles = makeStyles({
    progress: {
        position: 'sticky',
        zIndex: 1,
        top: `calc(${tokens.customHeaderHeight} * 1)`,
        padding: `${tokens.spacingVerticalS} ${tokens.spacingHorizontalS} ${tokens.spacingVerticalXXL}`,
        margin: `0 auto ${`calc(${tokens.spacingVerticalXXL} * -1)`}`,
        maxWidth: tokens.customPageWidth,
    },
});
