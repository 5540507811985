const storage = new Map<string, unknown>();

export const retrieve = <T>(key: string): T | undefined => {
    return storage.get(key) as T;
};

export const store = <T>(key: string, value?: T): void => {
    if (value) {
        storage.set(key, value);
    } else {
        storage.delete(key);
    }
};
