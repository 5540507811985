import { makeStyles } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { ensureResponseError } from '@zastrpay/common';
import { AlertDialog, ErrorTrans } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { Flow } from '../Flow';
import { Step } from '../models';
import { PhoneOtpInput } from '../PhoneOtpInput';
import { useFlow } from '../use-flow';
import { PhoneInput } from './PhoneInput';

export type ChangePhoneSettingsProps = {
    onChange?: () => void;
};

export const ChangePhoneSettings: React.FC<ChangePhoneSettingsProps> = ({ onChange }) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation('auth-flow');
    const { createOtpAuthFactor } = useAuth();

    const flow = useFlow('ChangePhone', () => ({ type: 'setup-new-phone' }));

    const phoneProvided = async (phone: string, flowId?: string) => {
        try {
            await createOtpAuthFactor('Phone', phone, i18n.language, flowId);

            flow.setStep({ type: 'verify-new-phone', phone, success: false });
        } catch (error) {
            flow.setStep({ type: 'setup-new-phone', error: ensureResponseError(error) } as Step);
        }
    };

    const phoneVerified = (phone: string) => {
        flow.setStep({ type: 'verify-new-phone', phone, success: true });
        trackPage('change_phone_success');
    };

    const renderStep = (step: Step) => {
        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (step.type) {
            case 'setup-new-phone':
                return (
                    <PhoneInput
                        className={classes.page}
                        onChange={(phone) => phoneProvided(phone, flow.id)}
                        error={step.error && <ErrorTrans t={t} error={step.error} />}
                    />
                );
            case 'verify-new-phone':
                return (
                    <>
                        <PhoneOtpInput
                            phone={step.phone}
                            flowId={flow.id}
                            onVerify={() => phoneVerified(step.phone)}
                            title={t('changePhone.newPhoneOtp.title')}
                        />
                        <AlertDialog
                            type="success"
                            title={t('changePhone.phoneIsChanged.title')}
                            content={t('changePhone.phoneIsChanged.subTitle')}
                            open={step.success}
                            onOpenChange={onChange}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return <Flow {...flow} renderStep={renderStep} />;
};

const useStyles = makeStyles({
    page: {
        backgroundColor: tokens.colorNeutralBackground1,
        flexGrow: 1,
    },
});
