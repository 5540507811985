import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { cloneElement, PropsWithChildren, ReactElement, ReactNode, useMemo } from 'react';

import { Body, Button, Link, Title } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { Page } from './Page';

export type MessagePageProps = {
    icon: ReactElement;
    title: string | ReactNode;
    message?: React.ReactNode;
    action?: string | null;
    onAction?: () => void;
    secondaryAction?: string | null;
    onSecondaryAction?: () => void;
};

export const MessagePage: React.FC<PropsWithChildren<MessagePageProps>> = (props) => {
    const classes = useStyles();

    const icon = useMemo(
        () => cloneElement(props.icon, { className: mergeClasses(classes.icon, props.icon.props.className) }),
        [props.icon, classes.icon],
    );

    return (
        <Page className={classes.page}>
            {icon}

            <Title className={classes.title}>{props.title}</Title>
            {props.message && <Body className={classes.message}>{props.message}</Body>}

            {props.action && (
                <Button size="large" appearance="primary" onClick={props.onAction} className={classes.button}>
                    {props.action}
                </Button>
            )}

            {props.children}

            {props.secondaryAction && (
                <Link onClick={props.onSecondaryAction} className={classes.link}>
                    {props.secondaryAction}
                </Link>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    page: {
        justifyContent: 'center',
        flex: '1',
    },
    icon: {
        fontSize: '76px',
        color: tokens.colorNeutralForeground1,
    },
    title: {
        color: tokens.colorNeutralForeground1,
    },
    message: {
        fontSize: tokens.colorNeutralForeground2,
        alignSelf: 'stretch',
    },
    button: {
        marginTop: tokens.spacingVerticalL,
    },
    link: {
        marginTop: tokens.spacingVerticalL,
    },
});
