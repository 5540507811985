// eslint-disable-next-line no-restricted-imports
import { Body2, makeStyles, mergeClasses, TextPresetProps } from '@fluentui/react-components';

import { tokens } from '@zastrpay/theme';

export const BodyStrong: React.FC<TextPresetProps> = ({ children, align, ...props }) => {
    const classes = useStyles();

    return (
        <Body2 className={mergeClasses(classes.body, props.className)} align={align ?? 'center'} {...props}>
            {children}
        </Body2>
    );
};

const useStyles = makeStyles({
    body: {
        fontWeight: tokens.fontWeightSemibold,
    },
});
