// eslint-disable-next-line no-restricted-imports
import { makeStyles, mergeClasses, TextPresetProps, Title3 } from '@fluentui/react-components';

import { tokens } from '@zastrpay/theme';

export const Title: React.FC<TextPresetProps> = ({ children, align, className, ...props }) => {
    const classes = useStyles();

    return (
        <Title3 className={mergeClasses(classes.title, className)} align={align ?? 'center'} {...props}>
            {children}
        </Title3>
    );
};

const useStyles = makeStyles({
    title: {
        color: tokens.colorNeutralForeground2,
    },
});
