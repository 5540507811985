import { makeStyles } from '@fluentui/react-components';
import { MailRegular, PhoneChatRegular } from '@fluentui/react-icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { trackClick, trackInput, trackPage } from '@zastrpay/analytics';
import { AuthenticationMethod } from '@zastrpay/auth';
import { Alert, Body, BodyStrong, Button, Divider, Link, Pin, PinElement, Timer, TimerElement, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { OTP_RESEND_WAIT } from './OtpInput';

type AuthOption = { title: string; value: AuthenticationMethod };

export type DeprecatedOtpInputProps = {
    value?: string;
    error?: React.ReactElement;
    disabled?: boolean;
    onInput?: (otp?: string) => void;
    onResend?: () => void;
    onNoAccess?: () => void;
    mode: 'phone' | 'email';
    title?: string;
    className?: string;
    alternativeAuthOptions?: AuthOption[];
    onAlternativeAuth?: (value: AuthenticationMethod) => void;
};

export const DeprecatedOtpInput: React.FC<DeprecatedOtpInputProps> = (props) => {
    const pinRef = useRef<PinElement>(null);
    const timer = useRef<TimerElement>(null);
    const { t } = useTranslation('pages');
    const [canSend, setCanSend] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (props.mode === 'phone') {
            trackPage('phone_verify');
        } else if (props.mode === 'email') {
            trackPage('email_verify');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const translations = useMemo(() => {
        switch (props.mode) {
            case 'phone':
                return {
                    title: props.title ?? t('deprecatedOtpInput.phoneOtp.title'),
                    subTitle: t('deprecatedOtpInput.phoneOtp.subTitle'),
                    sendAgain: t('deprecatedOtpInput.phoneOtp.sendAgain'),
                    noAccess: t('deprecatedOtpInput.phoneOtp.noAccess'),
                    infoBox: t('deprecatedOtpInput.phoneOtp.infoBox'),
                };
            case 'email':
                return {
                    title: props.title ?? t('deprecatedOtpInput.emailOtp.title'),
                    subTitle: t('deprecatedOtpInput.emailOtp.subTitle'),
                    sendAgain: t('deprecatedOtpInput.emailOtp.sendAgain'),
                    noAccess: t('deprecatedOtpInput.emailOtp.noAccess'),
                };
        }
    }, [props.mode, t, props.title]);

    useEffect(() => {
        if (props.error) {
            pinRef.current?.reset();
        }
    }, [props.error]);

    const timerEnded = () => {
        setCanSend(true);
    };

    const onOtpInput = (otp?: string) => {
        if (otp) {
            if (props.mode === 'phone') {
                trackInput('phone_verify', 'enter_sms_otp');
            } else if (props.mode === 'email') {
                trackInput('email_verify', 'enter_email_otp');
            }
        }

        props.onInput?.(otp);
    };

    const resendOtp = () => {
        if (props.mode === 'phone') {
            trackClick('phone_verify', 'resend_sms_otp');
        } else if (props.mode === 'email') {
            trackClick('email_verify', 'resend_sms_otp');
        }

        pinRef.current?.reset();
        props.onResend?.();

        setCanSend(false);
        timer.current?.reset();
    };

    return (
        <Page className={props?.className}>
            {props.mode === 'phone' ? <PhoneChatRegular className={classes.icon} /> : <MailRegular className={classes.icon} />}
            <Title>{translations.title}</Title>
            <Body>{translations.subTitle}</Body>

            <BodyStrong>{props.value}</BodyStrong>

            <Pin length={6} ref={pinRef} onInput={onOtpInput} autoFill autoFocus disabled={props.disabled} hyphenated={true} />
            {props.error && <Alert type="error">{props.error}</Alert>}
            <Button appearance="primary" size="large" onClick={resendOtp} disabled={!canSend}>
                {canSend ? (
                    translations.sendAgain
                ) : (
                    <Trans
                        t={t}
                        i18nKey={
                            props.mode === 'phone' ? 'deprecatedOtpInput.phoneOtp.sendAgainIn' : 'deprecatedOtpInput.emailOtp.sendAgainIn'
                        }
                        components={{
                            timer: <Timer seconds={OTP_RESEND_WAIT} ref={timer} onEnd={timerEnded} />,
                        }}
                    />
                )}
            </Button>
            {translations.infoBox && <Alert type="info">{translations.infoBox}</Alert>}

            {props.onNoAccess && (
                <Link className={classes.link} inline onClick={props.onNoAccess}>
                    {translations.noAccess}
                </Link>
            )}
            {!!props.alternativeAuthOptions?.length && props.onAlternativeAuth && (
                <>
                    <Divider>{t('deprecatedOtpInput.or')}</Divider>
                    {props.alternativeAuthOptions.map((option) => (
                        <Button
                            key={option.value}
                            appearance="outline"
                            size="large"
                            onClick={() => props.onAlternativeAuth?.(option.value)}
                        >
                            {option.title}
                        </Button>
                    ))}
                </>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
    icon: {
        height: tokens.iconSizeTitle,
        width: tokens.iconSizeTitle,
    },
});
