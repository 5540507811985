import { dateUtil } from '@zastrpay/common';

const formatters = new Map<DateFormat, (date: Date | string) => string>([
    ['time', dateUtil.formatTime],
    ['exact-time', dateUtil.formatExactTime],
    ['date', dateUtil.formatDate],
    ['full-date', dateUtil.formatFullDate],
    ['exact-date', dateUtil.formatExactDate],
    ['detail-date', dateUtil.formatDetailDate],
]);

export type DateFormat = 'time' | 'exact-time' | 'date' | 'full-date' | 'exact-date' | 'detail-date';

export type FormattedDateProps = {
    value: string | Date | undefined;
    format?: DateFormat;
};

export const FormattedDate: React.FC<FormattedDateProps> = (props) => {
    const format = props.format ?? 'full-date';
    const formatter = formatters.get(format);

    const formatted = props.value && formatter?.(props.value);

    if (formatted) {
        return formatted;
    } else {
        return null;
    }
};
