import { ActiveKycRequestType } from './ActiveKycRequestProvider';
import { KycRequestPage } from './KycRequestPage';

export type WithActiveKycRequestProps<P> = {
    mode?: ActiveKycRequestType;
    skippable?: boolean;
    onSkip?: () => void;
} & P;

export const withActiveKycRequest = <P extends object>(Component: React.FC<P>) => {
    return (props: WithActiveKycRequestProps<P>) => (
        <KycRequestPage mode={props.mode} skippable={props.skippable} onSkip={props.onSkip}>
            <Component {...props} />
        </KycRequestPage>
    );
};
