import { ClockDismissRegular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { MessagePage } from '@zastrpay/layout';

import { Merchant } from '../merchants/models';

export type SessionExpiredProps = {
    merchant?: Merchant;
    onBack: () => void;
};

export const SessionExpired: React.FC<SessionExpiredProps> = (props) => {
    const { t } = useTranslation('payment');

    const name = props.merchant?.displayName ?? props.merchant?.name ?? t('common.merchant');

    useEffect(() => {
        trackPage('error_session_expired', { type: 'error' });
    }, []);

    return (
        <MessagePage
            icon={<ClockDismissRegular />}
            title={t('session.expired.title')}
            message={t('session.expired.subTitle')}
            action={t('session.expired.back', { merchant: name })}
            onAction={props.onBack}
        />
    );
};
