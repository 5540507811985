export const retrieve = <T>(key: string): T | undefined => {
    const value = localStorage.getItem(key);

    if (value) {
        try {
            return JSON.parse(value);
        } catch {}
    }
};

export const store = <T>(key: string, value?: T): void => {
    if (value) {
        localStorage.setItem(key, JSON.stringify(value));
    } else {
        localStorage.removeItem(key);
    }
};
