import { isNetworkError } from 'axios-retry';
import { jwtDecode } from 'jwt-decode';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthenticationProvider, AuthenticationStateInitializer, AuthenticationStateInitializerResult } from '@zastrpay/auth';
import {
    api,
    isAuthenticationError,
    isAuthorizationError,
    isNetworkError as isConnectionError,
    LANGUAGES,
    memoryStorage,
    sessionStorage,
} from '@zastrpay/common';

import * as AuthApi from './auth/api';
import { RedirectSession } from './auth/models';
import { MOCK_AUTHENTICATION_HEADERS } from './config';
import { get as getMerchant } from './merchants/api';
import { Merchant } from './merchants/models';

type AppState = {
    codeGenerated?: boolean;
    codeFinalized?: boolean;
    redirectSession?: RedirectSession;
    merchant?: Merchant;
    lastUsedPhone?: string;
};

type AppStorage = {
    codeGenerated?: boolean;
    codeCompleted?: boolean;
    redirectSession?: RedirectSession;
    merchant?: Merchant;
    token?: string;
};

const CONTEXT_KEY = 'context';

const retrieveContext = () => sessionStorage.retrieve<AppStorage>(CONTEXT_KEY);

const storeContext = (context: AppStorage | undefined) => sessionStorage.store<AppStorage>(CONTEXT_KEY, context);

type TokenPayload = {
    sid: string;
    merchId: string;
    exp: number;
    failUrl: string;
};

const retrieveToken = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const token = params.get('token');

    if (token) {
        url.searchParams.delete('token');
        window.history.replaceState({}, '', url.toString());
    }

    return token;
};

const parseToken = (token: string): TokenPayload | undefined => {
    try {
        return jwtDecode<TokenPayload>(token);
    } catch {
        // empty
    }
};

const injectToken = async <T,>(token: string, operation: () => Promise<T>) => {
    let id: number;

    api.apply((instance) => {
        id = instance.interceptors.request.use((request) => {
            request.headers.set('authorization', `Bearer ${token}`);

            if (MOCK_AUTHENTICATION_HEADERS) {
                const parsed = parseToken(token);
                request.headers.set('x-session-id', parsed?.sid);
            }

            return request;
        });
    });

    const result = await operation();

    api.apply((instance) => {
        instance.interceptors.request.eject(id);
    });

    return result;
};

export type AppContext = AppState & {
    clear: () => void;
    cancelRedirectSession: () => Promise<void>;
    setCodeGenerated: () => void;
    setCodeFinalized: () => void;
};

const Context = createContext<AppContext | null>(null);

export const AppProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { i18n } = useTranslation('payment');
    const [state, setState] = useState<AppState>({});

    const init: AuthenticationStateInitializer = async (storedState, storedToken, parser) => {
        const tempToken = retrieveToken();

        if (tempToken) {
            // if we have new token we initialize new state
            storeContext({ token: tempToken });
        }

        const context = retrieveContext();
        const merchantToken = tempToken ?? context?.token;

        updateState({
            redirectSession: context?.redirectSession,
            merchant: context?.merchant,
            codeGenerated: context?.codeGenerated,
        });

        const defaultState: AuthenticationStateInitializerResult = storedState
            ? { ...storedState, token: storedToken }
            : { state: 'error', token: storedToken };

        if (!tempToken && storedToken) {
            return { ...defaultState };
        } else if (!merchantToken) {
            return { ...defaultState, state: 'expired' };
        }

        const payload = parseToken(merchantToken);

        if (!payload) {
            return { ...defaultState, state: 'error' };
        }

        const { token, ...redirectSession } = await injectToken(merchantToken, () => AuthApi.exchangeToken(payload.sid));

        // exchange happened, no need for the merchant token anymore
        storeContext({ ...context, token: undefined });

        const merchant = await injectToken(token, () => getMerchant(payload.merchId));
        const codeGenerated = redirectSession.type === 'ExistingTransactionIntent' ? true : state.codeGenerated;

        storeContext({ redirectSession, merchant, codeGenerated });
        updateState({ redirectSession, merchant, codeGenerated });

        const parsed = parser(token);

        return { ...parsed, token };
    };

    const updateState = (diff: Partial<AppState>) => {
        const merchant = memoryStorage.retrieve<Merchant>('merchant');
        memoryStorage.store('merchant', diff.merchant ?? merchant);

        const redirectSession = memoryStorage.retrieve<RedirectSession>('redirectSession');
        memoryStorage.store('redirectSession', diff.redirectSession ?? redirectSession);

        setState((state) => ({ ...state, ...diff }));
    };

    const storeState = (diff: Partial<AppState>) => {
        updateState(diff);

        const storedState = retrieveContext();
        storeContext({ ...storedState, codeCompleted: true });
    };

    const setCodeGenerated = useCallback(() => {
        storeState({ codeGenerated: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCodeFinalized = useCallback(() => {
        storeState({ codeFinalized: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clear = useCallback(() => storeContext(undefined), []);

    const cancelRedirectSession = useCallback(async () => {
        if (state.redirectSession?.id) {
            try {
                await AuthApi.cancel(state.redirectSession?.id);
            } catch (error) {
                if (!isAuthenticationError(error) && !isAuthorizationError(error) && !isNetworkError(error) && !isConnectionError(error)) {
                    throw error;
                }
            }
        }
    }, [state.redirectSession?.id]);

    useEffect(() => {
        const language = LANGUAGES.find((current) => current.locale === state.redirectSession?.locale);

        if (language) {
            i18n.changeLanguage(language.code);

            // only use the redirect session language on the first load, afterwards respect the user choice
            setState((state) => ({
                ...state,
                redirectSession: storedState?.redirectSession ? { ...storedState?.redirectSession, locale: undefined } : undefined,
            }));

            const storedState = retrieveContext();

            storeContext({
                ...storedState,
                redirectSession: storedState?.redirectSession ? { ...storedState?.redirectSession, locale: undefined } : undefined,
            });
        }
    }, [state.redirectSession, i18n]);

    return (
        <Context.Provider value={{ ...state, clear, cancelRedirectSession, setCodeGenerated, setCodeFinalized }}>
            <AuthenticationProvider config={{ init, lastUsedPhone: true }}>{children}</AuthenticationProvider>
        </Context.Provider>
    );
};

export const useApp = (): AppContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useContext must be used within an ApplicationContextProvider');
    }

    return context;
};
