import { useEffect } from 'react';

import { useAuth } from '@zastrpay/auth';

import { disable, enable, identify } from './analytics';
import { analyticsConfig } from './config';
import { useCookieConsent } from './CookieBanner';

export const UserAnalyticsProvider: React.FC<React.PropsWithChildren> = (props) => {
    const { customerId, session } = useAuth();
    const { cookieConsent, setSubmitted } = useCookieConsent();

    useEffect(() => {
        if (!analyticsConfig.enabled) {
            return;
        }

        if (cookieConsent?.types && cookieConsent.types.includes('Analytic')) {
            // start analytics outside of current render cycle
            setTimeout(() => enable(), 1);
        } else {
            disable();
        }
    }, [cookieConsent]);

    useEffect(() => {
        if (customerId && cookieConsent) {
            identify(customerId, session, cookieConsent).then(() => {
                if (cookieConsent.submitted || !cookieConsent.date || !cookieConsent.types) {
                    return;
                }

                setSubmitted();
            });
        }
    }, [cookieConsent, customerId, setSubmitted, session]);

    return props.children;
};
