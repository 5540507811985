import { required } from './validation';

export const LANGUAGES = [
    { code: 'en', locale: 'en-GB', countryCode: 'GB', name: 'English', change: 'Change language' },
    { code: 'de', locale: 'de-DE', countryCode: 'DE', name: 'Deutsch', change: 'Sprache ändern' },
    // { code: 'de', locale: 'de-AT', country: 'AT', name: 'Deutsch' },
];

export const DEFAULT_LANGUAGE = 'de';
export const DEFAULT_LOCALE = 'de-DE';

export const mapLanguage = (locale?: string) => {
    const languageCode = locale?.split('-').shift();
    return (
        LANGUAGES.find((current) => current.code === languageCode) ??
        LANGUAGES.find((current) => current.code === DEFAULT_LANGUAGE) ??
        new Error('Unable to find default language') // should never happen
    );
};

/** Try to get the user locale closest matching the current UI language, else use locale of current UI language */
export const getLocales = () => {
    // Get language which best matches current language or default
    const currentLang = LANGUAGES.find((l) => l.code === document.documentElement.lang)?.code ?? DEFAULT_LANGUAGE;
    // Find user locales which match the current language
    const locales = window.navigator.languages.filter((l) => l.startsWith(currentLang));

    return [
        ...locales,
        // always include locales of the current language as fallback
        ...LANGUAGES.filter((l) => l.code === currentLang).map((l) => l.locale),
    ];
};

export const EMAIL_REGEX =
    /^(?=.{1,100}$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const bool = (value: string | undefined) => value === 'true';

export const defaultRequestTimeout = Number(required(import.meta.env['VITE_DEFAULT_REQUEST_TIMEOUT'], 'VITE_DEFAULT_REQUEST_TIMEOUT'));
export const defaultUploadDownloadTimeout = Number(
    required(import.meta.env['VITE_DEFAULT_UPLOAD_DOWNLOAD_TIMEOUT'], 'VITE_DEFAULT_UPLOAD_DOWNLOAD_TIMEOUT'),
);
