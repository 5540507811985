import { makeStyles } from '@fluentui/react-components';
import { ErrorCircleRegular, MoneyHandRegular } from '@fluentui/react-icons';
import { Trans, useTranslation } from 'react-i18next';

import { trackClick } from '@zastrpay/analytics';
import { BulletList, Dialog, DialogProps } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

export type FinancialTiesDialogProps = Omit<DialogProps, 'title'> & {
    onConfirm?: (confirmed: boolean) => void;
    highRiskCountries: string;
};

export const FinancialTiesDialog: React.FC<FinancialTiesDialogProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('registration');

    const confirmLinks = (confirmed: boolean) => {
        if (confirmed) {
            trackClick('additional_information', 'financialTies_yes');
        } else {
            trackClick('additional_information', 'financialTies_no');
        }

        props.onConfirm?.(confirmed);
    };

    return (
        <Dialog
            icon={props.onConfirm ? <ErrorCircleRegular className={classes.icon} /> : <MoneyHandRegular />}
            title={t('additionalDetails.financialTiesDialog.title', {
                countries: props.highRiskCountries,
                context: props.onConfirm ? 'Confirmation' : 'Info',
            })}
            open={props.open}
            onOpenChange={props.onOpenChange}
            dismissible={!props.onConfirm}
            align="bottom"
            actions={
                props.onConfirm
                    ? [
                          { text: t('additionalDetails.yes'), onClick: () => confirmLinks(true) },
                          { text: t('additionalDetails.no'), style: 'preferred', onClick: () => confirmLinks(false) },
                      ]
                    : []
            }
        >
            <div className={classes.dialogContent}>
                <Trans t={t} i18nKey="additionalDetails.financialTiesDialog.message" />
                <BulletList as="ul" size="small">
                    <li>
                        {/* use Trans for basic html tag replacement */}
                        <Trans t={t} i18nKey="additionalDetails.financialTiesDialog.employment" />
                    </li>
                    <li>
                        <Trans t={t} i18nKey="additionalDetails.financialTiesDialog.investments" />
                    </li>
                    <li>
                        <Trans t={t} i18nKey="additionalDetails.financialTiesDialog.financialSupport" />
                    </li>
                    <li>
                        <Trans t={t} i18nKey="additionalDetails.financialTiesDialog.businessInterests" />
                    </li>
                    <li>
                        <Trans t={t} i18nKey="additionalDetails.financialTiesDialog.ownership" />
                    </li>
                    <li>
                        <Trans t={t} i18nKey="additionalDetails.financialTiesDialog.otherFinancialInvolvement" />
                    </li>
                </BulletList>
            </div>
        </Dialog>
    );
};

const useStyles = makeStyles({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalS,
    },
    icon: {
        color: tokens.customPaletteRed,
    },
});
