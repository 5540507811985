import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormattedDate } from '@zastrpay/components';

import { KycRequestAlert } from '../KycRequestAlert';
import { KycRequest } from '../models';

export type SofwDocumentRequestProps = {
    request: KycRequest;
};

export const SofwDocumentRequest: React.FC<SofwDocumentRequestProps> = ({ request }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('kyc-requests');

    return (
        <KycRequestAlert title={t('sofwDocument.title')} action={t('sofwDocument.action')} onAction={() => navigate('/documents/upload')}>
            <Trans
                t={t}
                i18nKey="sofwDocument.description"
                context={request.state === 'PendingOverdue' ? 'Overdue' : undefined}
                components={{ dueOn: <FormattedDate value={request.dueOn} format="date" /> }}
            />
        </KycRequestAlert>
    );
};
