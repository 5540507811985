import { useNavigate } from 'react-router-dom';

import { PinInput, PinInputProps } from '@zastrpay/auth-flow';
import { BackButton, useHeaderSlot } from '@zastrpay/layout';

export const LoginPinInput: React.FC<PinInputProps> = (props: PinInputProps) => {
    const navigate = useNavigate();

    useHeaderSlot('left', <BackButton onClick={() => navigate('/')} />);

    return <PinInput {...props} />;
};
