import { createLightTheme, FluentProvider, makeStaticStyles, makeStyles, themeToTokensObject } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';

export const pageWidth = 480;
export const headerHeight = 48;

const baseTheme = createLightTheme({
    '10': '#343446',
    '20': '#343446',
    '30': '#343446',
    '40': '#343446',
    '50': '#4b4b64',
    '60': '#4b4b64',
    '70': '#4b4b64',
    '80': '#4b4b64', // primary
    '90': '#9494a1',
    '100': '#9494a1',
    '110': '#9494a1',
    '120': '#9494a1',
    '130': '#9494a1',
    '140': '#9494a1',
    '150': '#9494a1',
    '160': '#9494a1',
});

export const theme = {
    ...baseTheme,

    fontFamilyBase: '"Raleway", sans-serif',
    fontSizeBase400: '15px',
    fontSizeBase500: '17px',
    fontSizeBase600: '22px',

    iconSizeTitle: '48px',

    colorNeutralForeground1: '#777384',
    colorNeutralForeground1Hover: '#777384',
    colorNeutralForeground1Pressed: '#777384',
    colorNeutralForeground1Selected: '#777384',

    colorNeutralForeground2: '#4b4b64',
    colorNeutralForeground2Hover: '#4b4b64',
    colorNeutralForeground2Pressed: '#4b4b64',
    colorNeutralForeground2Selected: '#4b4b64',

    colorNeutralForeground3: '#5d5d73',
    colorNeutralForeground3Hover: '#5d5d73',
    colorNeutralForeground3Pressed: '#5d5d73',
    colorNeutralForeground3Selected: '#5d5d73',

    colorNeutralForeground4: '#B7B7C1',

    colorNeutralStroke1: '#C9C9D1',
    colorNeutralStroke1Hover: '#C9C9D1',
    colorNeutralStroke1Pressed: '#C9C9D1',
    colorNeutralStroke1Selected: '#4B4B64',

    colorNeutralStrokeAccessiblePressed: '#C9C9D1',

    colorNeutralBackgroundInverted: '#252a31',

    colorBrandBackground: '#49c297',
    colorBrandBackgroundHover: '#419f8e',
    colorBrandBackgroundPressed: '#419f8e',
    colorBrandBackgroundSelected: '#419f8e',

    customPageWidth: `${pageWidth}px`,
    customHeaderHeight: `${headerHeight}px`,
    customHeaderPadding: 'env(safe-area-inset-top, 0px)',
    customFooterPadding: 'env(safe-area-inset-bottom, 0px)',

    customPaletteBlack: '#343446',

    customPaletteGreen: '#49c297',
    customPaletteGreenDark: '#419f8e',

    customPaletteBlue: '#4396b3',
    customPaletteBlueDark: '#39697a',
    customPaletteBlueLight: '#e6eff2',

    customPaletteYellow: '#eeca3d',
    customPaletteYellowDark: '#d6a016',
    customPaletteYellowLight: '#fdf9e8',

    customPaletteRed: '#c8102e',
    customPaletteRedDark: '#920c22',
    customPaletteRedLight: '#fbece9',
};

export const breakpoints = {
    /** Small devices such as large phones or tablet in split view mode (480px and up) */
    small: '480px', // 30rem
    /** Medium devices such as tablets in portrait orientation (768px and up) */
    medium: '768px', // 48rem
    /** Large devices such as tablets in landscape orientation (992 and up) */
    large: '992px', // 62rem
    /** Largest devices such as laptops or desktops (1200px and up) */
    extraLarge: '1200px', // 75rem
};

export const tokens = themeToTokensObject(theme);

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const classes = useStyles();
    useGlobalStyles();

    return (
        <FluentProvider theme={theme} className={classes.container} applyStylesToPortals={false}>
            {children}
        </FluentProvider>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'contents', // don't consider this element for layouting
    },
});

export const useGlobalStyles = makeStaticStyles({
    html: {
        backgroundColor: theme.colorNeutralBackground1,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100dvh',
        margin: 0,
        fontFamily: theme.fontFamilyBase,
        fontVariant: 'lining-nums',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
    },
    '#root': {
        // don't consider this element for layouting
        display: 'contents',
    },
});
