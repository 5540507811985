/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, FieldProps, InputProps } from '@fluentui/react-components';
import { Controller } from 'react-hook-form';

import { ControllerFieldProps, validate } from '@zastrpay/common';

import { Input } from './Input';

export type InputFieldProps = InputProps & Pick<FieldProps, 'hint' | 'label'>;

export const InputField: React.FC<InputFieldProps & ControllerFieldProps> = ({
    control,
    rules,
    className,
    label,
    hint,
    defaultValue,
    ...props
}) => {
    return (
        <Controller
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Field label={label} hint={hint} {...validate(fieldState)} className={className}>
                    <Input
                        {...props}
                        {...field}
                        className={props.input?.className}
                        onChange={(e) => field.onChange(e.target.value || null)}
                        value={field.value ?? ''}
                    />
                </Field>
            )}
        />
    );
};
