import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Logo, theme, tokens } from '@zastrpay/theme';

export type HeaderProps = { left?: React.ReactNode; right?: React.ReactNode; className?: string };

export const Header: React.FC<HeaderProps> = (props) => {
    const classes = useStyles();

    return (
        <HelmetProvider>
            <Helmet>
                <meta name="theme-color" content={theme.colorNeutralBackground1} />
            </Helmet>
            <div className={mergeClasses(classes.wrapper, props.className)}>
                <div className={classes.header}>
                    <div className={mergeClasses(classes.action, classes.actionLeft)}>{props.left}</div>
                    <div className={classes.title}>
                        <Logo className={classes.logo} />
                    </div>
                    <div className={mergeClasses(classes.action, classes.actionRight)}>{props.right}</div>
                </div>
            </div>
        </HelmetProvider>
    );
};

const useStyles = makeStyles({
    wrapper: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    header: {
        width: '100%',
        maxWidth: tokens.customPageWidth,
        height: tokens.customHeaderHeight,
        display: 'grid',
        gridTemplateColumns: '1fr 144px 1fr',
        alignItems: 'center',
        margin: '0 auto',
    },
    title: {
        color: tokens.colorNeutralBackground1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1',
    },
    action: {
        padding: `0 ${tokens.spacingHorizontalS}`,
    },
    actionRight: {
        justifySelf: 'end',
    },
    actionLeft: {
        justifySelf: 'start',
    },
    logo: {
        width: '128px',
    },
});
