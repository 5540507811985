import { Component, createContext, PropsWithChildren, useContext } from 'react';

import { isAuthenticationError, isNetworkError } from '@zastrpay/common';

export type ErrorBoundaryProps = {
    fallback?: React.FC<{ error: unknown; reset: () => void }>;
    log?: (error: Error) => void;
};

type State = {
    error?: unknown;
};

const shouldThrow = (errorType: string): boolean => {
    return errorType !== 'FailedToNegotiateWithServerError';
};

export const shouldTrack = (error: unknown): boolean => {
    // Those errors come from recaptcha running into timeouts in the background
    if (typeof error === 'string' && /^(Timeout|Timeout \(\w+\))$/.test(error)) {
        return false;
    }

    return !isNetworkError(error) && !isAuthenticationError(error);
};

export type ErrorBoundaryContext = {
    trackError: (error: unknown) => void;
};

const Context = createContext<ErrorBoundaryContext | null>(null);

export class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>, State> {
    public state: State = {};

    private handlePromiseError = (event: PromiseRejectionEvent): void => {
        if (shouldThrow(event.reason?.errorType)) {
            this.componentDidCatch(event.reason);
        }
    };

    private trackError = (error: unknown): void => {
        if (error instanceof Error) {
            this.props.log?.(error);
        } else if (typeof error === 'string') {
            this.props.log?.(new Error(error));
        } else {
            this.props.log?.(new Error(JSON.stringify(error)));
        }
    };

    private resetError = (): void => {
        this.setState({ error: null });
    };

    componentDidMount(): void {
        // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
        window.addEventListener('unhandledrejection', this.handlePromiseError);
    }

    componentWillUnmount(): void {
        window.removeEventListener('unhandledrejection', this.handlePromiseError);
    }

    public static getDerivedStateFromError(error: unknown): State {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    public componentDidCatch(error: unknown): void {
        if (shouldTrack(error)) {
            this.trackError(error);
        } else {
            console.error('Uncaught & untracked error:', error);
        }

        this.setState({ error });
    }

    public render(): JSX.Element {
        return (
            <Context.Provider value={{ trackError: this.trackError }}>
                {this.state.error ? this.props.fallback?.({ error: this.state.error, reset: this.resetError }) : this.props.children}
            </Context.Provider>
        );
    }
}

export const useErrorBoundary = (): ErrorBoundaryContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useErrorBoundary must be used within an ErrorBoundary');
    }

    return context;
};
