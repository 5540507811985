import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';

import { tokens } from '@zastrpay/theme';

export type PageProps = {
    align?: 'left' | 'center' | 'right' | 'fill';
    className?: string;
};

export const Page: React.FC<PropsWithChildren<PageProps>> = (props) => {
    const classes = useStyles();
    const align = props.align ?? 'center';

    return (
        <section
            className={mergeClasses(
                classes.container,
                props.className,
                align === 'center' && classes.alignCenter,
                align === 'left' && classes.alignLeft,
                align === 'right' && classes.alignRight,
                align === 'fill' && classes.alignFill,
            )}
        >
            {props.children}
        </section>
    );
};

const useStyles = makeStyles({
    container: {
        width: '100%',
        maxWidth: tokens.customPageWidth,
        boxSizing: 'border-box',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        padding: tokens.spacingVerticalXXL,
        margin: '0 auto',
        gap: tokens.spacingVerticalL,
    },
    alignLeft: {
        alignItems: 'left',
    },
    alignCenter: {
        alignItems: 'center',
    },
    alignRight: {
        alignItems: 'right',
    },
    alignFill: {
        alignItems: 'stretch',
    },
});
