import { ShieldKeyholeRegular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { Body, Dialog, DialogProps } from '@zastrpay/components';

export type TrustedBeneficiaryDialogProps = Omit<DialogProps, 'title' | 'icon' | 'align'> & {
    merchant: string;
};

export const TrustedBeneficiaryDialog: React.FC<TrustedBeneficiaryDialogProps> = (props) => {
    const { t } = useTranslation('analytics');

    return (
        <Dialog
            icon={<ShieldKeyholeRegular />}
            title={t('legal.trustedBeneficiary.title')}
            open={props.open}
            align="bottom"
            onOpenChange={props.onOpenChange}
        >
            <Body block>{t('legal.trustedBeneficiary.message', { merchant: props.merchant })}</Body>
        </Dialog>
    );
};
