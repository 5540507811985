import { v4 as uuid } from 'uuid';

import { api, sanitize } from '@zastrpay/common';

import { CUSTOMER_AUTHENTICATION_CMDH } from './config';
import { CustomerSessionScope, NewOtp, OtpAuthFactor, Pin, VerifyCaptchaResponse, VerifyOtpResponse } from './models';

export const createPhoneOtpAuthFactor = (phoneNumber: string, flowId?: string): Promise<OtpAuthFactor> =>
    api.put<OtpAuthFactor>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/otp-auth-factors/${uuid()}`,
        sanitize({
            type: 'Phone',
            contact: phoneNumber,
            flowId: flowId,
        }),
    );

export const createEmailOtpAuthFactor = (email: string, flowId?: string): Promise<OtpAuthFactor> =>
    api.put<OtpAuthFactor>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/otp-auth-factors/${uuid()}`,
        sanitize({
            type: 'Email',
            contact: email,
            flowId,
        }),
    );

export const generateOtp = (otpAuthFactorId: string, locale: string, flowId?: string): Promise<NewOtp> =>
    api.put<NewOtp>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/otps/${uuid()}`,
        sanitize({
            otpAuthFactorId,
            locale: locale.substring(0, 2),
            flowId,
        }),
    );

export const generatePhoneOtp = (phoneNumber: string, locale: string, flowId?: string): Promise<NewOtp> =>
    api.put<NewOtp>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/otps/${uuid()}`,
        sanitize({
            type: 'Phone',
            contact: phoneNumber,
            locale: locale.substring(0, 2),
            flowId,
        }),
    );

export const generateEmailOtp = (email: string | undefined, locale: string, flowId?: string): Promise<NewOtp> =>
    api.put<NewOtp>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/otps/${uuid()}`,
        sanitize({
            type: 'Email',
            contact: email,
            locale: locale.substring(0, 2),
            flowId,
        }),
    );

export const getOtpAuthFactor = (id: string): Promise<OtpAuthFactor> =>
    api.get<OtpAuthFactor>(`${CUSTOMER_AUTHENTICATION_CMDH}/otp-auth-factors/${id}`);

export const verifyOtp = (id: string, code: string, flowId?: string): Promise<VerifyOtpResponse> =>
    api.post<VerifyOtpResponse>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/otps/${id}/verify`,
        sanitize({
            code,
            flowId,
        }),
    );

export const createPin = (id: string, pin: string, flowId?: string): Promise<Pin> =>
    api.put<Pin>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/pins/${id}`,
        sanitize({
            pin,
            flowId,
        }),
    );

export const changePin = (newPin: string): Promise<Pin> =>
    api.post<Pin>(`${CUSTOMER_AUTHENTICATION_CMDH}/pins/change`, sanitize({ newPin }));

// TODO: decommission it after merging backend logic of /pins/verify and pins/{pinId}/verify
export const verifyPinByPhoneNumber = async (phoneNumber: string, pin: string, flowId?: string): Promise<VerifyOtpResponse> =>
    api.post<VerifyOtpResponse>(`${CUSTOMER_AUTHENTICATION_CMDH}/pins/verify`, sanitize({ phoneNumber, pin, flowId }));

export const verifyPinByEmail = async (email: string, pin: string, flowId?: string): Promise<VerifyOtpResponse> =>
    api.post<VerifyOtpResponse>(`${CUSTOMER_AUTHENTICATION_CMDH}/pins/verify`, sanitize({ email, pin, flowId }));

export const verifyPinByCustomerId = async (pin: string, flowId?: string): Promise<VerifyOtpResponse> =>
    api.post<VerifyOtpResponse>(`${CUSTOMER_AUTHENTICATION_CMDH}/pins/verify`, sanitize({ pin, flowId }));

export const verifyPinById = async (pin: string, pinId: string, flowId?: string): Promise<VerifyOtpResponse> =>
    api.post<VerifyOtpResponse>(`${CUSTOMER_AUTHENTICATION_CMDH}/pins/${pinId}/verify`, sanitize({ pin, flowId }));

export const startCustomerSessionByPhone = (
    phoneNumber: string,
    type: CustomerSessionScope,
    captchaToken: string,
): Promise<VerifyCaptchaResponse> =>
    api.post<VerifyCaptchaResponse>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/customer-sessions`,
        sanitize({
            phoneNumber,
            captchaToken,
            type,
        }),
        {
            headerOptions: {
                addAuthentication: false,
            },
        },
    );

export const startCustomerSessionByEmail = (
    email: string,
    type: CustomerSessionScope,
    captchaToken: string,
): Promise<VerifyCaptchaResponse> =>
    api.post<VerifyCaptchaResponse>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/customer-sessions`,
        sanitize({
            email,
            captchaToken,
            type,
        }),
        {
            headerOptions: {
                addAuthentication: false,
            },
        },
    );

export const refreshToken = (redirectSessionId: string, phoneNumber: string): Promise<VerifyCaptchaResponse> =>
    api.post<VerifyCaptchaResponse>(
        `${CUSTOMER_AUTHENTICATION_CMDH}/redirect-sessions/${redirectSessionId}/refresh-token`,
        sanitize({
            phoneNumber,
        }),
    );
