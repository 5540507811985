import { FieldProps } from '@fluentui/react-field';
import { Control, ControllerFieldState, FieldPath, FieldValues, FormState, get, RegisterOptions } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ControlRules<TModel extends FieldValues, TProperty extends FieldPath<TModel> = any> = Omit<
    RegisterOptions<TModel, TProperty>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;

export type ControllerFieldProps<TModel extends FieldValues = FieldValues, TProperty extends FieldPath<TModel> = FieldPath<TModel>> = {
    name: string;
    control?: Control<FieldValues, object>;
    rules?: ControlRules<TModel, TProperty>;
};

export const register = <TModel extends FieldValues, TProperty extends FieldPath<TModel>>(
    control: Control<TModel, object>,
    name: TProperty,
    rules?: ControlRules<TModel, TProperty>,
): ControllerFieldProps => {
    return {
        name: name.toString(),
        control: control as Control<FieldValues, object>,
        rules: rules as ControlRules<FieldValues>,
    };
};

// TODO: investigate api approach as the type is required on array append
export const empty = <T>(): T => {
    return {} as T;
};

export type ValidationProps = Pick<FieldProps, 'validationState' | 'validationMessage' | 'validationMessageIcon'>;

export function validate(state: ControllerFieldState): ValidationProps;
export function validate<TModel extends FieldValues, TProperty extends FieldPath<TModel>>(
    state: FormState<TModel>,
    name: TProperty,
): ValidationProps;
// eslint-disable-next-line func-style
export function validate<TModel extends FieldValues, TProperty extends FieldPath<TModel>>(
    state: FormState<TModel> | ControllerFieldState,
    name?: TProperty,
): ValidationProps {
    const error = 'errors' in state ? get(state.errors, name) : state.error;

    if (error) {
        return {
            validationState: 'error',
            // it is a component slot, so if no message we want null so the component is not rendered empty
            validationMessage: error.message || null,
            // it is a component slot, so if no message we want null so the component is not rendered empty
            // undefined allows default behavior
            validationMessageIcon: error.message ? undefined : null,
        };
    }

    return {
        validationState: 'none',
        validationMessage: null,
        validationMessageIcon: null,
    };
}
