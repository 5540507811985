import { v4 as uuid } from 'uuid';

import { api, Serialized } from '@zastrpay/common';

import { endpoints } from '../config';
import { CustomerRedirectedRedirectSession, RedirectSession } from './models';

const deserializeRedirectSession = (redirectSession: Serialized<RedirectSession>): RedirectSession => {
    return {
        ...redirectSession,
        createdOn: new Date(redirectSession.createdOn),
    };
};

const deserializeCustomerRedirectedRedirectSession = (
    redirectSession: Serialized<CustomerRedirectedRedirectSession>,
): CustomerRedirectedRedirectSession => {
    return {
        ...redirectSession,
        createdOn: new Date(redirectSession.createdOn),
    };
};

export const get = async (redirectSessionId: string): Promise<RedirectSession> =>
    api.get<RedirectSession>(`${endpoints.custAuthQryh}/redirect-sessions/${redirectSessionId}`).then(deserializeRedirectSession);

export const exchangeToken = async (redirectSessionId: string): Promise<CustomerRedirectedRedirectSession> =>
    api
        .post<CustomerRedirectedRedirectSession>(
            `${endpoints.custAuthCmdh}/redirect-sessions/${redirectSessionId}/exchange-token`,
            undefined,
            { headers: { 'x-request-id': redirectSessionId } },
        )
        .then(deserializeCustomerRedirectedRedirectSession);

export const cancel = async (redirectSessionId: string): Promise<void> => {
    await api.post<RedirectSession>(`${endpoints.custAuthCmdh}/redirect-sessions/${redirectSessionId}/cancel`, undefined, {
        // NOTE: don't just reuse the redirectSessionId as the x-request-id as we already used that for /exchange-token
        // which will result in this returning 200 OK (because of idempotency) but not actually cancelling the session
        headers: { 'x-request-id': uuid() },
    });
};
