import { Body1, makeStyles } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Account, get as getAccount } from '@zastrpay/accounts';
import { trackClick, trackPage } from '@zastrpay/analytics';
import { numberUtil, register } from '@zastrpay/common';
import { Button, FormattedCurrency, InputField, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { CreateTransactionIntent, TransactionIntentType } from './models';

export type TransactionIntentAmountProps = {
    onBack?: () => void;
    onComplete?: (intent: CreateTransactionIntent) => void;
    intentType: Extract<TransactionIntentType, 'CustomerWithdrawal' | 'CustomerDeposit'>;
    customerId: string;
};

const preselectAmounts = [10, 25, 50, 75, 100];

const maxDepositAmount = 10000;

export const TransactionIntentAmount: React.FC<TransactionIntentAmountProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('transaction-intents');
    const [account, setAccount] = useState<Account>();

    useEffect(() => {
        trackPage('transaction_intent_amount_input', { trx_int_type: props.intentType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { handleSubmit, setValue, control, trigger, watch } = useForm<CreateTransactionIntent>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: { type: props.intentType, customerId: props.customerId, currency: 'EUR' },
    });

    const submit = handleSubmit((data) => {
        trackClick('transaction_intent_amount_input', 'submit', { trx_int_type: props.intentType });

        data.amount = Number(data.amount); //TODO: generalize this in input component
        props.onComplete?.(data);
    });

    const amount = watch('amount', 0);

    useEffect(() => {
        getAccount(props.customerId).then((account) => setAccount(account));
    }, [props.customerId]);

    return (
        <Page className={classes.page}>
            <article className={classes.title}>
                <Title>{t('amount.title', { context: props.intentType })}</Title>
                <Body1>{t('amount.subTitle', { context: props.intentType })}</Body1>
            </article>
            <div className={classes.content}>
                <InputField
                    label={t('amount.label')}
                    className={classes.input}
                    type="number"
                    inputMode="decimal"
                    contentAfter={account?.currency}
                    max={props.intentType === 'CustomerWithdrawal' ? account?.balance : maxDepositAmount}
                    min={0}
                    input={{ className: classes.input }}
                    {...register(control, 'amount', {
                        required: t('amount.required'),
                        max: {
                            value: props.intentType === 'CustomerWithdrawal' ? (account?.balance ?? 0) : maxDepositAmount,
                            message:
                                props.intentType === 'CustomerWithdrawal'
                                    ? t('amount.balanceAmountExceeded')
                                    : t('amount.maxDepositAmountExceeded', {
                                          maxDepositAmount: numberUtil.formatCurrency(maxDepositAmount, 'EUR', 'currency'),
                                      }),
                        },
                        validate: {
                            greaterThanZero: (value) => Number(value) > 0 || t('amount.minAmountValueBelowZero'),
                        },
                    })}
                />

                <div className={classes.buttonContainer}>
                    {preselectAmounts.map((amount) => (
                        <Button
                            key={amount}
                            className={classes.button}
                            appearance="secondary"
                            disabled={
                                (props.intentType === 'CustomerDeposit' && amount > maxDepositAmount) ||
                                (props.intentType === 'CustomerWithdrawal' && amount > (account?.balance ?? 0))
                            }
                            onClick={() => {
                                setValue('amount', amount);
                                trigger('amount');
                            }}
                        >
                            {amount}
                        </Button>
                    ))}
                </div>
                <Body1>
                    <Trans
                        t={t}
                        i18nKey="amount.balance"
                        components={{
                            amount: <FormattedCurrency currency={account?.currency} value={account?.balance} format="currency" />,
                        }}
                    />
                </Body1>
                <Button appearance="primary" onClick={submit} size="large" disabled={Number(amount) <= 0}>
                    {t('amount.submit')}
                </Button>
            </div>
        </Page>
    );
};

const useStyles = makeStyles({
    page: {
        gap: tokens.spacingHorizontalXXXL,
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        gap: tokens.spacingVerticalS,
    },
    subtitle: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        gap: tokens.spacingVerticalS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: tokens.spacingVerticalXXL,
    },
    icon: {
        fontSize: tokens.fontSizeHero900,
    },
    info: {
        marginTop: tokens.spacingVerticalXS,
        width: '100%',
        textAlign: 'center',
    },
    input: {
        alignSelf: 'stretch',
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightBold,
        padding: tokens.spacingVerticalS,
    },
    buttonContainer: {
        marginLeft: tokens.spacingHorizontalL,
        marginRight: tokens.spacingHorizontalL,
        display: 'flex',
        justifyContent: 'center',
        gap: tokens.spacingHorizontalM,
    },
    button: {
        maxHeight: '40px',
        minHeight: '40px',
        maxWidth: '50px',
        minWidth: '50px',
        backgroundColor: tokens.colorNeutralBackground2,
    },
});
