/* eslint-disable no-restricted-imports */
import { Input as FuiInput, InputProps as FuiInputProps, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { forwardRef } from 'react';

import { tokens } from '@zastrpay/theme';

export const Input = forwardRef<HTMLInputElement, FuiInputProps>(({ className, input, ...props }, ref) => {
    const classes = useStyles();

    return (
        <FuiInput
            {...props}
            ref={ref}
            input={{
                ...input,
                className: mergeClasses(input?.className, classes.innerInput),
            }}
            className={mergeClasses(className, classes.input, props.disabled && classes.disabled)}
        />
    );
});

const useStyles = makeStyles({
    input: {
        borderBottomColor: tokens.colorNeutralStroke1,
        ':hover': {
            borderBottomColor: tokens.colorNeutralStroke1,
        },
        ':focus-within': {
            ...shorthands.borderColor(`${tokens.colorNeutralStroke1Selected} !important`),
            '::after': {
                content: 'none',
            },
        },
    },
    disabled: {
        backgroundColor: `${tokens.colorNeutralBackgroundDisabled} !important`,
    },
    innerInput: {
        fontVariant: 'lining-nums',
        '&[type="date"]': {
            // on iOS empty date inputs have height 0 (focus does not work)
            minHeight: '1.5rem',
            '&::-webkit-date-and-time-value': {
                // on iOS dates are always aligned in the center
                textAlign: 'left',
            },
        },
        ':disabled': {
            color: tokens.colorNeutralForeground1,
        },
    },
});
