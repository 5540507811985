import { makeStyles } from '@fluentui/react-components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackPage } from '@zastrpay/analytics';
import { ValidationError } from '@zastrpay/common';
import { Alert, Body, Button, ErrorTrans, Link, Phone, PHONE_REGEX, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

export type PhoneInputProps = {
    defaultValue?: string;
    error?: React.ReactElement;
    onChange?: (phone: string) => void;
    onResetPhone?: () => void;
    oldPhone?: boolean;
    className?: string;
};

export const PhoneInput: React.FC<PhoneInputProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation('auth-flow');

    const [phone, setPhone] = useState<string>();
    const [validationError, setValidationError] = useState<ValidationError<'PhoneRegex'>>();

    useEffect(() => {
        trackPage('phone_input');
    }, []);

    const verifyPhone = async () => {
        if (phone) {
            if (PHONE_REGEX.test(phone)) {
                setValidationError(undefined);
                props.onChange?.(phone);
            } else {
                setValidationError(new ValidationError('PhoneRegex'));
            }
        }
    };

    const [title, subTitle, action] = useMemo(() => {
        return props.oldPhone
            ? [t('changePhone.oldPhone.title'), t('changePhone.oldPhone.subTitle'), t('changePhone.oldPhone.next')]
            : [t('changePhone.newPhone.title'), t('changePhone.newPhone.subTitle'), t('changePhone.newPhone.next')];
    }, [props.oldPhone, t]);

    return (
        <Page className={props?.className}>
            <Title>{title}</Title>
            <Body>{subTitle}</Body>

            <div className={classes.phoneContainer}>
                <Phone defaultValue={props.defaultValue} onChange={setPhone} />
                {validationError && (
                    <Alert type="error">
                        <ErrorTrans t={t} i18nKey="changePhone.error.validation" error={validationError} />
                    </Alert>
                )}
                {!validationError && props.error && <Alert type="error">{props.error}</Alert>}
            </div>

            <Button appearance="primary" size="large" onClick={verifyPhone} disabled={!phone}>
                {action}
            </Button>
            {props.onResetPhone && (
                <Link className={classes.link} onClick={props.onResetPhone}>
                    {t('changePhone.oldPhone.noAccess')}
                </Link>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    phoneContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        marginBottom: tokens.spacingVerticalM,
        gap: tokens.spacingHorizontalXS,
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
        textAlign: 'center',
    },
});
