import { Combobox, makeStyles, Option, shorthands, Spinner } from '@fluentui/react-components';
import { SearchRegular } from '@fluentui/react-icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '@zastrpay/hooks';
import { tokens } from '@zastrpay/theme';

import { SearchAddress } from './models';

type SearchProps = {
    autoFocus?: boolean;
    manualEntry?: boolean;
    onSearch: (text: string) => Promise<SearchAddress[]>;
    onSelected?: (value?: SearchAddress) => void;
};

export const AddressSearch: React.FC<SearchProps> = ({ onSearch, onSelected, manualEntry, ...props }) => {
    const { t } = useTranslation('pages');
    const classes = useStyles();
    const ref = useRef<HTMLInputElement>(null);

    const [options, setOptions] = useState<SearchAddress[]>();
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const handleSearch = useDebounce(async (text: string) => {
        const searchResult = await onSearch(text);
        setOptions(searchResult);
        setIsSearching(false);
    }, 500);

    const handleChange = (value: string) => {
        if (value.length) {
            setIsSearching(true);
            setOptions([]);
        }

        setSearchValue(value);
        handleSearch(value);
    };

    const handleOptionSelect = (optionValue?: string) => {
        const option = options?.find((o) => o.id === optionValue);

        if (option) {
            setSearchValue(`${option.streetAddress}, ` + `${option.postalCode ?? ''} ${option.city ?? ''}`.trim());
            // position curser at the end of the streetAddress
            setTimeout(() => ref.current?.setSelectionRange(option.streetAddress.length, option.streetAddress.length));
        }

        onSelected?.(option);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        // scroll to top on focus to make more space for dropdown options on mobile devices
        const offset = e.currentTarget.getBoundingClientRect().top + window.scrollY;
        setTimeout(() => document.scrollingElement?.scrollTo({ top: offset - 5, behavior: 'smooth' }));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setTimeout(() => {
            // if focus is still in the input field (e.g. if only street was selected) don't clear
            if (e.target !== document.activeElement) {
                setSearchValue('');
                setOptions(undefined);
            }
        }, 100);
    };

    return (
        <Combobox
            {...props}
            ref={ref}
            value={searchValue}
            selectedOptions={[]}
            placeholder={t('address.search.placeholder')}
            onChange={(event) => handleChange(event.currentTarget.value)}
            onOptionSelect={(_, data) => handleOptionSelect(data?.optionValue)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            freeform
            autoComplete="nope"
            size="large"
            className={classes.input}
            listbox={{ className: classes.listbox }}
            expandIcon={<SearchRegular />}
        >
            {isSearching && (
                <Option disabled className={classes.optionDisabled} text={t('address.search.loading')}>
                    <Spinner size="tiny" label={t('address.search.loading')} />
                </Option>
            )}
            {!isSearching &&
                options?.map((option) => (
                    <Option key={option.id} value={option.id} className={classes.option} data-clarity-mask="true">
                        {`${option.streetAddress}, ` + `${option.postalCode ?? ''} ${option.city ?? ''}`.trim()}
                    </Option>
                ))}
            {!isSearching && options && !options.length && (
                <Option disabled className={classes.optionDisabled}>
                    {t('address.search.noResults')}
                </Option>
            )}
            {!isSearching && manualEntry && options && (
                <Option style={{ color: tokens.customPaletteBlue }}>{t('address.search.manualEntry')}</Option>
            )}
        </Combobox>
    );
};

const useStyles = makeStyles({
    input: {
        borderBottomColor: tokens.colorNeutralStroke1,
        ':hover': {
            borderBottomColor: tokens.colorNeutralStroke1,
        },
        ':focus-within': {
            ...shorthands.borderColor(`${tokens.colorNeutralStroke1Selected} !important`),
            '::after': {
                content: 'none',
            },
        },
    },
    option: {
        '&:after': {
            ...shorthands.borderColor('#EEE'),
        },
    },
    optionDisabled: {
        '&:after': {
            display: 'none',
        },
    },
    listbox: {
        '&:empty': {
            display: 'none',
        },
    },
});
