import { makeStyles, mergeClasses, Subtitle2 } from '@fluentui/react-components';
import { ErrorCircle20Regular, Info20Regular } from '@fluentui/react-icons';

import { tokens } from '@zastrpay/theme';

import { Body } from './Body';

export type AlertProps = React.PropsWithChildren & {
    className?: string;
    type: 'info' | 'error' | 'warning';
    title?: string;
};

export const Alert: React.FC<AlertProps> = (props) => {
    const classes = useStyles();

    return (
        <div
            className={mergeClasses(
                classes.alert,
                props.type === 'info' && classes.info,
                props.type === 'error' && classes.error,
                props.type === 'warning' && classes.warning,
                props.className,
            )}
        >
            {props.type === 'info' && <Info20Regular color={tokens.customPaletteBlue} className={classes.icon} />}
            {props.type === 'warning' && <Info20Regular color={tokens.customPaletteYellowDark} className={classes.icon} />}
            {props.type === 'error' && <ErrorCircle20Regular color={tokens.customPaletteRed} className={classes.icon} />}
            <div className={classes.body}>
                {props.title && <Subtitle2>{props.title}</Subtitle2>}
                <Body align="start">{props.children}</Body>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    alert: {
        borderRadius: tokens.borderRadiusMedium,
        padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalXL}`,
        margin: `${tokens.spacingVerticalS} 0`,
        gap: tokens.spacingHorizontalS,
        display: 'flex',
        alignSelf: 'stretch',
        alignItems: 'center',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        flexBasis: '20px',
        flexGrow: 0,
        flexShrink: 0,
    },
    info: {
        backgroundColor: tokens.customPaletteBlueLight,
    },
    error: {
        backgroundColor: tokens.customPaletteRedLight,
    },
    warning: {
        backgroundColor: tokens.customPaletteYellowLight,
    },
});
