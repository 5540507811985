import { makeStyles, Subtitle1 } from '@fluentui/react-components';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { Alert, Body, BulletList, Button, Link, Title } from '@zastrpay/components';
import { Page, SpinnerOverlay } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

const ShopListDialog = lazy(() => import('@zastrpay/shops').then((module) => ({ default: module.ShopListDialog })));

export type InformationPageProps = {
    note?: 'existingCustomer' | 'shops';
    onContinue?: () => void;
};

export const InformationPage: React.FC<InformationPageProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation('registration');

    useEffect(() => {
        trackPage('registration_info');
    }, []);

    const [shopsOpen, setShopsOpen] = useState(false);

    const findShops = () => {
        setShopsOpen(true);
    };

    return (
        <Page>
            <Title>{t('information.title')}</Title>

            {props.note === 'existingCustomer' && (
                <Alert type="info" title={t('information.note.existingCustomer.title')}>
                    {t('information.note.existingCustomer.message')}
                </Alert>
            )}

            <Body align="start" className={classes.listContainer}>
                <BulletList as="ol" size="large">
                    <li>
                        <Subtitle1 className={classes.listTitle}>{t('information.steps.pin')}</Subtitle1>
                    </li>
                    <li>
                        <Subtitle1 className={classes.listTitle}>{t('information.steps.data.title')}</Subtitle1>
                        <BulletList as="ul" size="small">
                            <li>{t('information.steps.data.personal')}</li>
                            <li>{t('information.steps.data.email')}</li>
                            <li>{t('information.steps.data.address')}</li>
                        </BulletList>
                    </li>
                    <li>
                        <Subtitle1 className={classes.listTitle}>{t('information.steps.finish.title')}</Subtitle1>
                        <Trans
                            t={t}
                            i18nKey="information.steps.finish.details"
                            components={{ shopLink: <Link inline className={classes.link} onClick={findShops} /> }}
                        />
                    </li>
                    {props.note === 'shops' && (
                        <Alert type="info" title={t('information.note.shops.title')}>
                            {t('information.note.shops.message')}
                        </Alert>
                    )}
                </BulletList>
            </Body>

            <Button
                appearance="primary"
                size="large"
                onClick={() => {
                    trackClick('registration_info', 'continue');

                    props.onContinue?.();
                }}
            >
                {t('information.submit')}
            </Button>

            <Suspense fallback={<SpinnerOverlay loading />}>
                {shopsOpen && <ShopListDialog open={shopsOpen} onOpenChange={setShopsOpen} />}
            </Suspense>
        </Page>
    );
};

const useStyles = makeStyles({
    listContainer: {
        alignSelf: 'stretch',
    },
    listTitle: {
        display: 'block',
        lineHeight: tokens.lineHeightBase400,

        '& + *': {
            display: 'inline-block',
            marginTop: '0.2rem',
        },
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
});
