import { CookiesRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';

import { Body, Dialog, Link } from '@zastrpay/components';

import { analyticsConfig } from './config';
import { CookieSettings } from './CookieSettings';
import { PrivacyPolicyDialog } from './legal/PrivacyPolicyDialog';
import { ConsentType, CookieConsent } from './model';

const consentCookieName = 'cookie_consent';

const getExpireDate = (date: Date) => {
    const expireDate = new Date(date);
    expireDate.setFullYear(expireDate.getFullYear() + 1);
    return expireDate;
};

export const useCookieConsent = () => {
    const [cookies, setCookie] = useCookies([consentCookieName]);
    const [cookieConsent, setConsents] = useState<CookieConsent>();
    const analyticsEnabled = analyticsConfig.enabled;

    useEffect(() => {
        if (!cookies.cookie_consent) {
            setConsents({ date: undefined, types: undefined, showBanner: true, submitted: false });
        } else {
            setConsents(cookies.cookie_consent);
        }
    }, [cookies]);

    const setSubmitted = () => {
        if (!cookieConsent || !cookies[consentCookieName]) return;

        cookieConsent.submitted = true;
        setCookie(consentCookieName, cookieConsent, {
            path: '/',
            expires: getExpireDate(cookies.cookie_consent.date),
        });
    };

    const openCookieBanner = () => {
        if (!cookieConsent || !cookies[consentCookieName]) return;

        cookieConsent.showBanner = true;
        setCookie(consentCookieName, cookieConsent, {
            path: '/',
            expires: getExpireDate(cookies.cookie_consent.date),
        });
    };

    return { cookieConsent, analyticsEnabled, setSubmitted, openCookieBanner };
};

export const CookieBanner: React.FC = () => {
    const [_, setCookie] = useCookies([consentCookieName]);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [privacyVisible, setPrivacyVisible] = useState(false);
    const { cookieConsent } = useCookieConsent();
    const [open, setOpen] = useState(false);

    const { t } = useTranslation('analytics');

    useEffect(() => {
        setOpen(cookieConsent?.showBanner ?? false);
    }, [cookieConsent]);

    const accept = (consentTypes: ConsentType[]) => {
        const consent: CookieConsent = {
            showBanner: false,
            types: consentTypes,
            date: new Date(),
            submitted: false,
        };

        setCookie(consentCookieName, consent, {
            path: '/',
            expires: getExpireDate(consent.date ?? new Date()),
        });
        setShowSettings(false);
        setOpen(false);
    };

    return (
        analyticsConfig.enabled && (
            <>
                <Dialog
                    modalType="non-modal"
                    open={open}
                    align="bottom"
                    dismissible={false}
                    title={!showSettings ? t('cookieConsent.banner.title') : t('cookieConsent.settings.title')}
                    icon={!showSettings && <CookiesRegular />}
                    actions={
                        !showSettings && [
                            { text: t('cookieConsent.banner.manage'), onClick: () => setShowSettings(true) },
                            {
                                text: t('cookieConsent.banner.acceptAll'),
                                style: 'preferred',
                                onClick: () => accept(['Essential', 'Analytic']),
                            },
                        ]
                    }
                >
                    {!showSettings ? (
                        <Body block>
                            <Trans
                                t={t}
                                i18nKey="cookieConsent.banner.description"
                                components={{
                                    policyLink: <Link inline onClick={() => setPrivacyVisible(true)} />,
                                }}
                            />
                        </Body>
                    ) : (
                        <CookieSettings
                            consents={cookieConsent?.types}
                            onAccept={accept}
                            onOpenPrivacyPolicy={() => setPrivacyVisible(true)}
                        />
                    )}
                </Dialog>
                <PrivacyPolicyDialog open={privacyVisible} onOpenChange={setPrivacyVisible} />
            </>
        )
    );
};
