import { Namespace, ParseKeys, TFunction, TOptions, TypeOptions } from 'i18next';
import { useMemo } from 'react';
import React from 'react';
import { KeysWithoutContext, Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TranslatableError } from '@zastrpay/common';

import { useErrorBoundary } from './ErrorBoundary';
import { Link } from './Link';

export type ErrorTransProps<
    Key extends KeysWithoutContext<ParseKeys<Ns, TOpt, KPrefix>>,
    Ns extends Namespace = TypeOptions['defaultNS'],
    KPrefix = undefined,
    TOpt extends TOptions = {},
> = {
    error: TranslatableError<Key | string>; // string is only to not force typing to much
    t: TFunction<Ns, undefined>;
    /** Translation key, default key is `error.defaultTranslationKey` */
    i18nKey?: Key;
    /** Additional custom tag replacements for the translation, default replacements are `<supportLink>` and `<zastrpayLink>` */
    components?: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement };
    /** Additional translation placeholder values, default placeholders are `error.translationPlaceholders` */
    values?: Record<string, unknown>;
    /** Additional context added to `error.translationContext` */
    additionalContext?: string;
};

export const ErrorTrans = <
    Key extends KeysWithoutContext<ParseKeys<Ns, TOpt, KPrefix>>,
    Ns extends Namespace = TypeOptions['defaultNS'],
    KPrefix = undefined,
    TOpt extends TOptions = {},
>({
    error,
    i18nKey,
    t,
    components,
    values: transValues,
    additionalContext,
}: ErrorTransProps<Key, Ns, KPrefix, TOpt>): React.ReactNode => {
    const { trackError } = useErrorBoundary();
    const navigate = useNavigate();

    const { i18n } = useTranslation(undefined);

    const [key, context, values] = useMemo(() => {
        if (error instanceof TranslatableError) {
            const key = (i18nKey ?? error.defaultTranslationKey) as never;
            const context = error.translationContext;
            const values = { ...error.translationPlaceholders, ...transValues };

            // check if additional context translation exists without logging missing key error
            // ...t extracts the namespace and other additional options from the t function
            if (additionalContext && i18n.exists(key, { context: `${context}_${additionalContext}`, ...t })) {
                return [key, `${context}_${additionalContext}`, values];
            }

            // try to translate to get missing key error logged and through default value check if the key exists
            if (t(key, { context, defaultValue: '' })) {
                return [key, context, values];
            }
        } else {
            // only track error if it is not a TranslatableError
            trackError(error);
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, i18nKey]);

    return key ? (
        <Trans
            t={t}
            i18nKey={key as never}
            context={context}
            values={values}
            components={{
                supportLink: <Link inline onClick={() => navigate('/support')} />,
                zastrpayLink: <Link inline onClick={() => window.open('https://www.zastrpay.com', '_blank')} />,
                ...components,
            }}
        />
    ) : (
        <Trans
            i18nKey={'common:error.general' as never}
            components={{
                supportLink: <Link inline onClick={() => navigate('/support')} />,
            }}
        />
    );
};
