import { Currency } from '@zastrpay/components';

import { idRequirementThreshold } from './config';

export type TransactionIntentType =
    | 'PassthroughDeposit'
    | 'PassthroughWithdrawal'
    | 'CustomerDeposit'
    | 'CustomerWithdrawal'
    | 'CustomerToMerchantPassthrough'
    | 'MerchantToCustomerPassthrough';

export type CreateTransactionIntentType = TransactionIntentType;

export type CreateTransactionIntent = {
    type: CreateTransactionIntentType;
    amount: number;
    currency: Currency;
    customerId: string;
    merchantId?: string;
    externalReference?: string;
    locale?: string;
};

export type ApproveTransactionIntent = {
    customerId: string;
    approvalCode: string;
};

export type TransactionIntentState = 'Created' | 'PendingApproval' | 'Declined' | 'Pending' | 'Expired' | 'Cancelled' | 'Finalized';

export type TransactionIntentFinalizeReason = 'TransactionCompleted' | 'TransactionDeclined' | 'TransactionCancelled';

export type TransactionIntentStateDetails = {
    finalizeReason?: TransactionIntentFinalizeReason;
};

export type TransactionIntent = {
    id: string;
    type: TransactionIntentType;
    // code?: string;
    amount: number;
    currency: Currency;
    customerId: string;
    merchantId?: string;
    // externalReference?: string;
    createdOn: Date;
    state: TransactionIntentState;
    stateDetails?: TransactionIntentStateDetails;
    locale?: string;
};

export const isIdRequired = (type: TransactionIntentType | undefined, amount: number) => {
    return type === 'PassthroughWithdrawal' && amount >= idRequirementThreshold;
};

export const isTransactionIntentType = (type: string | undefined): type is TransactionIntentType => {
    // cast for exhaustiveness check
    switch (type as TransactionIntentType) {
        case 'PassthroughDeposit':
        case 'PassthroughWithdrawal':
        case 'CustomerDeposit':
        case 'CustomerWithdrawal':
        case 'CustomerToMerchantPassthrough':
        case 'MerchantToCustomerPassthrough':
            return true;
    }
};
