import { numberUtil } from '@zastrpay/common';

export type Currency = 'EUR';

export type CurrencyFormat = 'currency' | 'sign';

export type FormattedCurrencyProps = {
    value?: number;
    currency?: Currency;
    format?: CurrencyFormat;
    className?: string;
};

export const FormattedCurrency: React.FC<FormattedCurrencyProps> = (props) => {
    return (
        <span className={props.className} data-clarity-mask="true">
            {numberUtil.formatCurrency(props.value, props.currency, props.format)}
        </span>
    );
};
