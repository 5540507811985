import { makeStyles, Subtitle1 } from '@fluentui/react-components';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { Alert, Body, BulletList, Button, Link, Title } from '@zastrpay/components';
import { Page, SpinnerOverlay } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { TransactionIntentType } from './models';

const ShopListDialog = lazy(() => import('@zastrpay/shops').then((module) => ({ default: module.ShopListDialog })));

export type TransactionIntentInformationProps = {
    transactionIntentType: TransactionIntentType;
    idRequired: boolean;
    onContinue?: () => void;
};

export const TransactionIntentInformation: React.FC<TransactionIntentInformationProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation('transaction-intents');

    const [shopsOpen, setShopsOpen] = useState(false);

    const findShops = () => {
        trackClick('qr_code_info', 'find_shops');
        setShopsOpen(true);
    };

    useEffect(() => {
        trackPage('qr_code_info');
    }, []);

    return (
        <Page>
            <Title>{t('information.title')}</Title>

            <Body align="start" className={classes.listContainer}>
                <BulletList as="ol" size="large">
                    <li>
                        <Subtitle1 className={classes.listTitle}>{t('information.steps.findShopTitle')}</Subtitle1>
                        <span>
                            <Trans
                                t={t}
                                i18nKey="information.steps.findShop"
                                components={{
                                    shopLink: <Link inline className={classes.link} onClick={findShops} />,
                                }}
                            />
                        </span>
                    </li>
                    <li>
                        <Subtitle1 className={classes.listTitle}>{t('information.steps.showCodeTitle')}</Subtitle1>
                        <span>{t('information.steps.showCode')}</span>
                    </li>
                    <Alert type="info">{t('information.steps.showCodeHint')}</Alert>
                    {props.idRequired && (
                        <li>
                            <Subtitle1 className={classes.listTitle}>{t('information.steps.showIdTitle')}</Subtitle1>
                            <span>{t('information.steps.showId')}</span>
                        </li>
                    )}
                    <li>
                        <Subtitle1 className={classes.listTitle}>
                            {t('information.steps.completeTitle', { context: props.transactionIntentType })}
                        </Subtitle1>
                    </li>
                </BulletList>
            </Body>

            <Button
                appearance="primary"
                size="large"
                onClick={() => {
                    trackClick('qr_code_info', 'generate_qr_code', { trx_int_type: props.transactionIntentType });
                    props.onContinue?.();
                }}
            >
                {t('information.generate')}
            </Button>

            <Suspense fallback={<SpinnerOverlay loading />}>
                {shopsOpen && <ShopListDialog open={shopsOpen} onOpenChange={setShopsOpen} />}
            </Suspense>
        </Page>
    );
};

const useStyles = makeStyles({
    listContainer: {
        alignSelf: 'stretch',
    },
    listTitle: {
        display: 'block',
        lineHeight: tokens.lineHeightBase400,

        '& + *': {
            display: 'inline-block',
            marginTop: '0.2rem',
        },
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
    },
});
