import { Currency } from '@zastrpay/components';
import type { Address } from '@zastrpay/pages';
import type { Contact, Identity } from '@zastrpay/registration';

export type Direction = 'CustomerToMerchant' | 'MerchantToCustomer';

export type CustomerData = Partial<Identity> & {
    address?: Partial<Address>;
    contact?: Partial<Contact>;
};

export type RedirectData = {
    successUrl: string;
    failureUrl: string;
    abortUrl: string;
    qrCodeSuccessUrl?: string; //TODO: make qrCodeSuccessUrl required once Tipico migrate to the latest version
};

export type RedirectSessionType = 'NewCustomer' | 'NewTransactionIntent' | 'ExistingTransactionIntent';

export type RedirectSessionState = 'Created' | 'CustomerRedirected';

export type RedirectSessionBase = {
    id: string;
    type: RedirectSessionType;
    state: RedirectSessionState;
    createdOn: Date;
    merchantId: string;
    redirectData: RedirectData;
    locale?: string;
};

export type NewCustomerRedirectSession = RedirectSessionBase & {
    type: 'NewCustomer';
    customerData?: CustomerData;
};

export type TransactionType =
    | 'PassthroughDeposit'
    | 'PassthroughWithdrawal'
    | 'CustomerToMerchantPassthrough'
    | 'MerchantToCustomerPassthrough'
    | 'CustomerToMerchantTransfer'
    | 'MerchantToCustomerTransfer';

export type TransactionData = {
    amount: number;
    currency: Currency;
    type?: TransactionType;
    direction?: Direction;
    externalReference?: string;
};

export type NewTransactionIntentRedirectSession = RedirectSessionBase & {
    type: 'NewTransactionIntent';
    customerId?: string;
    transactionData: TransactionData;
};

export type ExistingTransactionIntentRedirectSession = RedirectSessionBase & {
    type: 'ExistingTransactionIntent';
    customerId: string;
    transactionIntentId: string;
};

export type RedirectSession = NewCustomerRedirectSession | NewTransactionIntentRedirectSession | ExistingTransactionIntentRedirectSession;

export type CustomerRedirectedRedirectSession = RedirectSession & { token: string };

export const getTransactionTypeFromRedirectTypeOrDirection = (direction: Direction | undefined, type: TransactionType | undefined) => {
    if (type === 'MerchantToCustomerTransfer') {
        return 'MerchantToCustomerTransfer';
    } else if (direction === 'CustomerToMerchant') {
        return 'CustomerToMerchantTransfer';
    } else if (direction === 'MerchantToCustomer') {
        return 'MerchantToCustomerTransfer';
    }
};

export const getTransactionIntentTypeFromDirection = (direction: Direction | undefined) => {
    if (direction === 'CustomerToMerchant') {
        return 'CustomerToMerchantPassthrough';
    } else {
        return 'MerchantToCustomerPassthrough';
    }
};

export const getTransactionIntentTypeFromRedirectTypeOrDirection = (
    direction: Direction | undefined,
    type: TransactionType | undefined,
) => {
    if (direction) {
        return getTransactionIntentTypeFromDirection(direction);
    } else if (
        type === 'PassthroughDeposit' ||
        type === 'PassthroughWithdrawal' ||
        type === 'CustomerToMerchantPassthrough' ||
        type === 'MerchantToCustomerPassthrough'
    ) {
        return type;
    } else {
        throw new Error(`Unsupported transaction type ${type}`);
    }
};
