import { api, PagedList } from '@zastrpay/common';

import { CUST_CMDH, CUST_QRYH } from './config';
import { Customer } from './models';

export const get = (id: string): Promise<Customer> => {
    return api.get<Customer>(`${CUST_QRYH}/customers/${id}`);
};

export const getWhitelistedMerchants = (id: string): Promise<string[]> => {
    return api.get<PagedList<string>>(`${CUST_QRYH}/customers/${id}/sca-whitelisted-merchant-ids`).then((data) => data.items);
};

export const updateWhitelistedMerchants = (id: string, merchants: string[]): Promise<string[]> => {
    return api
        .patch<PagedList<string>>(`${CUST_CMDH}/customers/${id}/sca-whitelisted-merchant-ids`, { items: merchants })
        .then((data) => data.items);
};
