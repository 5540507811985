import { api, getListDeserialized, PagedList, sanitize, Serialized } from '@zastrpay/common';
import { ApproveTransactionIntent, CreateTransactionIntent, TransactionIntent } from '@zastrpay/transaction-intents';

import { endpoints } from '../config';
import { TransactionIntentFilter } from './models';

type FilterParams = {
    queryType: string;
    customerId?: string;
    merchantId?: string;
    type?: string;
};

const deserializeTransactionIntent = (transactionIntent: Serialized<TransactionIntent>): TransactionIntent => {
    return {
        ...transactionIntent,
        createdOn: new Date(transactionIntent.createdOn),
    };
};

export const getList = (filter: TransactionIntentFilter): Promise<PagedList<TransactionIntent>> => {
    const params: FilterParams = {
        queryType: filter.type,
    };

    if (filter.type === 'ActiveByCustomerAndMerchant') {
        params.customerId = filter.customerId;
        params.merchantId = filter.merchantId;
    }

    return api
        .get<PagedList<TransactionIntent>>(`${endpoints.trxQryh}/transaction-intents`, { params })
        .then(getListDeserialized(deserializeTransactionIntent));
};

export const create = (id: string, data: CreateTransactionIntent): Promise<TransactionIntent> => {
    return api
        .put<TransactionIntent>(`${endpoints.trxCmdh}/transaction-intents/${id}`, sanitize(data), { headers: { 'x-request-id': id } })
        .then(deserializeTransactionIntent);
};

export const approve = (id: string, data: ApproveTransactionIntent): Promise<TransactionIntent> => {
    return api
        .post<TransactionIntent>(`${endpoints.trxCmdh}/transaction-intents/${id}/approve`, sanitize(data))
        .then(deserializeTransactionIntent);
};

export const cancel = (id: string): Promise<TransactionIntent> => {
    return api.post<TransactionIntent>(`${endpoints.trxCmdh}/transaction-intents/${id}/cancel`).then(deserializeTransactionIntent);
};

export const get = (id: string): Promise<TransactionIntent> => {
    return api.get<TransactionIntent>(`${endpoints.trxQryh}/transaction-intents/${id}`).then(deserializeTransactionIntent);
};

export const tryGet = (id: string): Promise<TransactionIntent | undefined> => {
    return get(id).catch(() => undefined);
};

export const reset = (id: string): Promise<TransactionIntent> => {
    return api.post<TransactionIntent>(`${endpoints.trxCmdh}/transaction-intents/${id}/reset-approval`).then(deserializeTransactionIntent);
};
