import { CheckmarkCircleRegular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { MessagePage } from '@zastrpay/layout';

import { useApp } from '../AppProvider';
import { useSessionNavigation } from '../layout/SessionNavigationProvider';

export const RegistrationCompleted: React.FC = () => {
    const { t } = useTranslation('payment');

    const { merchant } = useApp();
    const { success } = useSessionNavigation();

    return (
        <MessagePage
            icon={<CheckmarkCircleRegular />}
            title={t('registration.completed.title')}
            message={t('registration.completed.subTitle')}
            action={t('registration.completed.back', { merchant: merchant?.displayName ?? merchant?.name })}
            onAction={success}
        />
    );
};
