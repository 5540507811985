/* eslint-disable no-restricted-imports */
import { Button as FuiButton, ButtonProps as FuiButtonProps, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { useMemo } from 'react';

import { tokens } from '@zastrpay/theme';

export type ButtonProps = FuiButtonProps & {
    variant?: 'default' | 'info' | 'warning' | 'error';
};

export const Button: React.FC<ButtonProps> = ({ variant, className, ...props }) => {
    const classes = useStyles();

    const variantClass = useMemo(() => {
        const buttonVariant = variant ?? 'default';
        const buttonAppearance = props.appearance ?? 'secondary';

        if (buttonAppearance === 'primary') {
            switch (buttonVariant) {
                case 'default':
                    return classes.primaryDefault;
                case 'error':
                    return classes.primaryError;
                case 'info':
                    return classes.primaryInfo;
                case 'warning':
                    return classes.primaryWarning;
            }
        }

        if (buttonAppearance === 'outline') {
            switch (buttonVariant) {
                case 'default':
                    return classes.outlineDefault;
                case 'error':
                    return classes.outlineError;
                case 'info':
                    return classes.outlineInfo;
                case 'warning':
                    return classes.outlineWarning;
            }
        }
    }, [variant, props.appearance, classes]);

    return <FuiButton className={mergeClasses(props.size === 'large' && classes.large, className, variantClass)} {...props} />;
};

const makePrimaryStyle = (color: string, backgroundColor: string, backgroundPressedColor: string) => ({
    alignSelf: 'stretch',
    boxShadow: tokens.shadow4,
    color,
    backgroundColor,
    fontVariant: 'lining-nums',
    ':disabled': {
        backgroundColor: `${backgroundColor} !important`,
        color,
        opacity: '0.4',
    },
    ':hover': {
        backgroundColor,
        color,
    },
    ':active': {
        backgroundColor: backgroundPressedColor,
        color,
    },
    ':hover:active': {
        backgroundColor: backgroundPressedColor,
        color,
    },
});

const makeOutlineStyle = (color: string, pressedColor: string) => ({
    alignSelf: 'stretch',
    color,
    fontWeight: 600,
    fontVariant: 'lining-nums',
    ...shorthands.borderColor(color),
    ':disabled': {
        color: `${color} !important`,
        ...shorthands.borderColor(`${color} !important`),
        opacity: '0.4',
    },
    ':hover': {
        color,
        ...shorthands.borderColor(color),
    },
    ':active': {
        color: pressedColor,
        ...shorthands.borderColor(pressedColor),
    },
    ':hover:active': {
        color: pressedColor,
        ...shorthands.borderColor(pressedColor),
    },
});

const useStyles = makeStyles({
    large: {
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalXL}`,
    },

    primaryDefault: makePrimaryStyle(tokens.colorNeutralBackground1, tokens.customPaletteGreen, tokens.customPaletteGreenDark),
    primaryInfo: makePrimaryStyle(tokens.colorNeutralBackground1, tokens.customPaletteBlue, tokens.customPaletteBlueDark),
    primaryWarning: makePrimaryStyle(tokens.customPaletteBlack, tokens.customPaletteYellow, tokens.customPaletteYellowDark),
    primaryError: makePrimaryStyle(tokens.colorNeutralBackground1, tokens.customPaletteRed, tokens.customPaletteRedDark),

    outlineDefault: makeOutlineStyle(tokens.customPaletteGreen, tokens.customPaletteGreenDark),
    outlineInfo: makeOutlineStyle(tokens.customPaletteBlue, tokens.customPaletteBlueDark),
    outlineWarning: makeOutlineStyle(tokens.customPaletteYellow, tokens.customPaletteYellowDark),
    outlineError: makeOutlineStyle(tokens.customPaletteRed, tokens.customPaletteRedDark),
});
