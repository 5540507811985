import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { forwardRef, HTMLAttributes } from 'react';

import { tokens } from '@zastrpay/theme';

import Logo from '../assets/logo.svg?react';
import { QrCode } from './QrCode';

type BrandedQrCodeProps = Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> & {
    content: string;
};

// currently hardcoded, maybe calculate on render?
const LOGO_ASPECT_RATIO = 1.6;
const CODE_SIZE = 340;

export const BrandedQrCode = forwardRef<HTMLDivElement, BrandedQrCodeProps>(({ content, className, ...props }, ref) => {
    const classes = useStyles();

    return (
        <div className={mergeClasses(classes.code, className)} {...props}>
            <QrCode
                ref={ref}
                content={content}
                moduleColor={tokens.colorBrandForeground2}
                moduleScale={0.9}
                moduleRoundness={1}
                positionCenterColor={tokens.customPaletteRed}
                positionCenterRoundness={1}
                positionRingColor={tokens.colorBrandForeground2}
                positionRingRoundness={0.5}
                quietZone={2}
                logoAspectRatio={LOGO_ASPECT_RATIO}
            >
                <Logo />
            </QrCode>
        </div>
    );
});

const useStyles = makeStyles({
    code: {
        borderRadius: '20px',
        display: 'flex',
        boxShadow: tokens.shadow8,
        width: '100%',
        maxWidth: `${CODE_SIZE}px`,
        backgroundColor: tokens.colorNeutralBackground1,
    },
});
