import { makeStyles } from '@fluentui/react-components';
import { MailRegular } from '@fluentui/react-icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackClick, trackInput, trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, ResponseError } from '@zastrpay/common';
import { Alert, Body, BodyStrong, ErrorTrans, Link, Title } from '@zastrpay/components';
import { useRequestLoader } from '@zastrpay/hooks';
import { Page } from '@zastrpay/layout';
import { OtpInput, OtpInputElement } from '@zastrpay/pages';
import { tokens } from '@zastrpay/theme';

export type EmailOtpInputProps = {
    flowId?: string;
    email: string;
    error?: React.ReactElement;
    onVerify?: () => void;
    onNoAccess?: () => void;
};

export const EmailOtpInput: React.FC<EmailOtpInputProps> = (props) => {
    const classes = useStyles();
    const loading = useRequestLoader();

    const { t, i18n } = useTranslation('auth-flow');
    const { verifyOtpCode, generateOtpCode } = useAuth();

    const otpRef = useRef<OtpInputElement>(null);

    const [error, setError] = useState<ResponseError>();

    useEffect(() => {
        trackPage('email_verify');
    }, []);

    const verify = async (otp?: string): Promise<void> => {
        if (otp) {
            trackInput('email_verify', 'enter_email_otp');

            try {
                await verifyOtpCode('Email', otp, props.flowId);

                props.onVerify?.();
            } catch (error) {
                otpRef.current?.reset();
                setError(ensureResponseError(error));
            }
        }
    };

    const resend = async (): Promise<void> => {
        trackClick('email_verify', 'resend_email_otp');

        try {
            await generateOtpCode('Email', undefined, i18n.language, props.flowId);
        } catch (error) {
            setError(ensureResponseError(error));
        }
    };

    const noAccess = (): void => {
        trackClick('email_verify', 'no_access');

        props.onNoAccess?.();
    };

    return (
        <Page>
            <MailRegular className={classes.icon} />
            <Title>{t('emailOtp.title')}</Title>
            <Body>{t('emailOtp.subTitle')}</Body>

            <BodyStrong>{props.email}</BodyStrong>

            <OtpInput
                ref={otpRef}
                error={error ? <ErrorTrans t={t} error={error} i18nKey="emailOtp.error.response" /> : props.error}
                disabled={loading}
                onInput={verify}
                onResend={resend}
            />

            <Alert type="info">{t('emailOtp.hint')}</Alert>

            {props.onNoAccess && (
                <Link className={classes.link} inline onClick={noAccess}>
                    {t('emailOtp.noAccess')}
                </Link>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
        textAlign: 'center',
    },
    icon: {
        height: tokens.iconSizeTitle,
        width: tokens.iconSizeTitle,
    },
});
