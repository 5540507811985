import { makeStyles } from '@fluentui/react-components';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, TranslatableError } from '@zastrpay/common';
import { Alert, Body, BodyStrong, ErrorTrans, Link, Pin, PinElement, Timer, TimerElement, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';

import { approve, reset } from './api';
import { otpResendWaitSeconds } from './config';
import { Merchant, Transaction } from './models';
import { TransactionInfo } from './TransactionInfo';

export type AuthorizeTransactionProps = {
    transaction: Transaction;
    merchant?: Merchant;
    onAuthorize?: (transaction: Transaction) => void;
};

export const AuthorizeTransaction: React.FC<AuthorizeTransactionProps> = (props) => {
    const classes = useStyles();
    const timer = useRef<TimerElement>(null);
    const pin = useRef<PinElement>(null);

    const { t } = useTranslation('transactions');
    const { phone } = useAuth();

    const [error, setError] = useState<TranslatableError>();
    const [canSend, setCanSend] = useState(false);

    const timerEnded = () => {
        setCanSend(true);
    };

    const pinChanged = async (value?: string) => {
        if (!value) {
            setError(undefined);
            return;
        }

        try {
            const transaction = await approve(props.transaction.id, { approvalCode: value, customerId: props.transaction.customerId });

            if (transaction.state === 'Completed') {
                props.onAuthorize?.(transaction);
            }
        } catch (error) {
            setError(ensureResponseError(error));
            pin.current?.reset();
        }
    };

    const resendOtp = async () => {
        try {
            pin.current?.reset();
            setCanSend(false);
            await reset(props.transaction.id);
            timer.current?.reset();
        } catch (error) {
            setError(ensureResponseError(error));
            pin.current?.reset();
        }
    };

    switch (props.transaction.type) {
        case 'MerchantToCustomerTransfer':
        case 'CustomerToMerchantTransfer':
            break;
        case 'PassthroughDeposit':
        case 'PassthroughWithdrawal':
        case 'CustomerDeposit':
        case 'CustomerWithdrawal':
            throw new Error(`Unsupported transaction kind ${props.transaction.type}`);
    }

    return (
        <Page>
            <Title>{t('authorize.title', { context: props.transaction.type })}</Title>
            <Body>{t('authorize.subTitle')}</Body>
            <BodyStrong>{phone}</BodyStrong>

            <Pin ref={pin} length={6} onInput={pinChanged} autoFill autoFocus hyphenated={true} />

            {error && (
                <Alert type="error">
                    <ErrorTrans t={t} error={error} />
                </Alert>
            )}

            <Body>
                {t('authorize.didNotGetCode')}{' '}
                {canSend ? (
                    <Link onClick={resendOtp} inline>
                        {t('authorize.sendAgain')}
                    </Link>
                ) : (
                    <Trans
                        t={t}
                        i18nKey="authorize.sendAgainIn"
                        components={{
                            timer: <Timer seconds={otpResendWaitSeconds} ref={timer} onEnd={timerEnded} />,
                        }}
                    />
                )}
            </Body>

            <TransactionInfo className={classes.overview} transaction={props.transaction} merchant={props.merchant} />
        </Page>
    );
};

const useStyles = makeStyles({
    overview: {
        alignSelf: 'stretch',
    },
});
