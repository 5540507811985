import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormattedDate } from '@zastrpay/components';

import { KycRequestAlert } from '../KycRequestAlert';
import { KycRequest } from '../models';

export type SelfDeclaredLinksQuestionnaireRequestProps = {
    request: KycRequest;
};

export const SelfDeclaredLinksQuestionnaireRequest: React.FC<SelfDeclaredLinksQuestionnaireRequestProps> = ({ request }) => {
    const navigate = useNavigate();

    const { t } = useTranslation('kyc-requests');

    if (!request.assignment) {
        throw new Error('Kyc Request of type SelfDeclaredLinks is missing assignment');
    }

    const { id: assignmentId, questionnaireId } = request.assignment;

    return (
        <KycRequestAlert
            title={t('selfDeclaredLinksQuestionnaire.title')}
            action={t('selfDeclaredLinksQuestionnaire.action')}
            onAction={() => navigate(`/questionnaires/${questionnaireId}/assignments/${assignmentId}`)}
        >
            <Trans
                t={t}
                i18nKey="selfDeclaredLinksQuestionnaire.description"
                context={request.state === 'PendingOverdue' ? 'Overdue' : undefined}
                components={{ dueOn: <FormattedDate value={request.dueOn} format="date" /> }}
            />
        </KycRequestAlert>
    );
};
