import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { AuthenticationVerifyResult, useAuth } from '@zastrpay/auth';
import { ensureResponseError, isResponseError, isValidationError, TranslatableError, ValidationError } from '@zastrpay/common';
import { Alert, Body, Button, ErrorTrans, Pin, PinElement, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';

export type PinSetupProps = {
    onComplete?: (result: AuthenticationVerifyResult) => void;
    existing?: boolean;
    flowId?: string;
    className?: string;
};

type InitialPin = {
    type: 'initial';
    pin?: string;
    error?: TranslatableError;
};

type VerifyPin = {
    type: 'verify';
    pin: string;
    retypedPin?: string;
    error?: TranslatableError;
};

export const PinSetup: React.FC<PinSetupProps> = (props) => {
    const pinRef = useRef<PinElement>(null);

    const { t } = useTranslation('registration');
    const {
        customerId,
        state: authState,
        phone,
        changePin: doChangePin,
        createPin: doCreatePin,
        verifyPin: doVerifyPin,
        verifyPinByOtpAuthFactor: doVerifyPinByOtpAuthFactor,
        verifyPinById: doVerifyPinById,
    } = useAuth();

    const [state, setState] = useState<InitialPin | VerifyPin>({ type: 'initial' });
    const [pinId, setPinId] = useState<string>();
    const [pinDisabled, setPinDisabled] = useState(false);

    useEffect(() => {
        if (phone) {
            if (state.type === 'initial') {
                trackPage('set_pin');
            } else if (state.type === 'verify') {
                trackPage('confirm_pin');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    if (!phone) {
        return <Navigate to="/login" />;
    }

    const setError = (error?: TranslatableError) => {
        setState((state) => ({ ...state, error }));
    };

    const setupPin = async (phone: string, pin: string, flowId?: string) => {
        setPinDisabled(true);

        try {
            if (props.existing) {
                await doChangePin(pin);
            } else {
                try {
                    const id = await doCreatePin(pin, flowId);
                    setPinId(id);
                } catch (error) {
                    if (isResponseError(error, 'DuplicateEntity')) {
                        await doChangePin(pin);
                    } else {
                        throw error;
                    }
                }
            }
            setState({ pin, type: 'verify' });

            pinRef.current?.reset();
        } catch (error) {
            setError(ensureResponseError(error));
        }

        setPinDisabled(false);
    };

    const verifyPin = async (phone: string, pin: string, retypedPin: string, flowId?: string) => {
        if (retypedPin !== pin) {
            setState({ type: 'initial', pin: undefined });
            pinRef.current?.reset();
            return setError(new ValidationError('PinNotMatched'));
        }

        try {
            const result =
                authState === 'authenticated' && pinId && flowId
                    ? // only authenticated users can verify pins by id (which is needed in case of creating a new pin through a flow)
                      await doVerifyPinById(pinId, retypedPin, flowId)
                    : customerId
                      ? await doVerifyPin(retypedPin)
                      : await doVerifyPinByOtpAuthFactor('Phone', phone, retypedPin, flowId);

            props.onComplete?.(result);
        } catch (error) {
            setError(ensureResponseError(error));
        }
    };

    const pinChanged = (value?: string) => {
        if (!value) {
            setError(undefined);
        }

        if (state.type === 'initial') {
            setState({ ...state, pin: value });
        } else {
            setState({ ...state, retypedPin: value });
        }
    };

    const buttonAction = () => {
        if (state.type === 'initial') {
            trackClick('set_pin', 'next');
            return state.pin && setupPin(phone, state.pin, props.flowId);
        } else {
            trackClick('confirm_pin', 'next');
            return state.retypedPin && verifyPin(phone, state.pin, state.retypedPin, props.flowId);
        }
    };

    return (
        <Page className={props?.className}>
            <Title>{state.type === 'initial' ? t('pin.enterTitle') : t('pin.reenterTitle')}</Title>
            <Body>{state.type === 'initial' ? t('pin.enterSubTitle') : t('pin.reenterSubTitle')}</Body>
            <Pin length={4} ref={pinRef} onInput={pinChanged} autoFocus disabled={pinDisabled} />

            {state.error && (
                <Alert type="error">
                    <ErrorTrans
                        t={t}
                        i18nKey={isValidationError(state.error, 'PinNotMatched') ? 'pin.error.validation' : undefined}
                        error={state.error}
                    />
                </Alert>
            )}

            <Button
                appearance="primary"
                size="large"
                disabled={state.type === 'initial' ? !state.pin : !state.retypedPin}
                onClick={buttonAction}
            >
                {t('pin.next')}
            </Button>
        </Page>
    );
};
