import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';

import { api } from '@zastrpay/common';

export const useRequestLoader = (): boolean => {
    const [loading, setLoading] = useState(false);

    const requests = useRef(new Set<string | symbol>());

    const requestStarted = (identifier: string | symbol) => {
        requests.current.add(identifier);

        if (requests.current.size !== 0) {
            setLoading(true);
        }
    };

    const requestEnded = (identifier: string | symbol) => {
        requests.current.delete(identifier);

        if (requests.current.size === 0) {
            setLoading(false);
        }
    };

    useEffect(() => {
        let requestInterceptor: number;
        let responseInterceptor: number;

        api.apply((instance) => {
            requestInterceptor = instance.interceptors.request.use(
                (request) => {
                    if (request.loader !== false) {
                        request.loader = {
                            requestIdentifier: Symbol(),
                            ...request.loader,
                        };

                        requestStarted(request.loader.requestIdentifier);
                    }

                    return request;
                },
                (error) => {
                    if (error instanceof AxiosError && error.config?.loader) {
                        requestEnded(error.config.loader.requestIdentifier);
                    }

                    return Promise.reject(error);
                },
            );

            responseInterceptor = instance.interceptors.response.use(
                (response) => {
                    if (response.config?.loader) {
                        requestEnded(response.config.loader.requestIdentifier);
                    }

                    return response;
                },
                (error) => {
                    if (error instanceof AxiosError && error.config?.loader) {
                        requestEnded(error.config.loader.requestIdentifier);
                    }

                    return Promise.reject(error);
                },
            );
        });

        return () => {
            api.apply((instance) => {
                instance.interceptors.request.eject(requestInterceptor);
                instance.interceptors.response.eject(responseInterceptor);
            });
        };
    }, []);

    return loading;
};
