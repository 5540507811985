import { v4 as uuid } from 'uuid';

import { api, sanitize } from '@zastrpay/common';

import { CUSTOMER_AUTHENTICATION_CMDH, CUSTOMER_AUTHENTICATION_QRYH } from './config';
import { AuthFlow, AuthFlowConfig, AuthFlowType } from './models';

export const getConfig = (type: AuthFlowType): Promise<AuthFlowConfig> =>
    api.get<AuthFlowConfig>(`${CUSTOMER_AUTHENTICATION_QRYH}/auth-flow-configs`, { params: sanitize({ type }) });

export const start = (type: AuthFlowType): Promise<AuthFlow> =>
    api.put<AuthFlow>(`${CUSTOMER_AUTHENTICATION_CMDH}/auth-flows/${uuid()}`, sanitize({ type }));

export const getAuthFlow = (id: string): Promise<AuthFlow> => api.get<AuthFlow>(`${CUSTOMER_AUTHENTICATION_QRYH}/auth-flows/${id}`);
