import { getLocales } from './config';

const isLeapYear = (year: number) => year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

export const parseIsoDate = (date: string): Date | undefined => {
    const match = date.match(/(\d{4})-(\d{2})-(\d{2})/);

    if (!match) {
        return;
    }

    const year = parseInt(match[1]);
    const month = parseInt(match[2]);
    const day = parseInt(match[3]);

    if ([1, 3, 5, 7, 8, 10, 12].includes(month) && day > 31) {
        return;
    } else if ([4, 6, 9, 11].includes(month) && day > 30) {
        return;
    } else if (month === 2 && isLeapYear(year) && day > 29) {
        return;
    } else if (month === 2 && !isLeapYear(year) && day > 28) {
        return;
    } else if (month > 12) {
        return;
    }

    const parsed = new Date(year, month - 1, day);

    if (!isNaN(parsed.getTime())) {
        return parsed;
    }
};

/** Formats the current local date in the ISO8601 format. */
export const formatIsoDate = (date: Date | string | number) => {
    const value = date instanceof Date ? date : new Date(date);

    // the Date.toISOString does format the date to UTC so we do it this way to get the correct date
    const yyyy = value.getFullYear().toString().padStart(4, '0');
    const MM = (value.getMonth() + 1).toString().padStart(2, '0');
    const dd = value.getDate().toString().padStart(2, '0');

    return `${yyyy}-${MM}-${dd}`;
};

const format = (date: Date | string | number, formatOptions: Intl.DateTimeFormatOptions) => {
    const value = date instanceof Date ? date : new Date(date);
    return value.toLocaleString(getLocales(), formatOptions);
};

export const formatTime = (date: Date | string | number) => format(date, { timeStyle: 'short' });

export const formatExactTime = (date: Date | string | number) => {
    const timeFormat = { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' } as const;

    // do it this way because it put "," instead of "." before the ms in certain locales (because that's the decimal separator)
    return `${format(date, timeFormat)}.${format(date, { fractionalSecondDigits: 3 })}`;
};

export const formatDate = (date: Date | string | number) => format(date, { dateStyle: 'medium' });

export const formatFullDate = (date: Date | string) => {
    const dateFormat = { day: '2-digit', month: '2-digit', year: 'numeric' } as const;
    const timeFormat = { timeStyle: 'short' } as const;

    return `${format(date, dateFormat)} ${format(date, timeFormat)}`;
};

export const formatExactDate = (date: Date | string | number) =>
    format(date, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });

export const formatDetailDate = (date: Date | string) => {
    const value = typeof date === 'string' ? new Date(date) : date;

    return `${format(value, { dateStyle: 'medium' })} ${formatExactTime(date)}`;
};

export const addDays = (date: Date, days: number): Date => {
    const d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
};

export const subDays = (date: Date, days: number): Date => {
    return addDays(date, -days);
};

export const addYears = (date: Date, years: number): Date => {
    const d = new Date(date);
    d.setFullYear(d.getFullYear() + years);
    return d;
};

export const subYears = (date: Date, years: number): Date => {
    return addYears(date, -years);
};

export const differenceInSeconds = (first: Date, second: Date) => {
    const difference = first.getTime() - second.getTime();

    return Math.round(difference / 1_000);
};

export const differenceInDays = (first: Date, second: Date) => {
    const difference = first.getTime() - second.getTime();

    return Math.round(difference / 1_000 / 60 / 60 / 24);
};
