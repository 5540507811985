import { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from '@zastrpay/auth';
import { useKycRequest } from '@zastrpay/kyc-requests';
import { TransactionIntent } from '@zastrpay/transaction-intents';
import { TransactionType } from '@zastrpay/transactions';

import { useApp } from '../AppProvider';
import { Direction, TransactionType as RedirectSessionTransactionType } from '../auth/models';
import { get as getIntent } from '../transaction-intents/api';

export type CustomerPermissionsState = {
    existingIntent: TransactionIntent | null;
    isIntentAllowed: boolean;
};

export type CustomerPermissionsContext = CustomerPermissionsState & {};

const Context = createContext<CustomerPermissionsContext | null>(null);

export const CustomerPermissionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { customer } = useAuth();
    const { redirectSession } = useApp();
    const { completed, loaded } = useKycRequest();
    const isKycFlowCompleted = loaded && completed;
    const [transactionTypeOrDirection, setTransactionTypeOrDirection] = useState<
        RedirectSessionTransactionType | TransactionType | Direction | undefined | null
    >(null);
    const [existingIntent, setExistingIntent] = useState<TransactionIntent | null>(null);

    const [state, setState] = useState<CustomerPermissionsState>({
        existingIntent: null,
        isIntentAllowed: true,
    });

    useEffect(() => {
        if (!redirectSession) {
            return;
        }

        if (redirectSession.type === 'NewTransactionIntent') {
            setTransactionTypeOrDirection(redirectSession.transactionData?.type || redirectSession.transactionData?.direction);
            return;
        }

        if (redirectSession.type === 'ExistingTransactionIntent') {
            getIntent(redirectSession.transactionIntentId).then((intent) => {
                setExistingIntent(intent);
                setTransactionTypeOrDirection(intent?.type);
            });
        }
    }, [redirectSession]);

    useEffect(() => {
        if (!isKycFlowCompleted || !transactionTypeOrDirection) {
            setState((state) => ({
                ...state,
                isIntentAllowed: true,
            }));
            return;
        }

        switch (transactionTypeOrDirection) {
            case 'PassthroughDeposit':
            case 'CustomerDeposit':
            case 'CustomerToMerchant':
            case 'CustomerToMerchantTransfer':
            case 'CustomerToMerchantPassthrough':
                setState((state) => ({
                    ...state,
                    isIntentAllowed: customer?.permissions.executePassthroughDeposit !== 'Disallowed',
                }));
                return;
            case 'PassthroughWithdrawal':
            case 'CustomerWithdrawal':
            case 'MerchantToCustomer':
            case 'MerchantToCustomerPassthrough':
            case 'MerchantToCustomerTransfer':
                setState((state) => ({
                    ...state,
                    isIntentAllowed: customer?.permissions.executePassthroughWithdrawal !== 'Disallowed',
                }));
        }
    }, [customer, transactionTypeOrDirection, isKycFlowCompleted]);

    return <Context.Provider value={{ ...state, existingIntent }}>{children}</Context.Provider>;
};

export const useCustomerPermissions = (): CustomerPermissionsContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useCustomer must be used within an CustomerProvider');
    }

    return context;
};
