export const retrieve = <T>(key: string): T | undefined => {
    const value = sessionStorage.getItem(key);

    if (value) {
        try {
            return JSON.parse(value);
        } catch {}
    }
};

export const store = <T>(key: string, value?: T): void => {
    if (value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    } else {
        sessionStorage.removeItem(key);
    }
};
