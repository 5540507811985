import { makeStyles } from '@fluentui/react-components';
import { DismissCircleRegular } from '@fluentui/react-icons';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { Link } from '@zastrpay/components';
import { useKycRequest } from '@zastrpay/kyc-requests';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { Merchant } from '../merchants/models';

export type AccountBlockedProps = {
    merchant?: Merchant;
    onBack: () => void;
};

export const CustomerBlocked: React.FC<AccountBlockedProps> = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { completed: isKycFlowCompleted, loaded: isKycLoaded } = useKycRequest();
    const { customer } = useAuth();

    const { t } = useTranslation('payment');

    const name = props.merchant?.displayName ?? props.merchant?.name ?? t('common.merchant');

    useEffect(() => {
        trackPage('error_account_blocked', { type: 'error' });
    }, []);

    useEffect(() => {
        if (!isKycFlowCompleted && isKycLoaded) {
            navigate('/kyc-request');
        }
    }, [isKycFlowCompleted, isKycLoaded, navigate]);

    useEffect(() => {
        if (customer?.state !== 'Blocked') {
            navigate('/');
        }
    }, [customer, navigate]);

    if (!isKycLoaded) {
        return null;
    }

    return (
        <MessagePage
            icon={<DismissCircleRegular className={classes.icon} />}
            title={t('common.accountBlockedError.title')}
            message={
                <>
                    <p>{t('common.accountBlockedError.subTitle')}</p>
                    <p>
                        <Trans
                            t={t}
                            i18nKey="common.accountBlockedError.contactSupport"
                            components={{
                                email: (
                                    <span>
                                        <Link className={classes.email} href={`mailto:${t('common.accountBlockedError.contactEmail')}`}>
                                            {t('common.accountBlockedError.contactEmail')}
                                        </Link>
                                    </span>
                                ),
                            }}
                        />
                    </p>
                </>
            }
            action={t('common.accountBlockedError.back', { merchant: name })}
            onAction={props.onBack}
        />
    );
};

const useStyles = makeStyles({
    icon: {
        color: tokens.customPaletteRed,
    },
    email: {
        fontWeight: tokens.fontWeightSemibold,
    },
});
