import type { LanguageDetectorModule } from 'i18next';

import * as localStorage from './local-storage';
import * as sessionStorage from './session-storage';

type DetectorOptions = { supportedLanguages: string[]; defaultLanguage?: string; logError: (e: Error) => void };

const STORAGE_KEY = 'language';
const QUERY_PARAM_KEY = 'lang';

const options: DetectorOptions = {
    supportedLanguages: [],
    defaultLanguage: undefined,
    logError: (e: Error) => console.log(e),
};

const logError = (e: unknown): void => {
    if (e instanceof Error) {
        options.logError(e);
    } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        options.logError((e as any)?.toString?.() ?? 'No Error');
    }
};

let initializedCache = false;

const getUserLanguage = (): string => {
    const distinctUserLangs = [...new Set<string>(navigator.languages.map((lang) => lang.substring(0, 2)))];
    const supportedUserLangs = distinctUserLangs.filter((lang) => options.supportedLanguages.includes(lang));

    return supportedUserLangs[0] ?? options.defaultLanguage;
};

const detect = (): string | undefined => {
    try {
        const queryParams = new URLSearchParams(location.search);

        const language =
            queryParams.get(QUERY_PARAM_KEY) ??
            localStorage.retrieve(STORAGE_KEY) ??
            sessionStorage.retrieve(STORAGE_KEY) ??
            getUserLanguage();

        // always delete the ?lang=xx query param after detection - will be cached "temporarily" through sessionStorage
        queryParams.delete(QUERY_PARAM_KEY);
        const searchString = queryParams.toString();
        history.replaceState(null, '', searchString ? `?${searchString}` : location.pathname);

        return language;
    } catch (e) {
        logError(e);
    }
};

const cacheUserLanguage = (language: string): void => {
    try {
        document.documentElement.lang = language;

        // cacheUserLanguage always runs once immediately after detect, on this initial call
        // we only cache the language to the "temporary" sessionStorage
        if (!initializedCache) {
            sessionStorage.store(STORAGE_KEY, language);
            initializedCache = true;
            return;
        }

        // after initial caching the language change can only be triggered by i18n.changeLanguage call (e.g. on user action)
        // this language change will be cached in the "permanent" localStorage
        localStorage.store(STORAGE_KEY, language);
    } catch (e) {
        logError(e);
    }
};

const userLanguageDetector: LanguageDetectorModule = {
    type: 'languageDetector',
    init: (_, detectorOptions: Required<DetectorOptions>) => {
        options.supportedLanguages = detectorOptions.supportedLanguages;
        options.defaultLanguage = detectorOptions.defaultLanguage;
        options.logError = detectorOptions.logError ?? options.logError;
    },
    detect,
    cacheUserLanguage,
};

export default userLanguageDetector;
