import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useEffect } from 'react';

import { sessionStorage } from '@zastrpay/common';

import { useNetworkState } from './use-network-state';

export type HubListener<TData> = (data: TData) => void;

class ArrayMap<TKey, TValue> {
    private map = new Map<TKey, TValue[]>();

    add(key: TKey, value: TValue): void {
        const currentValue = this.map.get(key) ?? [];

        this.map.set(key, [...currentValue, value]);
    }

    remove(key: TKey, value: TValue): void {
        const currentValue = this.map.get(key) ?? [];
        const updatedValue = currentValue.filter((current) => current !== value);

        if (updatedValue.length === 0) {
            this.map.delete(key);
        } else {
            this.map.set(key, updatedValue);
        }
    }

    empty(key: TKey): boolean {
        return this.map.has(key);
    }
}

const hubs = new Map<string, HubConnection>();
const handlers = new ArrayMap<string, HubListener<string>>();

const getHub = (url: string): HubConnection => {
    let hub = hubs.get(url);

    if (!hub) {
        hub = new HubConnectionBuilder()
            .withUrl(url, {
                // TODO: find an elegant way to share the token between the authentication provider and the useHub hook
                accessTokenFactory: () => sessionStorage.retrieve<string>('sessionToken') ?? '',
                timeout: 15_000,
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        hubs.set(url, hub);
    }

    return hub;
};

export const makeHubListener = <TMethod extends string, TData>(url: string) => {
    const hub = getHub(url);

    return (methodName: TMethod, method: HubListener<TData>) => {
        const network = useNetworkState();

        useEffect(() => {
            const callback = (data: string) => method(JSON.parse(data));

            if (network === 'online') {
                if (hub.state === HubConnectionState.Disconnected) {
                    hub.start().catch((e) => console.warn(e));
                }

                hub.on(methodName, callback);
                handlers.add(methodName, callback);
            } else {
                hub.stop();
            }

            return () => {
                hub.off(methodName, callback);
                handlers.remove(methodName, callback);

                if (handlers.empty(methodName)) {
                    hub.stop();
                }
            };
        }, [method, methodName, network]);
    };
};
