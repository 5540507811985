import { useEffect, useRef } from 'react';

/**
 * @param func The original, non-debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <Func extends (...args: any[]) => void>(func: Func, delay = 1000) => {
    const timer = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        return () => {
            if (!timer.current) return;
            clearTimeout(timer.current);
        };
    }, []);

    return (...args: Parameters<Func>) => {
        const newTimer = setTimeout(() => {
            func(...args);
        }, delay);
        clearTimeout(timer.current);
        timer.current = newTimer;
    };
};
