import React, { lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLayout } from '@zastrpay/layout';
import type { StepType } from '@zastrpay/registration';

import { useApp } from '../AppProvider';
import { CancelSession, CancelSessionDialogType } from '../session-cancellation/CancelSession';

const RegistrationFlow = lazy(() => import('@zastrpay/registration').then((module) => ({ default: module.Registration })));

export const Registration: React.FC = () => {
    const navigate = useNavigate();
    const { setHeaderSlot } = useLayout();
    const [step, setStep] = useState<StepType>();

    const { redirectSession, merchant } = useApp();

    useEffect(() => {
        let dialog: CancelSessionDialogType = 'registration';

        if (step === 'code') {
            dialog = 'registration-finalization';
        } else if (step === 'phone-verify') {
            dialog = 'phone-otp';
        }

        const unregisterHandle = setHeaderSlot('right', <CancelSession dialog={dialog} />);

        return () => unregisterHandle();
    }, [step, setHeaderSlot]);

    return (
        <RegistrationFlow
            compact
            customerData={redirectSession?.type === 'NewCustomer' ? redirectSession.customerData : undefined}
            merchant={merchant}
            handleIncompleteVerification={true}
            onRegister={() => navigate('/register/completed')}
            onBack={() => navigate('/')}
            onStepChange={(step) => setStep(step)}
        />
    );
};
