/* eslint-disable no-restricted-imports */
import { Link as FuiLink, LinkProps as FuiLinkProps, makeStyles, mergeClasses } from '@fluentui/react-components';

import { tokens } from '@zastrpay/theme';

export const Link: React.FC<FuiLinkProps> = ({ className, ...props }) => {
    const classes = useStyles();

    return <FuiLink {...props} className={mergeClasses(classes.link, props.inline && classes.inline, className)} />;
};

const useStyles = makeStyles({
    link: {
        textDecorationLine: 'none',
        color: tokens.customPaletteBlue,
        ':hover': {
            color: tokens.customPaletteBlue,
        },
        ':active': {
            color: tokens.customPaletteBlueDark,
            textDecorationLine: 'none',
        },
        ':hover:active': {
            color: tokens.customPaletteBlueDark,
        },
    },
    inline: {
        fontSize: 'inherit',
    },
});
