import { makeStyles } from '@fluentui/react-components';
import { PhoneChatRegular } from '@fluentui/react-icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackClick, trackInput, trackPage } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, ResponseError } from '@zastrpay/common';
import { Body, BodyStrong, ErrorTrans, Link, PinElement, Title } from '@zastrpay/components';
import { useRequestLoader } from '@zastrpay/hooks';
import { Page } from '@zastrpay/layout';
import { OtpInput } from '@zastrpay/pages';
import { tokens } from '@zastrpay/theme';

export type PhoneOtpInputProps = {
    flowId?: string;
    title?: string;
    phone: string;
    error?: React.ReactElement;
    onVerify?: () => void;
    onNoAccess?: () => void;
};

export const PhoneOtpInput: React.FC<PhoneOtpInputProps> = (props) => {
    const classes = useStyles();
    const loading = useRequestLoader();

    const { t, i18n } = useTranslation('auth-flow');
    const { verifyOtpCode, generateOtpCode } = useAuth();
    const pinRef = useRef<PinElement>(null);

    const [error, setError] = useState<ResponseError>();

    useEffect(() => {
        trackPage('phone_verify');
    }, []);

    const verify = async (otp?: string) => {
        if (otp) {
            trackInput('phone_verify', 'enter_phone_otp');

            try {
                await verifyOtpCode('Phone', otp, props.flowId);

                props.onVerify?.();
            } catch (error) {
                pinRef.current?.reset();
                setError(ensureResponseError(error));
            }
        }
    };

    const resend = async () => {
        trackClick('phone_verify', 'resend_sms_otp');

        try {
            await generateOtpCode('Phone', props.phone, i18n.language, props.flowId);
        } catch (error) {
            setError(ensureResponseError(error));
        }
    };

    return (
        <Page>
            <PhoneChatRegular className={classes.icon} />
            <Title>{props.title ?? t('phoneOtp.title')}</Title>
            <Body>{t('phoneOtp.subTitle')}</Body>

            <BodyStrong>{props.phone}</BodyStrong>

            <OtpInput
                ref={pinRef}
                error={error ? <ErrorTrans t={t} error={error} i18nKey="phoneOtp.error.response" /> : props.error}
                disabled={loading}
                onInput={verify}
                onResend={resend}
            />

            {props.onNoAccess && (
                <Link className={classes.link} inline onClick={props.onNoAccess}>
                    {t('phoneOtp.noAccess')}
                </Link>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
        textAlign: 'center',
    },
    icon: {
        height: tokens.iconSizeTitle,
        width: tokens.iconSizeTitle,
    },
});
