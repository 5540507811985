import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { useEffect, useState } from 'react';

import { dateUtil } from '@zastrpay/common';
import { tokens } from '@zastrpay/theme';

import { Body } from './Body';
import { ProgressBar } from './ProgressBar';

export type TransactionIntentTimerProps = {
    startOn: Date;
    /** Duration in seconds */
    duration: number;
    title?: string;
    onProgress?: (remainingTime: number) => void;
    onFinish?: () => void;
};

const REFRESH_TIME = 5; // seconds

export const TimerProgressBar: React.FC<TransactionIntentTimerProps> = ({ startOn, duration, title, onProgress, onFinish }) => {
    const classes = useStyles();
    const getElapsedTime = () => {
        const diff = dateUtil.differenceInSeconds(new Date(), startOn);
        return diff < 0 ? 0 : diff;
    };

    const [elapsedTime, setElapsedTime] = useState(getElapsedTime());

    const remainingTime = Math.round(duration - elapsedTime);
    const expirationProgress = (duration - elapsedTime) / duration;

    useEffect(() => {
        onProgress?.(remainingTime);
    }, [onProgress, remainingTime]);

    useEffect(() => {
        const task = setInterval(() => {
            setElapsedTime((remaining) => {
                if (remaining >= duration) {
                    clearInterval(task);

                    return duration;
                }

                return getElapsedTime();
            });
        }, REFRESH_TIME * 1000);

        return () => {
            clearInterval(task);
        };
    });

    useEffect(() => {
        if (elapsedTime >= duration) {
            onFinish?.();
        }
    }, [duration, elapsedTime, onFinish]);

    return (
        <div className={classes.progress}>
            <ProgressBar className={mergeClasses(remainingTime / duration < 1 / 3 && classes.redBar)} value={expirationProgress} />
            {title && <Body>{title}</Body>}
        </div>
    );
};

const useStyles = makeStyles({
    progress: {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        marginTop: tokens.spacingVerticalS,
        gap: tokens.spacingVerticalXXS,
    },
    redBar: {
        backgroundColor: tokens.customPaletteRed,
    },
});
