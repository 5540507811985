import { makeStyles } from '@fluentui/react-components';

import { Loader } from '@zastrpay/components';

import { Page } from './Page';

export const SpinnerPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Page className={classes.root}>
            <Loader />
        </Page>
    );
};

const useStyles = makeStyles({
    root: {
        justifyContent: 'center',
        flex: '1',
    },
});
