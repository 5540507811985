import {
    // eslint-disable-next-line no-restricted-imports
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { CheckmarkCircleRegular, Dismiss24Regular, ErrorCircleRegular } from '@fluentui/react-icons';

import { tokens } from '@zastrpay/theme';

import { Body } from './Body';
import { BodyStrong } from './BodyStrong';
import { Button } from './Button';

export type AlertType = 'success' | 'error';

export type AlertDialogProps = {
    type: AlertType;
    title?: string;
    content?: string;
    open: boolean;
    onOpenChange?: (open: boolean) => void;
};

export const AlertDialog: React.FC<AlertDialogProps> = (props) => {
    const classes = useStyles();

    return (
        <Dialog open={props.open} onOpenChange={(event, data) => props.onOpenChange?.(data.open)}>
            <DialogSurface className={classes.dialog}>
                <DialogBody className={classes.dialogBody}>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    className={classes.dialogAction}
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    />
                    <DialogContent className={classes.dialogContent}>
                        {props.type === 'success' && (
                            <CheckmarkCircleRegular className={mergeClasses(classes.dialogIcon, classes.dialogIconSuccess)} />
                        )}
                        {props.type === 'error' && (
                            <ErrorCircleRegular className={mergeClasses(classes.dialogIcon, classes.dialogIconError)} />
                        )}
                        {props.title && <BodyStrong>{props.title}</BodyStrong>}
                        {props.content && <Body>{props.content}</Body>}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

const useStyles = makeStyles({
    dialog: {
        maxWidth: '280px !important',
    },
    dialogBody: {
        gap: '0',
    },
    dialogContent: {
        position: 'relative',
        overflowY: 'visible',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: tokens.spacingVerticalM,
        margin: '0',
        padding: '0',
    },
    dialogIcon: {
        fontSize: '76px',
    },
    dialogIconSuccess: {
        color: tokens.customPaletteGreen,
    },
    dialogIconError: {
        color: tokens.customPaletteRed,
    },
    dialogAction: {
        position: 'absolute',
        top: tokens.spacingVerticalS,
        right: tokens.spacingHorizontalS,
    },
});
