import { makeStyles } from '@fluentui/react-components';
import { ShieldLockRegular } from '@fluentui/react-icons';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { TrustedBeneficiaryDialog } from '@zastrpay/analytics';
import { Body, Button, Dialog, DialogProps, Link } from '@zastrpay/components';

export type TrustBeneficiaryOutcome = 'skip' | 'decline' | 'confirm';

export type TrustMerchantDialogProps = Omit<DialogProps, 'title' | 'icon' | 'align'> & {
    merchant: string;
    onTrustBeneficiary?: (outcome: TrustBeneficiaryOutcome) => void;
};

export const TrustMerchantDialog: React.FC<TrustMerchantDialogProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('transaction-intents');

    const [openDetails, setOpenDetails] = useState(false);

    return (
        <>
            <Dialog
                open={!openDetails && props.open}
                onOpenChange={props.onOpenChange}
                align={'bottom'}
                icon={<ShieldLockRegular />}
                title={t('transaction.completed.trustedBeneficiary.title')}
                actions={
                    <>
                        <Button appearance="outline" onClick={() => props.onTrustBeneficiary?.('skip')} size="large">
                            {t('transaction.completed.trustedBeneficiary.skip')}
                        </Button>
                        <Button appearance="primary" onClick={() => props.onTrustBeneficiary?.('confirm')} size="large">
                            {t('transaction.completed.trustedBeneficiary.confirm')}
                        </Button>
                        <Link className={classes.link} onClick={() => props.onTrustBeneficiary?.('decline')}>
                            {t('transaction.completed.trustedBeneficiary.decline')}
                        </Link>
                    </>
                }
            >
                <Body block>
                    <Trans
                        t={t}
                        i18nKey="transaction.completed.trustedBeneficiary.subTitle"
                        values={{ merchant: props.merchant }}
                        components={{
                            whitelistLink: <Link as="a" onClick={() => setOpenDetails(true)} />,
                            portalLink: <Link href="https://zastrpay.com" target="_blank" />,
                        }}
                    />
                </Body>
            </Dialog>
            <TrustedBeneficiaryDialog merchant={props.merchant} open={openDetails} onOpenChange={setOpenDetails} />
        </>
    );
};

const useStyles = makeStyles({
    link: {
        textAlign: 'center',
        flexBasis: '100%',
    },
});
