import { makeStyles } from '@fluentui/react-components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { EMAIL_REGEX, register } from '@zastrpay/common';
import { Alert, Body, Button, InputField, Link, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

type EmailForm = {
    email: string;
};

export type EmailInputProps = {
    error?: React.ReactNode;
    onEmailInput: (email: string) => void;
    onEmailForgotten?: () => void;
};

export const EmailInput: React.FC<EmailInputProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation('auth-flow');

    const { control, handleSubmit, formState } = useForm<EmailForm>({ mode: 'onChange' });
    useEffect(() => {
        trackPage('email_input');
    }, []);

    const next = handleSubmit(async ({ email }) => {
        trackClick('email_input', 'next');
        props.onEmailInput(email);
    });

    return (
        <Page>
            <Title>{t('changePhone.emailInput.title')}</Title>
            <Body>{t('changePhone.emailInput.subTitle')}</Body>

            <div className={classes.emailContainer}>
                <InputField
                    {...register(control, 'email', {
                        required: true,
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t('changePhone.emailInput.error.validation', { context: 'EmailPattern' }),
                        },
                    })}
                    className={classes.email}
                    label={t('changePhone.emailInput.email')}
                    type="email"
                    autoComplete="email"
                    size="large"
                />

                {props.error && <Alert type="error">{props.error}</Alert>}
            </div>

            <Button appearance="primary" size="large" disabled={!formState.isValid} onClick={next}>
                {t('changePhone.emailInput.next')}
            </Button>
            {props.onEmailForgotten && (
                <Link className={classes.link} onClick={props.onEmailForgotten}>
                    {t('changePhone.emailInput.noAccess')}
                </Link>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    emailContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        alignItems: 'stretch',
        marginBottom: tokens.spacingVerticalM,
    },
    emailInput: {
        paddingLeft: 0,
        alignItems: 'stretch',
        alignSelf: 'stretch',
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
        textAlign: 'center',
    },
    email: {
        alignSelf: 'stretch',
    },
});
