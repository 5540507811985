// eslint-disable-next-line no-restricted-imports
import { Body2, TextPresetProps } from '@fluentui/react-components';

export const Body: React.FC<TextPresetProps> = ({ children, align, ...props }) => {
    return (
        <Body2 align={align ?? 'center'} {...props}>
            {children}
        </Body2>
    );
};
