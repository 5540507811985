import { jwtDecode } from 'jwt-decode';

export type AuthenticationMethod = 'Sms' | 'Email' | 'Pin';

type RedirectSessionScope = 'NewTransactionIntent' | 'ExistingTransactionIntent' | 'NewCustomer';
type CustomerSessionScope = 'NewCustomer' | 'ExistingCustomer';

type SessionTokenPayload =
    | {
          typ: 'Redirect';
          scope: RedirectSessionScope;
      }
    | {
          typ: 'Customer';
          scope: CustomerSessionScope;
      };

export type SessionType = SessionTokenPayload['typ'];
export type SessionScope = RedirectSessionScope | CustomerSessionScope;

export type TokenPayload = {
    sid: string;
    exp: number;
    sub?: string;
    auth?: boolean;
    ami?: AuthenticationMethod[];
    amr?: AuthenticationMethod[][];
    acr?: AuthenticationMethod[];
    tel?: string;
} & SessionTokenPayload;

export const parseToken = <T = TokenPayload>(token: string): T | undefined => {
    try {
        return jwtDecode<T>(token);
    } catch {
        // empty
    }
};
