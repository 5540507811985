import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COUNTRIES, CountryCode } from '@zastrpay/common';

export type Country = { countryCode: CountryCode; name: string; callingCode: string };

export const useCountries = (options?: { default?: CountryCode; top?: readonly CountryCode[]; sort?: keyof Country }) => {
    const { t, i18n } = useTranslation('hooks');

    const sort = useCallback(
        (a: Country, b: Country) => {
            const sortBy = options?.sort ?? 'countryCode';

            return a[sortBy].localeCompare(b[sortBy]);
        },
        [options?.sort],
    );

    const countries = useMemo(() => {
        return Object.entries(COUNTRIES)
            .map(([key, value]): Country => {
                return {
                    countryCode: key as CountryCode,
                    callingCode: value.callingCode,
                    name: t('countries.countryName', { context: key as CountryCode }),
                };
            })
            .sort(sort);
    }, [i18n.language, sort]);

    const defaultCountry = useMemo(
        () => countries.find((c) => c.countryCode === options?.default) ?? countries[0],
        [countries, options?.default],
    );

    const topCountries = useMemo(
        // order by order defined in top countries array
        () => (options?.top?.map((topCountry) => countries.find((c) => c.countryCode === topCountry)).filter(Boolean) as Country[]) ?? [],
        [countries, options?.top],
    );

    const otherCountries = useMemo(
        () => countries.filter((c) => !options?.top?.includes(c.countryCode)).sort(sort),
        [countries, sort, options?.top],
    );

    return { countries, defaultCountry, topCountries, otherCountries };
};
