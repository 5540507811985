import { makeStyles } from '@fluentui/react-components';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, TranslatableError } from '@zastrpay/common';
import { Alert, Body, BodyStrong, ErrorTrans, Link, Pin, PinElement, Timer, TimerElement, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { approve, reset } from './api';
import { otpResendWaitSeconds } from './config';
import { TransactionIntent } from './models';
import { TransactionIntentInfo } from './TransactionIntentInfo';

export type AuthorizeTransactionIntentProps = {
    intent: TransactionIntent;
    merchant?: string;
    onAuthorize?: (intent: TransactionIntent) => void;
};

export const AuthorizeTransactionIntent: React.FC<AuthorizeTransactionIntentProps> = (props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const timer = useRef<TimerElement>(null);
    const pin = useRef<PinElement>(null);

    const { t } = useTranslation('transaction-intents');
    const { phone } = useAuth();

    const [error, setError] = useState<TranslatableError>();
    const [canSend, setCanSend] = useState(false);

    const timerEnded = () => {
        setCanSend(true);
    };

    const pinChanged = async (value?: string) => {
        if (!value) {
            setError(undefined);
            return;
        }

        try {
            const intent = await approve(props.intent.id, { approvalCode: value, customerId: props.intent.customerId });

            if (intent.state === 'Pending') {
                props.onAuthorize?.(intent);
            }
        } catch (error) {
            setError(ensureResponseError(error));
            pin.current?.reset();
        }
    };

    const resendOtp = async () => {
        try {
            pin.current?.reset();
            setCanSend(false);
            await reset(props.intent.id);
            timer.current?.reset();
        } catch (error) {
            setError(ensureResponseError(error));
            pin.current?.reset();
        }
    };

    return (
        <Page>
            <Title>{t('authorize.title', { context: props.intent.type })}</Title>
            <Body>{t('authorize.subTitle')}</Body>
            <BodyStrong>{phone}</BodyStrong>

            <Pin ref={pin} length={6} onInput={pinChanged} autoFill autoFocus hyphenated={true} />

            {error && (
                <Alert type="error">
                    <ErrorTrans t={t} error={error} />
                </Alert>
            )}

            <Body>
                {t('authorize.didNotGetCode')}{' '}
                {canSend ? (
                    <Link onClick={resendOtp} inline>
                        {t('authorize.sendAgain')}
                    </Link>
                ) : (
                    <Trans
                        t={t}
                        i18nKey="authorize.sendAgainIn"
                        components={{
                            timer: <Timer seconds={otpResendWaitSeconds} ref={timer} onEnd={timerEnded} />,
                        }}
                    />
                )}
            </Body>
            <Link className={classes.link} onClick={() => navigate('/change-phone')}>
                {t('authorize.changePhone')}
            </Link>

            <TransactionIntentInfo className={classes.overview} intent={props.intent} merchant={props.merchant} />
        </Page>
    );
};

const useStyles = makeStyles({
    overview: {
        alignSelf: 'stretch',
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
});
