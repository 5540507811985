/* eslint-disable no-restricted-imports */
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ProgressBarProps as FuiFuiProgressBar, ProgressBar as FuiProgressBar } from '@fluentui/react-progress';

import { tokens } from '@zastrpay/theme';

export type ProgressBarProps = Pick<FuiFuiProgressBar, 'shape' | 'value' | 'className'>;

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
    const classes = useStyles();

    return (
        <FuiProgressBar
            thickness="large"
            value={props.value}
            shape={props.shape}
            bar={{ className: mergeClasses(classes.bar, props.className) }}
        />
    );
};

const useStyles = makeStyles({
    bar: {
        transitionProperty: 'width, background-color',
        backgroundColor: tokens.customPaletteGreenDark,
    },
});
