import { makeStyles } from '@fluentui/react-components';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { dateUtil, EMAIL_REGEX, register } from '@zastrpay/common';
import { InputField } from '@zastrpay/components';
import { tokens } from '@zastrpay/theme';

import { useActiveKycRequest } from '../ActiveKycRequestProvider';
import { KycRequestAlert } from '../KycRequestAlert';
import { KycRequest } from '../models';

type EmailForm = {
    email: string;
};

export type EmailVerificationRequestProps = {
    request: KycRequest;
};

export const EmailVerificationRequest: React.FC<EmailVerificationRequestProps> = ({ request }) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const { mode } = useActiveKycRequest();
    const { t } = useTranslation('kyc-requests');

    const remaining = useMemo(() => {
        const now = new Date();

        if (request.dueOn && request.dueOn > now) {
            return dateUtil.differenceInDays(request.dueOn, now);
        } else if (request.dueAfter) {
            return request.dueAfter.days;
        }
    }, [request]);

    const { control, handleSubmit } = useForm<EmailForm>({ mode: 'onChange' });

    const startVerification = (email?: string) => {
        navigate('/verify-email', { state: { email } });
    };

    const handleVerification = handleSubmit(({ email }) => startVerification(email));

    return (
        <KycRequestAlert
            title={t('emailVerification.title')}
            action={t('emailVerification.action')}
            onAction={mode === 'page' ? handleVerification : startVerification}
        >
            <Trans
                t={t}
                i18nKey="emailVerification.description"
                context={request.state === 'PendingOverdue' ? 'Overdue' : undefined}
                values={{ days: remaining }}
            />

            {mode === 'page' && (
                <InputField
                    {...register(control, 'email', {
                        required: true,
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t('emailVerification.error.validation', { context: 'EmailPattern' }),
                        },
                    })}
                    className={classes.email}
                    label={t('emailVerification.email')}
                    type="email"
                    autoComplete="email"
                    size="large"
                />
            )}
        </KycRequestAlert>
    );
};

const useStyles = makeStyles({
    email: {
        marginTop: tokens.spacingVerticalXL,
        textAlign: 'left',
        width: '100%',
    },
});
