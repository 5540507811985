import { makeStyles } from '@fluentui/react-components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { trackClick, trackPage } from '@zastrpay/analytics';
import { EMAIL_REGEX, register } from '@zastrpay/common';
import { Alert, Body, Button, InputField, Title } from '@zastrpay/components';
import { Page } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

type EmailForm = {
    email: string;
};

export type EmailInputProps = {
    email?: string;
    error?: React.ReactNode;
    onChange?: (email: string) => void;
};

export const EmailInput: React.FC<EmailInputProps> = (props) => {
    const classes = useStyles();

    const { t } = useTranslation('auth-flow');

    const { control, handleSubmit, formState } = useForm<EmailForm>({ mode: 'onChange', defaultValues: { email: props.email } });

    useEffect(() => {
        trackPage('new_email');
    }, []);

    const next = handleSubmit(({ email }) => {
        trackClick('new_email', 'next');

        props?.onChange?.(email);
    });

    return (
        <Page>
            <Title>{t('verifyEmail.emailInput.title')}</Title>
            <Body>{t('verifyEmail.emailInput.subTitle')}</Body>

            <div className={classes.emailContainer}>
                <InputField
                    {...register(control, 'email', {
                        required: true,
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t('verifyEmail.emailInput.error.validation', { context: 'EmailPattern' }),
                        },
                    })}
                    className={classes.email}
                    label={t('verifyEmail.emailInput.email')}
                    type="email"
                    autoComplete="email"
                    size="large"
                />

                {props.error && <Alert type="error">{props.error}</Alert>}
            </div>

            <Button appearance="primary" size="large" disabled={!formState.isValid} onClick={next}>
                {t('verifyEmail.emailInput.next')}
            </Button>
        </Page>
    );
};

const useStyles = makeStyles({
    emailContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        alignItems: 'stretch',
        marginBottom: tokens.spacingVerticalM,
    },
    emailInput: {
        paddingLeft: 0,
        alignItems: 'stretch',
        alignSelf: 'stretch',
    },
    link: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase400,
    },
    email: {
        alignSelf: 'stretch',
    },
});
