import { required } from '@zastrpay/common';

export const CUSTOMER_AUTHENTICATION_CMDH = required(import.meta.env.VITE_CUST_AUTH_SVC_CMDH_URL, 'VITE_CUST_AUTH_SVC_CMDH_URL');
export const CUSTOMER_AUTHENTICATION_QRYH = required(import.meta.env.VITE_CUST_AUTH_SVC_QRYH_URL, 'VITE_CUST_AUTH_SVC_QRYH_URL');
export const CUSTOMER_AUTHENTICATION_EXTEVP = required(import.meta.env.VITE_CUST_AUTH_SVC_EXTEVP_URL, 'VITE_CUST_AUTH_SVC_EXTEVP_URL');

export const MOCK_AUTHENTICATION_HEADERS = import.meta.env.VITE_MOCK_AUTHENTICATION_HEADERS === 'true';
export const MOCK_AUTHENTICATION_APP = MOCK_AUTHENTICATION_HEADERS
    ? required(import.meta.env.VITE_MOCK_AUTHENTICATION_APP, 'VITE_MOCK_AUTHENTICATION_APP')
    : undefined;
