import { Subtitle2 } from '@fluentui/react-components';
import { DocumentCheckmarkRegular, LocationRegular } from '@fluentui/react-icons';
import { lazy, Suspense, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert, Body, BrandedQrCode, BulletList, Button, Dialog, Link, Title } from '@zastrpay/components';
import { Page, SpinnerOverlay } from '@zastrpay/layout';

const ShopListDialog = lazy(() => import('@zastrpay/shops').then((module) => ({ default: module.ShopListDialog })));

export type ChangeAuthFactorQrCodeProps = {
    authFactor: 'email' | 'phone';
    authFlowId: string;
    onFindShopsClick?: () => void;
};

const idDocuments = ['IdCard', 'Passport', 'ResidencePermit', 'OtherDocument'] as const;

export const ChangeAuthFactorQrCode: React.FC<ChangeAuthFactorQrCodeProps> = ({ authFactor, authFlowId, onFindShopsClick }) => {
    const { t } = useTranslation('auth-flow');
    const [shopsOpen, setShopsOpen] = useState(false);
    const [idDocumentsOpen, setIdDocumentsOpen] = useState(false);

    const findShops = () => {
        setShopsOpen(true);
        onFindShopsClick?.();
    };

    return (
        <Page>
            <Title>{t('qrCode.title', { context: authFactor })}</Title>
            <Body>{t('qrCode.subTitle', { context: authFactor })}</Body>

            <Alert type="info">
                <Trans
                    t={t}
                    i18nKey="qrCode.info"
                    components={{ showMoreLink: <Link inline onClick={() => setIdDocumentsOpen(true)} /> }}
                />
            </Alert>

            <BrandedQrCode content={`AF:${authFlowId}`} />

            <Button appearance="outline" size="large" onClick={findShops} icon={<LocationRegular />}>
                {t('qrCode.findShops')}
            </Button>

            <Body>
                <Trans
                    t={t}
                    i18nKey="qrCode.later"
                    components={{ homepageLink: <Link inline href="https://zastrpay.com" target="_blank" /> }}
                />
            </Body>

            <Dialog
                icon={<DocumentCheckmarkRegular />}
                title={t('qrCode.acceptedDocuments')}
                open={idDocumentsOpen}
                onOpenChange={setIdDocumentsOpen}
                align="bottom"
            >
                <BulletList as="ul" size="small">
                    {idDocuments.map((doc) => (
                        <li key={`id-doc-${doc.toLowerCase()}`}>
                            <Trans t={t} i18nKey="qrCode.idDocument" context={doc} components={{ title: <Subtitle2 /> }} />
                        </li>
                    ))}
                </BulletList>
            </Dialog>

            <Suspense fallback={<SpinnerOverlay loading />}>
                {shopsOpen && <ShopListDialog open={shopsOpen} onOpenChange={setShopsOpen} />}
            </Suspense>
        </Page>
    );
};
