import { CheckmarkCircleRegular, DismissCircleRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { track } from '@zastrpay/analytics';
import { useAuth } from '@zastrpay/auth';
import { dateUtil, localStorage } from '@zastrpay/common';
import { useErrorBoundary } from '@zastrpay/components';
import { getWhitelistedMerchants, updateWhitelistedMerchants } from '@zastrpay/customers';
import { MessagePage } from '@zastrpay/layout';
import { Merchant, Transaction } from '@zastrpay/transactions';

import { TrustBeneficiaryOutcome, TrustMerchantDialog } from './TrustMerchantDialog';

export type ViewTransactionStateProps = {
    merchant?: Merchant;
    transaction: Transaction;
    onFailure: () => void;
    onSuccess: () => void;
};

const REJECTED_WHITELIST_KEY = 'rejectedWhitelist';

type RejectedWhitelist = Record<string, Date>;

export const ViewTransactionState: React.FC<ViewTransactionStateProps> = ({ merchant, transaction, onFailure, onSuccess }) => {
    const { t } = useTranslation('transaction-intents');
    const { trackError } = useErrorBoundary();
    const { customerId, state } = useAuth();

    const [whitelist, setWhitelist] = useState<string[]>([]);
    const [openWhitelist, setOpenWhitelist] = useState(false);
    const [canWhitelist, setCanWhitelist] = useState(false);

    const merchantName = merchant?.displayName ?? merchant?.name;

    const backText = merchantName ? t('common.back', { merchant: merchantName }) : t('common.back', { context: 'GenericMerchant' });

    useEffect(() => {
        if (state === 'authenticated' && transaction.type === 'PassthroughDeposit') {
            checkWhitelist(customerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, state, transaction.type]);

    useEffect(() => {
        if (transaction.state === 'Completed') {
            track('transaction_state', 'transaction_completed', { trx_type: transaction.type });
        } else if (transaction.state === 'Cancelled') {
            track('transaction_state', 'transaction_cancelled', { trx_type: transaction.type });
        } else if (transaction.state === 'Declined') {
            track('transaction_state', 'transaction_declined', { trx_type: transaction.type });
        }
    }, [transaction.state, transaction.type]);

    const checkWhitelist = async (customerId: string) => {
        if (merchant) {
            const rejectedWhitelist = localStorage.retrieve<RejectedWhitelist>(REJECTED_WHITELIST_KEY);
            const rejectedDate = rejectedWhitelist?.[merchant.id];

            if (rejectedDate && dateUtil.differenceInDays(new Date(), new Date(rejectedDate)) < 30) {
                return;
            }

            try {
                const whitelistedMerchants = await getWhitelistedMerchants(customerId);

                setWhitelist(whitelistedMerchants);

                if (!whitelistedMerchants.includes(merchant.id)) {
                    setCanWhitelist(true);
                }
            } catch (error) {
                trackError(error);
            }
        }
    };

    const handleWhitelist = async (outcome: TrustBeneficiaryOutcome) => {
        if (state === 'authenticated') {
            if (outcome === 'confirm' && state === 'authenticated' && merchant) {
                try {
                    await updateWhitelistedMerchants(customerId, [...whitelist, merchant.id]);
                } catch (error) {
                    trackError(error);
                }
            } else if (outcome === 'decline' && merchant) {
                const rejectedWhitelist = localStorage.retrieve<RejectedWhitelist>(REJECTED_WHITELIST_KEY) ?? {};
                localStorage.store<RejectedWhitelist>(REJECTED_WHITELIST_KEY, { ...rejectedWhitelist, [merchant.id]: new Date() });
            }
        }

        onSuccess();
    };

    const complete = () => {
        if (canWhitelist) {
            setOpenWhitelist(true);
        } else {
            onSuccess();
        }
    };

    switch (transaction.state) {
        case 'Completed':
            return (
                <>
                    <MessagePage
                        icon={<CheckmarkCircleRegular />}
                        title={t('transaction.completed.title', { context: transaction.type })}
                        action={backText}
                        onAction={complete}
                    />

                    {merchant && (
                        <TrustMerchantDialog
                            merchant={merchant.name}
                            open={openWhitelist}
                            onOpenChange={setOpenWhitelist}
                            onTrustBeneficiary={handleWhitelist}
                        />
                    )}
                </>
            );
        case 'Cancelled':
            return (
                <MessagePage
                    icon={<DismissCircleRegular />}
                    title={t('transaction.cancelled.title', { context: transaction.type })}
                    message={t('transaction.cancelled.subTitle', { context: transaction.type })}
                    action={backText}
                    onAction={onFailure}
                />
            );
        case 'Declined':
            return (
                <MessagePage
                    icon={<DismissCircleRegular />}
                    title={t('transaction.declined.title', { context: transaction.type })}
                    message={t('transaction.declined.subTitle')}
                    action={backText}
                    onAction={onFailure}
                />
            );
        case 'PendingApproval':
            throw new Error('Unhandled transaction state'); //TODO: replace with error handling
    }
};
