import axios, { AxiosInstance, AxiosResponse, Serialized } from 'axios';
import * as qs from 'qs';
import { v4 as uuid } from 'uuid';

import { defaultRequestTimeout, defaultUploadDownloadTimeout } from './config';
import { ResponseError } from './errors';

export type { Serialized } from 'axios';

export const PAGE_SIZE = 10;

export type List<T> = {
    items?: T[];
};

export type PagedList<T> = {
    items: T[];
    next?: T;
};

export type ListFilter = {
    from?: Date;
    to?: Date;
    limit?: number;
    type?: string;
    page?: number;
};

export const getData = <T>(response: AxiosResponse<T>): T => response.data;

export const getPagedList = (fromFilter?: Date, limit?: number) => {
    return function <T>(list: List<T>): PagedList<T> {
        const size = limit ?? PAGE_SIZE;

        let items = list.items ?? [];

        if (fromFilter && items.length) {
            items = items.reverse();
        }

        const next = items.length >= size ? items.pop() : undefined;

        return { items, next };
    };
};

export const getListDeserialized = <T>(deserialize: (serialized: Serialized<T>) => T) => {
    return function (list: PagedList<Serialized<T>>): PagedList<T> {
        return {
            items: list.items.map(deserialize),
            next: list.next && deserialize(list.next),
        };
    };
};

export const errorResponse = (status: number): boolean => status >= 400 && status < 500 && status !== 401 && status !== 403;
const okResponse = (status: number): boolean => status >= 200 && status < 300;

const apiInstance = axios.create({
    validateStatus: (status: number) => okResponse(status) || errorResponse(status),
    paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
    timeout: defaultRequestTimeout,
});

apiInstance.interceptors.request.use((request) => {
    request.headers.set('Accept', 'application/json', false);

    if (request.headerOptions?.addRequestId !== false) {
        request.headers.set('x-request-id', uuid(), false);
    }

    return request;
});

apiInstance.interceptors.request.use((request) => {
    const isFileDownload = request.responseType?.toLowerCase() === 'blob';
    const isFileUpload = [FormData, File, Blob].some((type) => request.data instanceof type);

    if (isFileDownload || isFileUpload) {
        request.timeout = defaultUploadDownloadTimeout;
    }

    return request;
});

const handleResponse = <T>(response: AxiosResponse<T>): T => {
    if (errorResponse(response.status)) {
        throw ResponseError.fromAxiosResponse(response);
    }

    return response.data;
};

export const api = {
    apply: (plugin: (instance: AxiosInstance) => void): void => plugin(apiInstance),
    request: <T extends object>(...args: Parameters<typeof apiInstance.request>): Promise<Serialized<T>> =>
        apiInstance.request(...args).then(handleResponse),
    get: <T extends object>(...args: Parameters<typeof apiInstance.get>): Promise<Serialized<T>> =>
        apiInstance.get(...args).then(handleResponse),
    put: <T extends object>(...args: Parameters<typeof apiInstance.put>): Promise<Serialized<T>> =>
        apiInstance.put(...args).then(handleResponse),
    post: <T extends object>(...args: Parameters<typeof apiInstance.post>): Promise<Serialized<T>> =>
        apiInstance.post(...args).then(handleResponse),
    delete: <T extends object>(...args: Parameters<typeof apiInstance.delete>): Promise<Serialized<T>> =>
        apiInstance.delete(...args).then(handleResponse),
    patch: <T extends object>(...args: Parameters<typeof apiInstance.patch>): Promise<Serialized<T>> =>
        apiInstance.patch(...args).then(handleResponse),
};

export const sanitize = (data?: unknown): unknown | undefined => {
    if (data === undefined || data === null || data === '') {
        return undefined;
    }

    if (Array.isArray(data)) {
        const sanitizedData: unknown[] = [];

        data.forEach((item) => {
            const sanitizedItem = sanitize(item);

            if (sanitizedItem !== undefined) {
                sanitizedData.push(sanitizedItem);
            }
        });

        if (sanitizedData.length) {
            return sanitizedData;
        }
    } else if (typeof data === 'object') {
        const sanitizedData: Record<string, unknown> = {};

        Object.entries(data).forEach(([key, value]) => {
            const sanitizedValue = sanitize(value);

            if (sanitizedValue !== undefined) {
                sanitizedData[key] = sanitizedValue;
            }
        });

        if (Object.keys(sanitizedData).length) {
            return sanitizedData;
        }
    } else {
        return data;
    }

    return undefined;
};
