import { Card, makeStyles, mergeClasses, Subtitle1 } from '@fluentui/react-components';
import { ErrorCircle20Regular, Info20Regular } from '@fluentui/react-icons';
import { PropsWithChildren } from 'react';

import { tokens } from '@zastrpay/theme';

export type AlertCardProps = {
    title: string;
    type: 'info' | 'error' | 'warning';
};

export const AlertCard: React.FC<PropsWithChildren<AlertCardProps>> = (props) => {
    const classes = useStyles();

    return (
        <Card
            size="large"
            className={mergeClasses(
                classes.root,
                props.type === 'info' && classes.info,
                props.type === 'error' && classes.error,
                props.type === 'warning' && classes.warning,
            )}
        >
            <div
                className={mergeClasses(
                    classes.header,
                    props.type === 'info' && classes.headerInfo,
                    props.type === 'error' && classes.headerError,
                    props.type === 'warning' && classes.headerWarning,
                )}
            >
                {props.type === 'info' && <Info20Regular color={tokens.customPaletteBlue} className={classes.icon} />}
                {props.type === 'warning' && <Info20Regular color={tokens.customPaletteYellowDark} className={classes.icon} />}
                {props.type === 'error' && <ErrorCircle20Regular color={tokens.customPaletteRed} className={classes.icon} />}
                <Subtitle1 className={classes.title}>{props.title}</Subtitle1>
            </div>

            {props.children}
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalXL}`,
        gap: tokens.spacingHorizontalS,
        display: 'flex',
        alignSelf: 'stretch',
        boxShadow: 'none',
    },
    title: {
        lineHeight: tokens.lineHeightBase400,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
    },
    icon: {
        flexBasis: '20px',
        flexGrow: 0,
        flexShrink: 0,
    },
    info: {
        backgroundColor: tokens.customPaletteBlueLight,
    },
    error: {
        backgroundColor: tokens.customPaletteRedLight,
    },
    warning: {
        backgroundColor: tokens.customPaletteYellowLight,
    },
    header: {
        display: 'flex',
        gap: tokens.spacingHorizontalS,
        alignItems: 'center',
    },
    headerInfo: {
        color: tokens.customPaletteBlue,
    },
    headerError: {
        color: tokens.customPaletteRed,
    },
    headerWarning: {
        color: tokens.customPaletteYellowDark,
    },
});
