import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useRef } from 'react';

export type TimerElement = {
    reset: () => void;
    start: () => void;
    stop: () => void;
};

export type TimerProps = {
    seconds: number;
    className?: string;
    onEnd?: () => void;
};

type TimerState = 'Running' | 'Stopped' | 'Ended';

type TimerModel = {
    time: number;
    state: TimerState;
};

export const Timer = forwardRef<TimerElement, TimerProps>((props, ref) => {
    const [timer, setTimer] = useState<TimerModel>({ time: props.seconds * 1000, state: 'Running' });
    const handle = useRef<ReturnType<typeof setTimeout>>();

    const formatTime = (ms: number) => {
        const seconds = ms / 1000;

        // const h = Math.floor(e / 3600).toString().padStart(2, '0');
        const mm = Math.floor((seconds % 3600) / 60)
            .toString()
            .padStart(2, '0');
        const ss = Math.floor(seconds % 60)
            .toString()
            .padStart(2, '0');

        return `${mm}:${ss}`;
    };

    const stopInterval = () => {
        clearTimeout(handle.current);
        handle.current = undefined;
    };

    const startInterval = () => {
        if (handle.current) {
            stopInterval();
        }

        handle.current = setInterval(() => {
            setTimer((timer) => {
                const remainingTime = timer.time - 1000;

                if (remainingTime <= 0) {
                    return { time: 0, state: 'Ended' };
                }

                return { time: remainingTime, state: 'Running' };
            });
        }, 1000);
    };

    useImperativeHandle(ref, () => ({
        reset: () => setTimer({ time: props.seconds * 1000, state: 'Running' }),
        start: () => setTimer((timer) => ({ ...timer, state: 'Running' })),
        stop: () => setTimer((timer) => ({ ...timer, state: 'Stopped' })),
    }));

    useEffect(() => {
        switch (timer.state) {
            case 'Ended':
                props.onEnd?.();
                break;
            case 'Stopped':
                stopInterval();
                break;
            case 'Running':
                startInterval();
                break;
        }

        return () => clearTimeout(handle.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer.state, handle.current]);

    return <span className={props.className}>{formatTime(timer.time)}</span>;
});
