import { makeStyles, Text } from '@fluentui/react-components';
import { CalendarErrorRegular, ErrorCircleSettingsRegular } from '@fluentui/react-icons';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UAParser from 'ua-parser-js';

import { Header, MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

const browserSupport = [
    { type: 'mobile', browser: 'opera', name: 'Opera', minVersion: 64, logo: './browser-logos/opera.svg' },
    { type: 'mobile', browser: 'safari', name: 'Safari', minVersion: 14, logo: './browser-logos/safari.svg' },
    { type: 'mobile', browser: 'chrome', name: 'Google Chrome', minVersion: 87, logo: './browser-logos/chrome.svg' },
    { type: 'mobile', browser: 'samsung', name: 'Samsung Internet', minVersion: 14, logo: './browser-logos/samsung-internet.svg' },
    { type: 'mobile', browser: 'firefox', name: 'Firefox', minVersion: 78, logo: './browser-logos/firefox.svg' },

    { type: 'desktop', browser: 'chrome', name: 'Google Chrome', minVersion: 87, logo: './browser-logos/chrome.svg' },
    { type: 'desktop', browser: 'firefox', name: 'Firefox', minVersion: 78, logo: './browser-logos/firefox.svg' },
    { type: 'desktop', browser: 'safari', name: 'Safari', minVersion: 14, logo: './browser-logos/safari.svg' },
    { type: 'desktop', browser: 'edge', name: 'Microsoft Edge', minVersion: 88, logo: './browser-logos/edge.svg' },
];

const engineSupport = [
    { name: 'webkit', minVersion: 605 },
    { name: 'blink', minVersion: 87 },
];

const BROWSER_SKIP_KEY = 'browserDetectionSkipped';

export type FeatureDetectionProps = {
    log?: (error: Error) => void;
};

type SupportResult = {
    /**
     * Indicates whether the browser or engine was matched in the list of supported ones
     */
    matched: boolean;
    /**
     * Indicates whether the browser or engine version fulfills the minimum version requirement in the specified list of supported ones
     */
    supported: boolean;
};

type CheckResult = {
    device: 'unknown' | 'mobile' | 'desktop';
    browser: SupportResult;
    engine: SupportResult;
};

const checkSupport = (): CheckResult => {
    const { browser, device, engine } = new UAParser().getResult();

    const result: CheckResult = {
        device: 'unknown',
        browser: { matched: false, supported: false },
        engine: { matched: false, supported: false },
    };

    if (browser.name && browser.version) {
        result.device = device.type === 'mobile' || device.type === 'tablet' ? 'mobile' : 'desktop';

        const browserVersion = parseInt(browser.version.split('.')[0]);
        const browserName = browser.name.toLowerCase();
        const browserMatch = browserSupport.find((bs) => bs.type === result.device && browserName.includes(bs.browser));

        if (browserMatch) {
            result.browser.matched = true;
            result.browser.supported = browserMatch.minVersion <= browserVersion;
        }
    }

    if (engine.name && engine.version) {
        const engineName = engine.name.toLowerCase();
        const engineVersion = parseInt(engine.version.split('.')[0]);
        const engineMatch = engineSupport.find((es) => engineName?.includes(es.name));

        if (engineMatch) {
            result.engine.matched = true;
            result.engine.supported = engineMatch.minVersion <= engineVersion;
        }
    }

    return result;
};

export const FeatureDetection: React.FC<PropsWithChildren<FeatureDetectionProps>> = ({ children, log }) => {
    const { t } = useTranslation('pages');
    const classes = useStyles();

    const [skipBrowserDetection, setSkipBrowserDetection] = useState(sessionStorage.getItem(BROWSER_SKIP_KEY) === 'true');

    try {
        sessionStorage.getItem('any-key');
        localStorage.getItem('any-key');
    } catch (error) {
        if (error instanceof Error && error.name === 'SecurityError') {
            return (
                <>
                    <Header />
                    <MessagePage
                        icon={<ErrorCircleSettingsRegular />}
                        title={t('featureDetection.storageDisabled.title')}
                        message={t('featureDetection.storageDisabled.subTitle')}
                        action={t('featureDetection.back')}
                        onAction={() => window.history.back()}
                    />
                </>
            );
        }
    }

    try {
        const { device, browser, engine } = checkSupport();

        if (device !== 'unknown' && !skipBrowserDetection) {
            if (!browser.supported && !engine.supported) {
                log?.(new Error(`Browser not supported: ${window.navigator.userAgent}`));

                return (
                    <>
                        <Header />
                        <MessagePage
                            icon={<CalendarErrorRegular />}
                            title={t('featureDetection.browserSupport.title')}
                            message={
                                <>
                                    {t('featureDetection.browserSupport.subTitle')}
                                    {browserSupport
                                        .filter((bs) => bs.type === device)
                                        .map((bs) => (
                                            <Text key={bs.browser} className={classes.browser}>
                                                <img src={bs.logo} alt={bs.browser} className={classes.logo} />
                                                <span className={classes.name}>
                                                    <b>{bs.name}</b>
                                                    <span className={classes.version}>
                                                        {t('featureDetection.browserSupport.version', { version: bs.minVersion })}
                                                    </span>
                                                </span>
                                            </Text>
                                        ))}
                                </>
                            }
                            action={t('featureDetection.back')}
                            onAction={() => window.history.back()}
                            secondaryAction={t('featureDetection.skip')}
                            onSecondaryAction={() => {
                                sessionStorage.setItem(BROWSER_SKIP_KEY, 'true');
                                setSkipBrowserDetection(true);
                            }}
                        />
                    </>
                );
            } else if (!browser.matched && !engine.matched) {
                log?.(new Error(`Unable to verify browser support for: ${window.navigator.userAgent}`));
            }
        }
    } catch (error) {
        log?.(new Error('Failed to verify browser support', { cause: error }));
    }

    return children;
};

const useStyles = makeStyles({
    browser: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
        marginTop: tokens.spacingVerticalM,
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusMedium,
        border: `1px solid ${tokens.colorNeutralStroke2}`,
        textAlign: 'center',
    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        fontSize: tokens.fontSizeBase500,
    },
    logo: {
        height: '2.5rem',
        width: '2.5rem',
    },
    version: {
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase200,
    },
});
