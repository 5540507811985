/* eslint-disable @typescript-eslint/no-explicit-any */
import { Field, FieldProps, SelectProps } from '@fluentui/react-components';
import { Key } from 'react';
import { Controller } from 'react-hook-form';

import { ControllerFieldProps, validate } from '@zastrpay/common';

import { Select } from './Select';

export type SelectOption<T> = {
    key: Key;
    text: string;
    value?: T;
    disabled?: boolean;
};

export type SelectFieldProps<T> = SelectProps & Pick<FieldProps, 'hint' | 'label'> & { options: SelectOption<T>[] };

export const SelectField = <T extends string | number | readonly string[]>({
    control,
    rules,
    className,
    label,
    hint,
    options,
    ...props
}: SelectFieldProps<T> & ControllerFieldProps) => {
    return (
        <Controller
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Field label={label} hint={hint} {...validate(fieldState)} className={className}>
                    <Select {...props} {...field}>
                        {options.map((option) => (
                            <option key={option.key} value={option.value} disabled={option.disabled}>
                                {option.text}
                            </option>
                        ))}
                    </Select>
                </Field>
            )}
        />
    );
};
