import { makeStyles } from '@fluentui/react-components';

import { tokens } from '@zastrpay/theme';

export const useDialogStyles = makeStyles({
    loadingContainer: { minHeight: '15vh' },
});

export const useStyles = makeStyles({
    link: {
        wordBreak: 'break-all',
        paddingInlineStart: '0.5ch',
        paddingInlineEnd: '0.5ch',
    },
    list: {
        counterReset: 'item',
        wordBreak: 'break-word',

        '& b': {
            fontWeight: tokens.fontWeightSemibold,
        },
        '& p': {
            marginBlockStart: tokens.spacingVerticalS,
            marginBlockEnd: tokens.spacingVerticalS,
        },
        '& h3': {
            fontWeight: tokens.fontWeightSemibold,
            fontSize: tokens.fontSizeBase500,
            marginBlockStart: tokens.spacingVerticalS,
            marginBlockEnd: tokens.spacingVerticalS,
        },
        '& > li': {
            display: 'block',
            padding: `${tokens.spacingVerticalXS} 0`,
            '::before': {
                content: 'counters(item, ".") " "',
                counterIncrement: 'item',
                position: 'absolute',
                left: 0,
            },
        },
    },
    primaryList: {
        position: 'relative',
        '& > li::before': {
            marginBlockStart: tokens.spacingVerticalS,
            fontWeight: tokens.fontWeightSemibold,
            fontSize: tokens.fontSizeBase500,
        },
    },
    nestedList: {
        padding: '0',
    },
    unorderedList: {
        paddingLeft: tokens.spacingHorizontalXL,
        '& li': {
            paddingTop: tokens.spacingVerticalXS,
            paddingBottom: tokens.spacingVerticalXS,
        },
    },
    standaloneList: {
        paddingLeft: 0,
        '& li': {
            paddingTop: tokens.spacingVerticalXS,
            paddingBottom: tokens.spacingVerticalXS,
        },
    },
    romanList: {
        listStyleType: 'lower-roman',
    },
    latinList: {
        listStyleType: 'lower-latin',
    },
});
